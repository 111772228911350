import React from 'react';
import { MainActionButton } from '../../actionButton/MainActionButton';
import { AppTooltip } from '../../appTooltipList/AppTooltip';

type Props = {
  toolTipText: string;
  handleSelectFile: () => void;
}

export const SelectFileButton = ({ toolTipText, handleSelectFile }: Props) => {
  if (toolTipText) {
    return (
      <AppTooltip text={toolTipText}>
        <MainActionButton
          title='Select file'
          isDisabled
          backgroundMainColor='rgba(73, 43, 131, 0.89)'
          borderRadius='2px'
          width={100}
        />
      </AppTooltip>
    )
  }

  return (
    <MainActionButton
      title='Select file'
      actionOnClick={handleSelectFile}
      backgroundMainColor='rgba(73, 43, 131, 0.89)'
      borderRadius='2px'
      width={100}
    />
  )


};
