import { useMemo } from 'react';
import { bindActionCreators } from '@reduxjs/toolkit';
import { useAppDispatch } from '../typedHooks';
import { accountDetailsActions } from '../../slices/AccounDetailsSlice/accountDetailsSlice';
import {
  cancelPlan,
  confirmRestorePassword,
  getInvoicesHistory,
  getPlans,
  getSubscriptionDetails,
  logOut,
  payNow,
  restorePassword,
  sendEmailToChangePaymentDetails,
  signIn,
  upgradePlan,
  checkIsUserAuth,
  getUploadingLeadsLimit,
  skipTour
} from '../../slices/AccounDetailsSlice/thunks';

const rootActions = {
  ...accountDetailsActions,
  signIn,
  restorePassword,
  confirmRestorePassword,
  getInvoicesHistory,
  logOut,
  getSubscriptionDetails,
  getPlans,
  upgradePlan,
  cancelPlan,
  sendEmailToChangePaymentDetails,
  payNow,
  checkIsUserAuth,
  getUploadingLeadsLimit,
  skipTour
};
export type AccountDetailsActions = typeof rootActions;
export const useAccountDetailsActions = () => {
  const dispatch = useAppDispatch();
  return useMemo(() => bindActionCreators(rootActions, dispatch), [dispatch]);
};