import React, { useEffect, useState } from "react";
import { useAccountDetailsActions } from "../../../utils/redux/storeHooks/actionsHooks/useAccountDetailsActions";
import { AuthFlow } from "../../../types/pages/auth/authGeneral";
import { SendRestoreEmail } from "./SendRestoreEmail";
import { SetRestorePassword } from "./SetRestorePassword";
import { NotAllowedPage } from "../../notAllowedPage/NotAllowedPage";


export const RestorePasswordFlow = () => {
  const { setRestoreToken } = useAccountDetailsActions();
  const [restorePasswordFlow, setRestorePasswordFlow] = useState<string>('');

  useEffect(() => {
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);
    const token = searchParams.get('token') || null;

    if (token) {
      setRestorePasswordFlow(AuthFlow.SETNEWPASSWORD)
      setRestoreToken(token)
    } else {
      setRestorePasswordFlow(AuthFlow.SENDRESTOREEMAIL)
      setRestoreToken(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (restorePasswordFlow === AuthFlow.SENDRESTOREEMAIL) {
    return <SendRestoreEmail />;
  }

  if (restorePasswordFlow === AuthFlow.SETNEWPASSWORD) {
    return <SetRestorePassword />;
  }

  return <NotAllowedPage />
}

