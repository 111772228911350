import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';
import CardMembershipRoundedIcon from '@mui/icons-material/CardMembershipRounded';
import { checkUserRole, cleanUpAllStorage, getUserInformationFromlocalStorage } from '../../utils/general/get_set_user_information';
import { useNavigate } from 'react-router-dom';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { PaymentFlow } from '../../types/PPG/paymentFlow.enum';
import { useAccountDetailsActions } from '../../utils/redux/storeHooks/actionsHooks/useAccountDetailsActions';
import { useAppDispatch } from '../../utils/redux/storeHooks/typedHooks';
import { resetStore } from '../../utils/redux/storeHooks/resetStore';
import { usePopupActions } from '../../utils/redux/storeHooks/actionsHooks/usePopupActions';
import QuizIcon from '@mui/icons-material/Quiz';
import { useAccountDetailsData } from '../../utils/redux/storeHooks/dataHooks/useAccountDetailsData';

export default function AccountMenu() {
  const { isAccountMenuOpen, activeSubscription } = useAccountDetailsData();
  const { setIsAccountMenuOpen } = useAccountDetailsActions();
  const userInformation = getUserInformationFromlocalStorage()
  const navigate = useNavigate()
  const { logOut } = useAccountDetailsActions();
  const dispatch = useAppDispatch();
  const { setPopUpWelcomeTourOpen } = usePopupActions();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setIsAccountMenuOpen(!!event.currentTarget);
  };
  const handleClose = () => {
    setIsAccountMenuOpen(false);
  };

  const isTourBlock = () => {
    if (checkUserRole('Master')) {
      return false
    }
    return !activeSubscription || !activeSubscription?.isActive
  }


  const userLogOut = async () => {
    await logOut();
    cleanUpAllStorage();
    resetStore(dispatch);
    setIsAccountMenuOpen(false);
    navigate('/sign-in')
  };

  const botManagement = () => {
    navigate('/bot-management')
  };

  const adminPage = () => {
    navigate('/admin-page/users')
  };

  const accountDetails = () => {
    navigate('/account-details')
  };

  const privacyPolicy = () => {
    window.location.href = 'https://heet.ai/privacy-policy'
  }

  const contactSupport = () => {
    const link = 'https://meetings-eu1.hubspot.com/michael-pihosh/heet';
    window.open(link, '_blank');
  }

  const onWelcomeTour = () => {
    setPopUpWelcomeTourOpen(true)
  }

  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }} id='accountMenuWrapper'>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2, marginLeft: 0 }}
            aria-controls={isAccountMenuOpen ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={isAccountMenuOpen ? 'true' : undefined}
          >
            <Avatar
              sx={{ width: 32, height: 32, mr: '4px' }}
              src={userInformation.profileImg}
              alt={userInformation.firstName}
            />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={document.getElementById('accountMenuWrapper')}
        id="account-menu"
        open={isAccountMenuOpen}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {(userInformation.paymentFlow === PaymentFlow.PPG || checkUserRole('Master')) && (
          <MenuItem onClick={accountDetails}>
            <ListItemIcon>
              <AccountCircleIcon style={{ fontSize: 'medium', color: 'black' }} />
            </ListItemIcon>
            Account details
          </MenuItem>
        )}
        <MenuItem onClick={onWelcomeTour} id='tourAgain' disabled={isTourBlock()}>
          <ListItemIcon>
            <QuizIcon style={{ fontSize: 'medium', color: 'black' }} />
          </ListItemIcon>
          Welcome tour
        </MenuItem>
        <MenuItem onClick={contactSupport}>
          <ListItemIcon>
            <SupportAgentIcon style={{ fontSize: 'medium', color: 'black' }} />
          </ListItemIcon>
          Contact support
        </MenuItem>

        <MenuItem onClick={privacyPolicy}>
          <ListItemIcon>
            <CardMembershipRoundedIcon style={{ fontSize: 'medium', color: 'black' }} />
          </ListItemIcon>
          Privacy policy
        </MenuItem>
        {checkUserRole('Master') && (
          <MenuItem onClick={botManagement}>
            <ListItemIcon>
              <AdminPanelSettingsIcon style={{ fontSize: 'medium', color: 'black' }} />
            </ListItemIcon>
            Bots management
          </MenuItem>
        )}
        {checkUserRole('Master') && (
          <MenuItem onClick={adminPage}>
            <ListItemIcon>
              <AdminPanelSettingsIcon style={{ fontSize: 'medium', color: 'black' }} />
            </ListItemIcon>
            Admin page
          </MenuItem>
        )}
        <MenuItem onClick={userLogOut}>
          <ListItemIcon>
            <Logout style={{ fontSize: 'medium', color: 'black' }} />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </React.Fragment >
  );
}
