import React, { useState } from "react";
import scss from '../Auth.module.scss'
import { Typography, Box, InputAdornment, IconButton } from "@mui/material";
import { MainActionButton } from "../../../components/actionButton/MainActionButton";
import { StyledTextFieldWithErrorAuth } from "../../../components/styledTextField/StyledTextField";
import { useAccountDetailsActions } from "../../../utils/redux/storeHooks/actionsHooks/useAccountDetailsActions";
import { useAccountDetailsData } from "../../../utils/redux/storeHooks/dataHooks/useAccountDetailsData";
import { useNavigate, useOutletContext } from "react-router-dom";
import { AuthContextType, AuthFlow } from "../../../types/pages/auth/authGeneral";
import { Visibility, VisibilityOff } from "@mui/icons-material";

export const SetRestorePassword = () => {
  const {
    confirmRestorePassword,
    setIsPasswordShown,
    setNewPasswordConfirm,
    setIsPasswordSuccess
  } = useAccountDetailsActions();
  const {
    newPassword,
    isNewPasswordValid,
    newPasswordConfirm,
    isPasswordShown,
    restoreToken,
    isConfirmRestorePasswordRequest,
  } = useAccountDetailsData();

  const {
    handlePasswordChange,
    checkIsPasswordValid
  } = useOutletContext<AuthContextType>();
  const navigate = useNavigate();
  const authFlow = AuthFlow.SETNEWPASSWORD;
  const [passwordSame, setPasswordSame] = useState(true);

  const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

  const handleConfirm = async () => {
    const isFormValid = isPasswordSameAndValid();
    if (isFormValid) {
      await confirmRestorePassword({
        token: restoreToken,
        password: newPassword
      })
      await delay(3000)
      setIsPasswordSuccess(false)
      navigate('/overview')
    }

    return;
  }

  const isPasswordSameAndValid = () => {
    if (newPassword && newPasswordConfirm && passwordSame && isNewPasswordValid) {
      return true;
    }

    return false;
  }


  const onNewPasswordChange = (password: string) => {
    handlePasswordChange(password, authFlow)
    checkIsPasswordValid(password, authFlow);
    if (newPasswordConfirm) {
      checkIsNewPasswordConfirmValid({
        password: password,
        confirm: newPasswordConfirm
      })
    }
  }

  const onNewPasswordConfirmChange = (password: string) => {
    setNewPasswordConfirm(password)
    checkIsNewPasswordConfirmValid({
      password: newPassword,
      confirm: password
    })
  }

  const checkIsNewPasswordConfirmValid = (passwords: { password: string, confirm: string }) => {
    const isPasswordSame = passwords.password === passwords.confirm;
    if (!isPasswordSame) {
      setPasswordSame(false)
    } else {
      setPasswordSame(true)
    }
  }

  return (
    <Box className={scss.auth__form}>
      <Box className={scss.auth__form__inputWrapper}>
        <Typography className={scss.auth__form__label}>
          New password
        </Typography>
        <StyledTextFieldWithErrorAuth
          type={isPasswordShown ? "text" : "password"}
          value={newPassword}
          onChange={(e) => onNewPasswordChange(e.target.value)}
          error={!isNewPasswordValid}
          helperText={!isNewPasswordValid && 'Password must be at least 6 symbols.'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setIsPasswordShown(!isPasswordShown)}>
                  {isPasswordShown
                    ? <Visibility sx={{ color: '#FFFFFF' }} />
                    : <VisibilityOff sx={{ color: '#FFFFFF' }} />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      </Box>
      <Box className={scss.auth__form__inputWrapper}>
        <Typography className={scss.auth__form__label}>
          Confirm password
        </Typography>
        <StyledTextFieldWithErrorAuth
          type={isPasswordShown ? "text" : "password"}
          value={newPasswordConfirm}
          onChange={(e) => onNewPasswordConfirmChange(e.target.value)}
          error={!passwordSame}
          helperText={!passwordSame && 'password must match'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setIsPasswordShown(!isPasswordShown)}>
                  {isPasswordShown
                    ? <Visibility sx={{ color: '#FFFFFF' }} />
                    : <VisibilityOff sx={{ color: '#FFFFFF' }} />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      </Box>
      <MainActionButton
        width={468}
        height={44}
        title="Restore"
        borderRadius="4px"
        backgroundMainColor="rgba(109, 48, 183, 1)"
        customHoverColor="rgba(130, 70, 190, 1)"
        fontSize={16}
        actionOnClick={handleConfirm}
        isDisabled={isConfirmRestorePasswordRequest || !isPasswordSameAndValid()}
        isLoading={isConfirmRestorePasswordRequest}
      />
    </Box>
  );
}

