import UserInformation from "../../types/entities/user_information";
import {PaymentFlow} from "../../types/PPG/paymentFlow.enum";
import {createLinkThroughApi,} from "./buildLinkToSubscribe";
import {SubscriptionI} from "../../types/PPG/SubscriptionI";
import { Invoice, InvoiceStatus } from "../../types/PPG/Invoice";

export const calculateTimeLeft = (nextPaymentDate: string | Date): string => {
    if (!nextPaymentDate) {
        return "No information about the next payment";
    }

    const paymentDate = new Date(nextPaymentDate);

    return paymentDate.toLocaleString('uk-UA');
};

export const getSubscriptionStatus = (user: UserInformation): string => {
    if (user.paymentFlow === PaymentFlow.INVOICE) {
        return 'Manual users do not have a trial.';
    }

    const activeSubscription = user.subscriptions?.find(sub => sub.isActive === true);

    if (activeSubscription) {
        if (activeSubscription.isTrial === false) {
            return 'No trial';
        }
        if (activeSubscription.nextPayment && activeSubscription.nextPayment !== '') {
            const daysLeft = calculateTimeLeft(activeSubscription.nextPayment);
            return `Next payment date: ${daysLeft}`;
        }
    }
    return 'N/A';
};

export const openSubscriptionLink = async (paymentFlow: PaymentFlow, lastSubscription: SubscriptionI): Promise<void> => {
      const link = paymentFlow === PaymentFlow.PPG
        ? await createLinkThroughApi(lastSubscription)
        : 'https://meetings-eu1.hubspot.com/michael-pihosh/heet';
        paymentFlow === PaymentFlow.PPG ? window.location.href = link : window.open(link, '_blank');
};

export const isFirstPaymentProcessing = (activeSubscription: SubscriptionI, invoices: Invoice[]) => {
    return !activeSubscription && invoices[0]?.orderStatusId === InvoiceStatus.Waiting;
}

export const urlBookCall = 'https://meetings-eu1.hubspot.com/michael-pihosh/heet'