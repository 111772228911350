import * as React from 'react';
import scss from '../DashboardLeadsPeoplePage.module.scss';
import { Box, Button, CircularProgress, Zoom } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { useTableLeadsData } from '../../../utils/redux/storeHooks/dataHooks/useTableLeadsData';
import { TooltipApp } from '../../../components/appTooltipList/TooltipApp';
import { usePopupActions } from '../../../utils/redux/storeHooks/actionsHooks/usePopupActions';

export const ExportButton = () => {
  const { appliedFilersCount, isExportingLeads } = useTableLeadsData();
  const { setPopupExportLeadOpen } = usePopupActions()

  if (!appliedFilersCount) {
    return (
      <TooltipApp TransitionComponent={Zoom} placement="bottom" title={
        <>
          <span className={scss.toolTipBody}>
            Select filters to use export.
          </span>
        </>
      }>
        <Box>
          <Button
            className={scss.exportButton}
            variant="contained"
            disabled={!appliedFilersCount}
            sx={{
              height: 40,
              "&:hover": { backgroundColor: '#E0E8F9', boxShadow: 3 },
            }}
          >
            <LogoutIcon sx={{ transform: 'rotate(-90deg)', fontSize: '18px' }} />
            Export
          </Button>
        </Box>

      </TooltipApp>
    )
  }
  return (
    <Button
      onClick={() => setPopupExportLeadOpen(true)}
      disabled={isExportingLeads}
      className={scss.exportButton}
      variant="contained"
      sx={{
        position: 'relative',
        height: 40,
        "&:hover": { backgroundColor: '#E0E8F9', boxShadow: 3 },
      }}
    >
      <LogoutIcon sx={{ transform: 'rotate(-90deg)', fontSize: '18px' }} />
      Export
      {isExportingLeads && <CircularProgress
        size={24}
        sx={{
          position: 'absolute',
          color: 'white',
          marginLeft: "auto",
          marginRight: "auto",
        }}
      />}
    </Button>
  )
}

