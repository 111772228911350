import { Button, CircularProgress } from "@mui/material";
import React, { ReactElement } from "react";
import styles from "./ActionButton.module.scss"


type Props = {
  icon?: ReactElement;
  iconPosition?: 'left' | 'right';
  title?: string;
  actionOnClick?: (...args: any[]) => void;
  isDisabled?: boolean;
  width?: number;
  height?: number;
  isLoading?: boolean;
  borderRadius?: string;
  fontWeight?: number;
  backgroundMainColor?: string;
  fontColor?: string;
  borderButton?: string;
  isFullWidth?: boolean;
  fontSize?: number;
  customHoverColor?: string;
  id?: string;
}

export const MainActionButton = ({
  icon,
  iconPosition,
  title,
  actionOnClick,
  isDisabled,
  width,
  height,
  isLoading,
  borderRadius,
  fontWeight,
  backgroundMainColor,
  fontColor,
  borderButton,
  isFullWidth,
  fontSize,
  customHoverColor,
  id
}: Props) => {
  const handleClick = (...args: any[]) => {
    if (actionOnClick) {
      if (args.length > 0) {
        // Call the function with event parameter
        actionOnClick(...args);
      } else {
        // Call the function without parameters
        actionOnClick();
      }
    } else {
      return;
    }
  };

  return (
    <Button
      className={styles.actionButton}
      sx={{
        "&:hover": { backgroundColor: customHoverColor || backgroundMainColor || '#3B2470' },
        "&:disabled": { backgroundColor: backgroundMainColor || '#3B2470', color: 'white', opacity: 0.6 },
        width: isFullWidth ? '100%' : `${width || 143}px`,
        height: `${height || 40}px`,
        position: 'relative',
        borderRadius: borderRadius || '1px',
        fontWeight: fontWeight || 300,
        backgroundColor: backgroundMainColor || '#3B2470',
        color: fontColor || '#fff',
        border: borderButton || 'none',
        fontSize: fontSize || '14px'
      }}
      variant="contained"
      onClick={handleClick}
      disabled={isDisabled || false}
      id={id || ''}
    >
      {icon && iconPosition !== 'right' && icon}
      {title && title}
      {icon && iconPosition === 'right' && icon}
      {isLoading && <CircularProgress
        size={24}
        sx={{
          position: 'absolute',
          color: 'white',
          marginLeft: "auto",
          marginRight: "auto",
        }}
      />}
    </Button>
  )
}

