import React, { useEffect, useRef, useState } from 'react';
import styles from "./ImportCSV.module.scss";
import { usePopupData } from '../../../utils/redux/storeHooks/dataHooks/usePopupData';
import { usePopupActions } from '../../../utils/redux/storeHooks/actionsHooks/usePopupActions';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { useAutomationData } from '../../../utils/redux/storeHooks/dataHooks/useAutomationData';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useTableLeadsData } from '../../../utils/redux/storeHooks/dataHooks/useTableLeadsData';
import { useSidebarData } from '../../../utils/redux/storeHooks/dataHooks/useSidebarData';
import { useAccountDetailsActions } from '../../../utils/redux/storeHooks/actionsHooks/useAccountDetailsActions';
import { DateRangeLimitName } from '../../../types/pages/plan_settings/plan_settings';
import { useAccountDetailsData } from '../../../utils/redux/storeHooks/dataHooks/useAccountDetailsData';
import { StepWithUnvalidLinks } from './StepWithUnvalidLinks';
import { getUploadAbilityTooltipText } from '../../../utils/general/getUploadAbilityTooltipText';
import DescriptionIcon from '@mui/icons-material/Description';
import { UploadCsvButton } from './UploadCSVButton';
import ClearIcon from '@mui/icons-material/Clear';
import { SelectFileButton } from './SelectFileButton';
import { UploadLeadsStatus } from '../../../types/pages/dashboardLeadsPeoplePage/uploadingLeads';

export const PopupImportCSV = () => {
  const { setPopupImportCSVOpen, uploadCSV, resetUnvalidLinks } = usePopupActions();
  const { isUploadingCSVProcess, unvalidLinks } = usePopupData()
  const { uploadInfo } = useTableLeadsData();
  const { totalCountOfLeads, leadsLimit, checkOfValidCookie } = useSidebarData();
  const { getUploadingLeadsLimit } = useAccountDetailsActions()
  const { isGetUploadingLeadsLimitWeeklyRequest, weeklyUploadingLeadsLimit } = useAccountDetailsData();
  const { isAutomationRunning } = useAutomationData();
  const [file, setfile] = useState<File | null>(null);
  const fileInputRef = useRef(null);

  const handleSelectFile = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const closePopUp = () => {
    resetUnvalidLinks();
    setPopupImportCSVOpen(false);
  }

  const handleUpload = () => {
    if (file) {
      uploadCSV({ file })
    }
  };

  const handleChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files[0]
    if (file) {
      setfile(file)
    }
  };

  const downloadExample = () => {
    const link = document.createElement("a");
    link.download = `example.csv`;
    link.href = "/example.csv";
    link.click();
  };

  const onRemoveFile = () => {
    setfile(null)
  }

  useEffect(() => {
    getUploadingLeadsLimit({
      dateRangeLimitName: DateRangeLimitName.PERWEEK
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const toolTipText = getUploadAbilityTooltipText({
    checkOfValidCookie,
    isTrackingLimitReached: totalCountOfLeads >= leadsLimit,
    isLeadsUploading: uploadInfo?.status === UploadLeadsStatus.UPLOADING,
    isLeadsAlreadyUpload: uploadInfo?.status === UploadLeadsStatus.COMPLETE,
    isGetUploadingLeadsLimitWeeklyRequest,
    isWeeklyLimitReached: weeklyUploadingLeadsLimit?.leadsUploadedPerRange >= weeklyUploadingLeadsLimit?.limitPerRange,
    isAutomationRunning,
  })

  if (unvalidLinks) {
    return (
      <StepWithUnvalidLinks />
    )
  }

  return (
    <Box className={styles.popup} id='mainPopUpUploadCsv'>
      <Box className={styles.header}>
        <Box className={styles.header__title}>
          Import CSV
        </Box>
        <IconButton
          color="default"
          onClick={closePopUp}
          className={styles.header__closeButton}
        >
          <CloseRoundedIcon sx={{ fill: '#191F2E' }} />
        </IconButton>
      </Box>
      <Box className={styles.content}>
        <Typography className={styles.content__title}>
          You can upload up a file with up to <span className={styles.content__title_bold}>250 leads profiles.</span>
        </Typography>

        <Box className={styles.content__upload}>
          <Box className={styles.content__upload__info}>
            <Typography className={styles.content__upload__info_title}>
              Please, choose to upload the CSV file
            </Typography>
            <Typography className={styles.content__upload__info_size}>
              {'(max 5MB)'}
            </Typography>

          </Box>
          <Box className={styles.content__upload__actions}>
            <SelectFileButton
              toolTipText={toolTipText}
              handleSelectFile={handleSelectFile}
            />
            <input
              type="file"
              accept=".xls, .xlsx, .csv"
              hidden
              ref={fileInputRef}
              onChange={handleChangeFile}
            />

          </Box>
        </Box>

        <Box className={styles.content__leadsLeft}>
          <Box className={styles.content__leadsLeft_container}>
            <Typography className={styles.content__leadsLeft_text}>
              {`Lead slots:`}
            </Typography>
            <Typography className={styles.content__leadsLeft_limit}>
              {`weekly`}
            </Typography>
          </Box>
          <Typography className={styles.content__leadsLeft_text}>
            {`${weeklyUploadingLeadsLimit?.leadsUploadedPerRange}/${weeklyUploadingLeadsLimit?.limitPerRange}`}
          </Typography>
        </Box>

        <Box className={styles.content__download}>
          <Typography className={styles.content__downloadText}>
            Check the following example to ensure your file fits appropriately.
          </Typography>
          <Button
            component='button'
            className={styles.content__downLoadButton}
            onClick={downloadExample}
          >
            <img src='/download.svg' alt='' />
            <Typography className={styles.content__downLoadButtonText}>
              Download sample CSV file
            </Typography>
          </Button>
        </Box>

        {file ? (
          <Box className={styles.content__uploadedFile}>
            <Box className={styles.content__uploadedFile__file}>
              <DescriptionIcon sx={{ fontSize: '14px', color: 'rgba(109, 109, 109, 1)' }} />
              <Typography className={styles.content__uploadedFile__file_name}>
                {`${file?.name || ''}`}
              </Typography>
            </Box>
            <IconButton onClick={onRemoveFile} sx={{ color: "rgba(109, 109, 109, 1)" }}>
              <ClearIcon sx={{ fontSize: "16px" }} />
            </IconButton>
          </Box>
        ) : (
          <Box className={styles.content__noUploadedFile} />
        )}

      </Box>

      <Box className={styles.footer}>
        <Button
          variant='text'
          onClick={closePopUp}
          className={styles.footer__cancel}
        >
          Cancel
        </Button>
        <UploadCsvButton
          file={file}
          toolTipText={toolTipText}
          isUploadingCSVProcess={isUploadingCSVProcess}
          handleUpload={handleUpload}
        />
      </Box>
    </Box>
  );
};
