import React from 'react';
import styles from "./UploadLeadsReport.module.scss";
import { Box } from '@mui/material';
import { UploadLeadsReportHeader } from './UploadLeadsReportHeader';
import { UploadLeadsReportFooter } from './UploadLeadsReportFooter';
import { UploadLeadsReportContent } from './UploadLeadsReportContent';

export const UploadLeadsReport = () => (
  <Box className={styles.popup}>
    <UploadLeadsReportHeader />
    <UploadLeadsReportContent />
    <UploadLeadsReportFooter />
  </Box>
);

