import UserInformation from "../../types/entities/user_information";
import { PaymentFlow } from "../../types/PPG/paymentFlow.enum";

export default function setUserInformationTolocalStorage(data: UserInformation) {
    localStorage.setItem('userId', String(data.id));
    localStorage.setItem('firstName', data.firstName);
    localStorage.setItem('lastName', data.lastName);
    localStorage.setItem('title', data.title);
    localStorage.setItem('email', data.email);
    localStorage.setItem('profileImg', data.profileImg);
    localStorage.setItem('slots', data.slots.toString());
    localStorage.setItem('comments', data.comments.toString());
    localStorage.setItem('slotsLeft', data.slotsLeft.toString());
    localStorage.setItem('commentsLeft', data.commentsLeft.toString());
    localStorage.setItem('engagement', data?.engagement ? data.engagement.toString() : '0');
    localStorage.setItem('leadsActivity', data?.leadsActivity ? data.leadsActivity.toString() : '0');
    localStorage.setItem('viewedProfile', data?.viewedProfile ? data.viewedProfile.toString() : '0');
    localStorage.setItem('linkedInConnections', data?.linkedInConnections ? data.linkedInConnections.toString() : '0');
    localStorage.setItem('roles', data.roles.toString());
    localStorage.setItem('organizationName', data.organizationName);
    localStorage.setItem('organizationRole', data.organizationRole);
    localStorage.setItem('organizationId', data.organizationId)
    localStorage.setItem('paymentFlow', data.paymentFlow)
    localStorage.setItem('userUuid', data.userUuid)
    localStorage.setItem('isFirstAppEnter', String(data.isFirstAppEnter))
}

export function getUserInformationFromlocalStorage(): UserInformation {
    const userInformation: UserInformation = {
        id: Number(localStorage.getItem('userId')),
        firstName: localStorage.getItem('firstName'),
        lastName: localStorage.getItem('lastName'),
        title: localStorage.getItem('title'),
        email: localStorage.getItem('email'),
        profileImg: localStorage.getItem('profileImg'),
        slots: parseInt(localStorage.getItem('slots')),
        comments: parseInt(localStorage.getItem('comments')),
        slotsLeft: parseInt(localStorage.getItem('slotsLeft')),
        commentsLeft: parseInt(localStorage.getItem('commentsLeft')),
        roles: localStorage.getItem('roles'),
        engagement: localStorage.getItem('engagement'),
        leadsActivity: localStorage.getItem('leadsActivity'),
        viewedProfile: localStorage.getItem('viewedProfile'),
        linkedInConnections: localStorage.getItem('linkedInConnections'),
        organizationName: localStorage.getItem('organizationName'),
        organizationRole: localStorage.getItem('organizationRole'),
        organizationId: localStorage.getItem('organizationId'),
        paymentFlow: localStorage.getItem('paymentFlow') as PaymentFlow,
        userUuid: localStorage.getItem('userUuid'),
        isFirstAppEnter: localStorage.getItem('isFirstAppEnter') === 'false' ? false : true
    }
    return userInformation;
}

export function cleanUpAllStorage() {
    const storageLength = localStorage.length;
    for (let i = storageLength - 1; i >= 0; i--) {
        const key = localStorage.key(i);
        localStorage.removeItem(key);
    }
}

export function checkUserRole(role: string): boolean {
    const roles = localStorage.getItem('roles');
    if(roles) {
    const rolesArray = roles.split(',');
    return rolesArray.includes(role);
    } else {
        return false
    }
}
