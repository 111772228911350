import React, { useEffect } from "react";
import styles from "./UsersStatistic.module.scss";
import { Box, LinearProgress } from "@mui/material";
import { GeneralListStatistic } from "./features/GeneralListStatistic";
import { UsersStatisticHeader } from "./features/UsersStatisticHeader";
import { UsersStatisticDiagrams } from "./features/UsersStatisticDiagrams";
import { useUsersStatisticData } from "../../utils/redux/storeHooks/dataHooks/useUsersStatisticData";
import { useUsersStatisticActions } from "../../utils/redux/storeHooks/actionsHooks/useUsersStatisticActions";
import { StatisticType } from "../../types/pages/usersStatistic/usersStatistic";
import { UserStatisticReactions } from "./features/UserStatisticReactions";
import { UserStatisticConnections } from "./features/UserStatisticConnections";

export const UsersStatistic = () => {
  const { isGeneralStatisticRequest, dateRange } = useUsersStatisticData();
  const { getGeneralStatistic, getCampaignStatistic } = useUsersStatisticActions();

  useEffect(() => {
    getGeneralStatistic({
      dateRange,
      statisticType: StatisticType.GENERAL
    })
    getCampaignStatistic({
      dateRange,
      statisticType: StatisticType.CAMPAIGN
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange])

  return (
    <>
      {isGeneralStatisticRequest && (<LinearProgress
        style={{ backgroundColor: 'rgba(224, 232, 249, 0.4)' }}
        sx={{
          margin: '-24px',
          marginBottom: '20px',
          height: 4,
          '& .MuiLinearProgress-bar': {
            backgroundColor: 'rgba(59, 36, 112, 0.9)',
          },
        }}
      />)}

      <Box className={styles.usersStatistic__pageContainer}>
        <UsersStatisticHeader />
        <GeneralListStatistic />
        <UsersStatisticDiagrams />
        <UserStatisticReactions />
        <UserStatisticConnections />
      </Box>
    </>
  );
};
