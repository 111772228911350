import { Box } from '@mui/material';
import React, { ReactElement } from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import CheckIcon from '@mui/icons-material/Check';
import styles from './UploadingIndicator.module.scss'


type Props = {
  wrapperProps: {
    isParsingError: boolean;
    isLeadParsingInProgress: boolean;
    isLeadParsingComplete: boolean;
  }
  children: ReactElement;
}
export const UploadingIndicatorWrapper = ({
  wrapperProps,
  children
}: Props) => {
  const { isParsingError, isLeadParsingComplete } = wrapperProps;
  const getIcon = () => {
    if (isParsingError) {
      return <ErrorIcon sx={{ color: '#191F2E' }} />
    }

    if (isLeadParsingComplete) {
      return <CheckIcon sx={{ color: '#191F2E' }} />
    }

    return null;
  }

  const getSx = () => {
    if (isParsingError) {
      return {
        backgroundColor: '#FFB7A3',
        color: '#191F2E',
        '&:hover': {
          backgroundColor: '#FFB7A3',
        }
      }
    }

    return {
      backgroundColor: '#BFE8B1',
      color: '#191F2E',
      '&:hover': {
        backgroundColor: '#BFE8B1',
      }
    };
  }

  return (
    <Box
      className={styles.indicatorWrapper}
      sx={getSx()}
    >
      {getIcon()}
      {children}
    </Box>
  )
}

