import { borderRadius, width } from "@material-ui/system";
import { styled, TextField } from "@mui/material";

export const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    height: '44px',
    borderRadius: '1px',
    fontFamily: 'Kanit',
    margin: 0,

    '&:hover fieldset': {
      borderColor: 'black',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'rgba(109, 48, 183, 1)',
      boxShadow: 'rgba(109, 48, 183, 1) 0px 1px 3px'
    },
    '& .MuiInputBase-input': {
      caretColor: 'rgba(109, 48, 183, 1)',
    },
  },
})

export const StyledTextFieldWithError = styled(TextField)({
  position: 'relative', // Required for positioning the asterisk
  '& .MuiOutlinedInput-root': {
    height: '44px',
    borderRadius: '1px',
    fontFamily: 'Kanit',
    margin: 0,
    '&:hover fieldset': {
      borderColor: 'black',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'rgba(109, 48, 183, 1)',
      boxShadow: 'rgba(109, 48, 183, 1) 0px 1px 3px',
    },
    '& .MuiInputBase-input': {
      caretColor: 'rgba(109, 48, 183, 1)',
    },
  },
  // Styles for the error state
  '& .MuiOutlinedInput-root.Mui-error fieldset': {
    borderColor: 'red',
    boxShadow: 'red 0px 1px 3px',
  },
  '& .MuiFormHelperText-root.Mui-error': {
    color: 'red',
  },
  '&::after': {
    content: '"*"',
    color: 'red',
    position: 'absolute',
    top: '-6px',
    right: '8px',
    fontSize: '24px',
    fontWeight: 'bold',
  },
});

export const StyledTextFieldWithErrorAuth = styled(TextField)({
  position: 'relative', // Required for positioning the asterisk
  '& .MuiOutlinedInput-root': {
    width: '468px',
    height: '44px',
    borderRadius: '4px',
    fontFamily: 'Kanit',
    color: 'white',
    margin: 0,
    '& fieldset': {
      borderColor: 'white', // Default border color
    },
    '&:hover fieldset': {
      borderColor: 'white',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
      boxShadow: 'white',
    },
    '& .MuiInputBase-input': {
      caretColor: 'white',
    },
  },
  // Styles for the error state
  '& .MuiOutlinedInput-root.Mui-error fieldset': {
    borderColor: 'rgba(255, 183, 163, 1)',
    boxShadow: 'rgba(255, 183, 163, 1) 0px 1px 3px',
  },
  '& .MuiFormHelperText-root.Mui-error': {
    color: 'rgba(255, 183, 163, 1)',
  },
  '& input:-webkit-autofill': {
    WebkitBoxShadow: 'none !important', // Заливаем фон черным (можно заменить)
    WebkitTextFillColor: 'white !important', // Цвет текста
    transition: 'background-color 5000s ease-in-out 0s', // Убираем мгновенную заливку
  },
});

