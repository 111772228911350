import * as React from 'react';
import { Box, Pagination, SelectChangeEvent } from '@mui/material';
import style from '../../newFeedPage.module.scss'
import { SelectPostPerPage } from './SelectPostPerPage';

type Props = {
  postsPerPage: number;
  feedCount: number;
  currentPage: number;
  handlePostsPerPageChange: (event: SelectChangeEvent<number>) => void;
  onFeedRequestHandler: (page: number, rowsPerPage: number) => void;
}

export const PostsPagination = ({
  postsPerPage,
  currentPage,
  feedCount,
  onFeedRequestHandler,
  handlePostsPerPageChange
}: Props) => {
  return (
    <Box className={style.paginationWrapper}>
      <Pagination
        count={Math.ceil(feedCount / postsPerPage) || 0}
        page={currentPage}
        onChange={(_, newPage) => onFeedRequestHandler(newPage, postsPerPage)}
        variant="outlined"
        shape="rounded"
        size="large"
        sx={{
          "& .MuiPaginationItem-root": {
            color: "#6f7d9b",
            borderColor: "#6f7d9b"
          },
          "& .MuiPaginationItem-root.Mui-selected": {
            backgroundColor: "#6d30b7",
            color: "white",
            borderColor: 'transparent'
          },
        }} />
      <SelectPostPerPage
        postsPerPage={postsPerPage}
        handlePostsPerPageChange={handlePostsPerPageChange}
      />
    </Box>
  )
}

