import * as React from 'react';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';

type Props = {
  postsPerPage: number;
  handlePostsPerPageChange: (event: SelectChangeEvent<number>) => void;
}

export const SelectPostPerPage = ({ postsPerPage, handlePostsPerPageChange }: Props) => {

  return (
    <FormControl sx={{ minWidth: 120, height: '100%' }}>
      <InputLabel id="posts-per-page-label"
        sx={{
          color: '#6f7d9b',
          '&.Mui-focused': {
            color: '#6d30b7',
          },
        }}
      >
        Per page
      </InputLabel>
      <Select
        labelId="posts-per-page-label"
        value={postsPerPage}
        label="Posts per"
        onChange={(e) => handlePostsPerPageChange(e)}
        sx={{
          height: '40px',
          width: '75px',
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: '#6f7d9b',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#6d30b7',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#191f2e',
          },
        }}
      >
        <MenuItem value={10}>10</MenuItem>
        <MenuItem value={20}>20</MenuItem>
        <MenuItem value={50}>50</MenuItem>
        <MenuItem value={100}>100</MenuItem>
      </Select>
    </FormControl>
  )
}

