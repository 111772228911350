import * as React from 'react';
import { TooltipApp } from './TooltipApp';
import { Box, Zoom } from '@mui/material';
import scss from './AppTooltipList.module.scss'

type Props = {
  children: React.ReactElement;
  text: string;
  placement?: "bottom" | "left" | "right" | "top" | "bottom-end" | "bottom-start" | "left-end" | "left-start" | "right-end" | "right-start" | "top-end" | "top-start"
}

export const AppTooltip = ({ text, children, placement }: Props) => {

  return (
    <TooltipApp
      TransitionComponent={Zoom}
      placement={placement || "top-start"}
      title={
        <span className={scss.toolTipBody}>
          {text}
        </span>
      }>
      <Box>
        {children}
      </Box>

    </TooltipApp>
  )
}
