import { useAppSelector } from '../typedHooks';

export const useAccountDetailsData = () => {
  const {
   isSendingRestorePasswordEmail,
   isSingInRequest,
   isConfirmRestorePasswordRequest,
   isEmailRestorePasswordSent,
   isGetSubscriptionDetailsRequest,
   activeSubscription,
   isGetPlansRequest,
   plans,
   isGetInvoicesRequest,
   invoicePage,
   invoices,
   isRequestUpgradePlan,
   planToUpgrade,
   isCancelPlanRequest,
   totalInvoicesCount,
   isRequestToSendEmailUpdatePaymentDetails,
   accessAppStatuses,
   isPasswordShown,
   authPassword,
   authEmail,
   isAuthEmailValid,
   authError,
   checkingUserAlreadyAuth,
   isAuthPasswordValid,
   restoreEmail,
   isRestoreEmailValid,
   isImageBackgroundLoading,
   newPassword,
   newPasswordConfirm,
   restoreToken,
   isNewPasswordValid,
   isUserAuth,
   isUserAuthRequest,
   isPasswordRestoreSuccess,
   isGetUploadingLeadsLimitWeeklyRequest,
   weeklyUploadingLeadsLimit,
   userInformation,
   needRunTour,
   isSkipTourRequest,
   isAccountMenuOpen
  } = useAppSelector((state) => state.accountDetailsReducer);

  return {
    isSendingRestorePasswordEmail,
    isSingInRequest,
    isConfirmRestorePasswordRequest,
    isEmailRestorePasswordSent,
    isGetSubscriptionDetailsRequest,
    activeSubscription,
    isGetPlansRequest,
    plans,
    isGetInvoicesRequest,
    invoicePage,
    invoices,
    isRequestUpgradePlan,
    planToUpgrade,
    isCancelPlanRequest,
    totalInvoicesCount,
    isRequestToSendEmailUpdatePaymentDetails,
    accessAppStatuses,
    isPasswordShown,
    authPassword,
    authEmail,
    isAuthEmailValid,
    authError,
    checkingUserAlreadyAuth,
    isAuthPasswordValid,
    restoreEmail,
    isRestoreEmailValid,
    isImageBackgroundLoading,
    newPassword,
    newPasswordConfirm,
    restoreToken,
    isNewPasswordValid,
    isUserAuth,
    isUserAuthRequest,
    isPasswordRestoreSuccess,
    isGetUploadingLeadsLimitWeeklyRequest,
    weeklyUploadingLeadsLimit,
    userInformation,
    needRunTour,
    isSkipTourRequest,
    isAccountMenuOpen
  };
};