import React from "react";
import { TooltipApp } from "../../appTooltipList/TooltipApp";
import styles from "../PagesTabs.module.scss";
import { Box } from "@mui/material";
import { NavLink } from "react-router-dom";

type Props = {
  title: string;
  link: string;
  disabled: boolean;
}

export const RegularTab = ({ title, link, disabled }: Props) => {

  if (disabled) {
    return (
      <TooltipApp title={
        <>
          <span className={styles.toolTipBody}>
            <img src='/tooltip_icon.svg' alt='' />
            coming soon
          </span>
        </>
      }>
        <Box className={`${styles.pagesTabs__tab} ${styles.pagesTabs__tab_disabled}`}>
          {title}
        </Box>
      </TooltipApp>
    )
  }

  return (
    <NavLink
      end
      className={({ isActive }) => {
        return isActive
          ? `${styles.pagesTabs__tab} ${styles.activeLink}`
          : styles.pagesTabs__tab
      }}
      to={link}
    >
      {title}
    </NavLink>
  )
}

