import { Box, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "../UsersStatistic.module.scss";
import { MainActionButton } from "../../../components/actionButton/MainActionButton";
import { useUsersStatisticData } from "../../../utils/redux/storeHooks/dataHooks/useUsersStatisticData";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Cell } from 'recharts';
import { DiagramsHeaderButtonType } from "../../../types/pages/usersStatistic/usersStatistic";
import { barColors, generateShade } from "../../../utils/pages/usersStatistic/userStatistic";

export const UsersStatisticDiagrams = () => {
  const {
    commentsResponseArr,
    leadsLikeResponseArr,
    leadsRepliesResponseArr,
    isCampaignStatisticRequest,
  } = useUsersStatisticData();
  const [data, setData] = useState(commentsResponseArr);
  const [diagramType, setDiagramType] = useState(DiagramsHeaderButtonType.comments)

  const onDataChange = (type: DiagramsHeaderButtonType) => {
    setDiagramType(type)
    switch (type) {
      case DiagramsHeaderButtonType.likes:
        setData(leadsLikeResponseArr?.length ? leadsLikeResponseArr : null)
        return;
      case DiagramsHeaderButtonType.replies:
        setData(leadsRepliesResponseArr?.length ? leadsRepliesResponseArr : null)
        return;
      case DiagramsHeaderButtonType.comments:
        setData(commentsResponseArr?.length ? commentsResponseArr : null)
        return;
    }
  }

  const getColorForIndex = (index: number): string => {
    const baseColor = barColors[index % barColors.length];
    if (index >= barColors.length) {
      return generateShade(baseColor, 1 + (index - barColors.length) * 0.2);
    }
    return baseColor;
  };

  useEffect(() => {
    setDiagramType(DiagramsHeaderButtonType.comments)
    setData(commentsResponseArr?.length ? commentsResponseArr : null)
  }, [commentsResponseArr,
    leadsLikeResponseArr,
    leadsRepliesResponseArr,])

  if (isCampaignStatisticRequest) {
    return (
      <Box className={styles.usersStatistic__diagramsContainer}>
        <Box className={styles.usersStatistic__diagrams}>
          <Box className={styles.usersStatistic__diagrams__header}>

            <Typography className={styles.usersStatistic__diagrams__header__title}>
              Running campaigns
            </Typography>
          </Box>
          <Box className={styles.usersStatistic__diagramsLoadingContainer}>
            <CircularProgress sx={{ color: 'rgba(59, 36, 112, 1)' }} />
          </Box>
        </Box>
      </Box>
    )
  }

  return (
    <Box className={styles.usersStatistic__diagramsContainer}>
      <Box className={styles.usersStatistic__diagrams}>
        <Box className={styles.usersStatistic__diagrams__header}>
          <Typography className={styles.usersStatistic__diagrams__header__title}>
            Running campaigns
          </Typography>
          <Box className={styles.usersStatistic__diagrams__header__actions}>
            {Object.values(DiagramsHeaderButtonType).map(type => {
              const isSelectedVariant = diagramType === type;
              return (
                <MainActionButton
                  key={type}
                  title={type.charAt(0).toUpperCase() + type.slice(1)}
                  backgroundMainColor={isSelectedVariant ? "rgba(25, 31, 46, 1)" : "rgba(224, 232, 249, 0.4)"}
                  fontSize={14}
                  width={84}
                  height={32}
                  fontColor={isSelectedVariant ? '#fff' : "rgba(25, 31, 46, 1)"}
                  actionOnClick={() => onDataChange(type)}
                  borderRadius="4px"
                />
              )
            })}
          </Box>
        </Box>

        {data ? (
          <Box className={styles.usersStatistic__diagramsResponsiveContainer}>
            <ResponsiveContainer>
              <BarChart
                data={data}
                margin={{
                  top: 5,
                  right: 70,
                  left: 20,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" tick={false} width={10} />
                <YAxis width={10} />
                {/* <Tooltip /> */}
                <Bar dataKey="value" radius={[30, 30, 10, 10]} barSize={10}>
                  {data.map((entry, index) => {
                    const color = getColorForIndex(index);
                    return <Cell key={`cell-${index}`} fill={color} />;
                  })}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </Box>) : (
          <Box className={styles.usersStatistic__diagramsResponsiveContainer}>
            <Typography className={styles.usersStatistic__emptyDiagrams}>
              Nothing to show yet. Try selecting other dates 😌
            </Typography>
          </Box>
        )}
      </Box>

      {data ? (
        <Box className={styles.usersStatistic__campaignList}>
          {data.map((entry, index) => {
            const color = getColorForIndex(index);
            return (
              <Box
                key={`cell-${index}`}
                className={styles.usersStatistic__campaignListItem}
                sx={{
                  color: 'black',
                  '::before': { backgroundColor: color },
                }}
              >
                {`${entry.name.length > 15 ? entry.name.slice(0, 12) + '...' : entry.name} - ${entry.percentage}%`}
              </Box>
            );
          })}
        </Box>
      )
        : (
          <Box className={styles.usersStatistic__campaignList} />
        )
      }
    </Box>
  )
}

