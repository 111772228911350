import {accountDetailsActions} from "../slices/AccounDetailsSlice/accountDetailsSlice"
import { adminPageProxySliceActions } from "../slices/adminPageProxySlice/adminPageProxySlice";
import { adminPageSliceActions } from "../slices/adminPageSlice/adminPageSlice";
import { automationActions } from "../slices/automationPageSlice/automationSlice";
import { botManagementActions } from "../slices/botManagementSlice/botManagementSlice";
import { leadsNotificationActions } from "../slices/leadsNotificationSlice/leadsNotificationSlice";
import { newFeedActions } from "../slices/newFeedSlice/newFeedSlice";
import { notificationSliceActions } from "../slices/notificationSlice/notificationSlice";
import { popupSliceActions } from "../slices/popupSlice/popupSlice";
import { sidebarSliceActions } from "../slices/sidebarSlice/sidebarSlice";
import { tableLeadsSliceActions } from "../slices/tableLeadsSlice/tableLeadsSlice";
import { tableTeamManagementActions } from "../slices/tableTeamManagmentSlice/teamManagementSlice";
import { usersStatisticSliceActions } from "../slices/usersStatisticSlice/usersStatisticSlice";

export const resetStore = (dispatch: any) => {
  dispatch(accountDetailsActions.resetState());
  dispatch(adminPageProxySliceActions.resetState());
  dispatch(adminPageSliceActions.resetState());
  dispatch(automationActions.resetState());
  dispatch(botManagementActions.resetState());
  dispatch(leadsNotificationActions.resetState());
  dispatch(newFeedActions.resetState());
  dispatch(notificationSliceActions.resetState());
  dispatch(popupSliceActions.resetState());
  dispatch(sidebarSliceActions.resetState());
  dispatch(tableLeadsSliceActions.resetState());
  dispatch(tableTeamManagementActions.resetState());
  dispatch(usersStatisticSliceActions.resetState());
};