import { Box, Typography } from "@mui/material";
import React from "react";
import styles from "../UsersStatistic.module.scss";
import { useUsersStatisticData } from "../../../utils/redux/storeHooks/dataHooks/useUsersStatisticData";
import { PieChart, Pie, Cell } from 'recharts';
import { DeterminateLinearProgress } from "../../../components/determinateLinearProgress/DeterminateLinearProgress";
import { colorsRadialDiagram } from "../../../utils/pages/usersStatistic/userStatistic";

export const UserStatisticConnections = () => {
  const {
    leadsFirstConnectionCount,
    leadsSecondConnectionCount,
  } = useUsersStatisticData();

  const data = [
    { name: '1-st', value: leadsFirstConnectionCount },
    { name: '2-nd', value: leadsSecondConnectionCount },
  ];

  const leadsCount = leadsFirstConnectionCount + leadsSecondConnectionCount
  const firstPercentConnectionValue = leadsFirstConnectionCount / leadsCount * 100 || 0
  const secondPercentConnectionValue = leadsSecondConnectionCount / leadsCount * 100 || 0

  return (
    <>

      <Box className={styles.usersStatistic__connections}>
        <Typography className={styles.usersStatistic__connections__title}>
          Connections
        </Typography>
        <Box className={styles.usersStatistic__connections__dataContainer}>
          <Box className={styles.usersStatistic__connections__totalContainer}>
            <Typography className={styles.usersStatistic__connections__totalTitle}>
              Total
            </Typography>
            <Box className={styles.usersStatistic__connections__totalCount}>
              {leadsCount}
            </Box>
          </Box>
          <PieChart width={360} height={180}>
            <Pie
              data={data}
              innerRadius={75}
              outerRadius={90}
              fill="#8884d8"
              paddingAngle={3}
              dataKey="value"
              cornerRadius={10}
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={colorsRadialDiagram[index % colorsRadialDiagram.length]} radius={10} />
              ))}
            </Pie>
          </PieChart>

          <Box sx={{ display: 'flex', gap: '6px', alignItems: 'center', width: '200px' }}>
            <Typography sx={{ fontFamily: 'Inter', width: '40px' }}>1st</Typography>
            <DeterminateLinearProgress value={firstPercentConnectionValue} progressColor={colorsRadialDiagram[0]} />
          </Box>
          <Box sx={{ display: 'flex', gap: '6px', alignItems: 'center', width: '200px' }}>
            <Typography sx={{ fontFamily: 'Inter', width: '40px' }}>2nd</Typography>
            <DeterminateLinearProgress value={secondPercentConnectionValue} progressColor={colorsRadialDiagram[1]} />
          </Box>
        </Box>

      </Box>
    </>

  )
}

