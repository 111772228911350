import React from 'react';
import { useTourNavigation } from '../../utils/customHooks/useTourNavigation';
import { MainActionButton } from '../actionButton/MainActionButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckIcon from '@mui/icons-material/Check';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { CircularProgress, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useAccountDetailsData } from '../../utils/redux/storeHooks/dataHooks/useAccountDetailsData';
import { useEndTour } from '../../utils/customHooks/useEndTour';

export const NextButton = () => {
  const { handleNextSteps, isLastStep, isFirstStep } = useTourNavigation();
  const getButtonIconTitle = () => {
    if (isFirstStep) {
      return { title: 'Start', icon: null }
    }

    if (isLastStep) {
      return { title: 'Done', icon: <CheckIcon /> }
    }

    return { title: 'Next', icon: <ArrowForwardIcon /> }
  }
  const { title, icon } = getButtonIconTitle()
  return <MainActionButton
    title={title}
    actionOnClick={handleNextSteps}
    borderRadius='4px'
    icon={icon}
    iconPosition='right'
  />
};

export const PrevButton = () => {
  const { handlePrevSteps, isFirstStep } = useTourNavigation();
  return !isFirstStep && <MainActionButton
    title='Previous'
    actionOnClick={handlePrevSteps}
    isDisabled={isFirstStep}
    borderRadius='4px'
    borderButton='0.5px solid rgba(230, 217, 248, 1)'
    backgroundMainColor='rgba(248, 243, 255, 1)'
    fontColor='rgba(73, 43, 131, 1)'
    fontWeight={400}
    icon={<ArrowBackIcon />}
  />
};

export const CloseButton = () => {
  const { isSkipTourRequest } = useAccountDetailsData();
  const { onEndTour } = useEndTour();
  const onClose = async () => {
    onEndTour()
  }
  return <IconButton onClick={onClose}>
    {isSkipTourRequest ? <CircularProgress sx={{ color: 'inherit' }} size={18} /> : <CloseIcon />}
  </IconButton>
};
