import { useAppSelector } from '../typedHooks';

export const useNewFeedData = () => {
  const {
    tabs,
    isDeletingtab,
    isSavingTab,
    isUpdatingTab,
    isUpdatingTitleTab,
    deleteOrUpdateTabId,
    isSuccessDelete,
    pagesForTabs,
    isAllTabsLoading,
    isAllTabsError,
    isGetFeedLoading,
    promptList,
    generatedMessages,
    postIdGeneratedMessage,
    postIdsSendingMessage,
    postIdsDeliting,
    initialTabs,
    isGetFeedCountLoading,
    currentLoadingTab,
    postsPerPage
  } = useAppSelector((state) => state.newFeedReducer);

  return {
    tabs,
    isDeletingtab,
    isSavingTab,
    isUpdatingTab,
    isUpdatingTitleTab,
    deleteOrUpdateTabId,
    isSuccessDelete,
    pagesForTabs,
    isAllTabsLoading,
    isAllTabsError,
    isGetFeedLoading,
    promptList,
    generatedMessages,
    postIdGeneratedMessage,
    postIdsSendingMessage,
    postIdsDeliting,
    initialTabs,
    isGetFeedCountLoading,
    currentLoadingTab,
    postsPerPage
  };
};