import React, { useEffect, useState } from 'react';
import styles from "./RestorePassword.module.scss";
import { Box, CircularProgress, Grow, LinearProgress, Typography } from '@mui/material';
import { usePopupActions } from '../../../utils/redux/storeHooks/actionsHooks/usePopupActions';
import { MainActionButton } from '../../actionButton/MainActionButton';
import IconButton from '@mui/material/IconButton';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useAccountDetailsActions } from '../../../utils/redux/storeHooks/actionsHooks/useAccountDetailsActions';
import { useAccountDetailsData } from '../../../utils/redux/storeHooks/dataHooks/useAccountDetailsData';
import { cleanUpAllStorage, getUserInformationFromlocalStorage } from '../../../utils/general/get_set_user_information';
import { resetStore } from '../../../utils/redux/storeHooks/resetStore';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../utils/redux/storeHooks/typedHooks';



export const PopUpRestorePassword = () => {
  const { setPopupRestorePasswordOpen, } = usePopupActions();
  const { restorePassword, setIsEmailRestorePasswordSent, logOut } = useAccountDetailsActions();
  const { isSendingRestorePasswordEmail, isEmailRestorePasswordSent } = useAccountDetailsData();
  const [countdown, setCountdown] = useState(10);
  const navigate = useNavigate()
  const dispatch = useAppDispatch();

  const userInformation = getUserInformationFromlocalStorage()
  const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
  const closePopUp = () => {
    setIsEmailRestorePasswordSent(false)
    setPopupRestorePasswordOpen(false)
  }

  const onResetPassword = async () => {
    await restorePassword({ email: userInformation.email })
    for (let i = 10; i > 0; i--) {
      setCountdown(i);
      await delay(1000);
    }
    await logOut();
    cleanUpAllStorage();
    resetStore(dispatch);
    navigate('/sign-in')
  }

  if (isEmailRestorePasswordSent && !isSendingRestorePasswordEmail) {
    return (
      <Grow in={isEmailRestorePasswordSent}>
        <Box className={styles.popupSuccess}>
          <Box className={styles.headerSuccess}>
            <img src='/success.svg' alt='success' className={styles.headerSuccess__img} />
            <Typography className={styles.headerSuccess__title}>
              Success! Check Your Email.
            </Typography>
            <Box className={styles.headerSuccess__subTitle}>
              <Box>
                {`We've sent a password reset link to your email. Follow the instructions to reset your password.`}
              </Box>
              <Box>
                {`You will be automatically logout in ${countdown} seconds...`}
              </Box>
            </Box>
          </Box>
        </Box>
      </Grow>
    )
  }



  return (
    <Box className={styles.popup}>
      <Box className={styles.header}>
        <Typography className={styles.header__title}>
          Reset your password
        </Typography>
        <IconButton
          color="default"
          onClick={closePopUp}
          className={styles.header__closeButton}
        >
          <CloseRoundedIcon sx={{ fill: '#191F2E' }} />
        </IconButton>
      </Box>
      <Box className={styles.content}>
        <Typography className={styles.content__subTitle}>
          Need to reset your password? No problem! Just click the button below and you’ll be on your way.
        </Typography>
      </Box>

      <Box className={styles.footer}>
        <MainActionButton
          title='Reset'
          isLoading={isSendingRestorePasswordEmail}
          actionOnClick={onResetPassword}
        />
      </Box>
    </Box>
  );
};
