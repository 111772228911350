export const getPageFromTo =  (page: number, perPage: number) => {
  const pageFrom = page === 0 ? page : page * perPage;
  const pageTo = pageFrom + perPage;

  return {pageFrom, pageTo}
}

export const getSkip =  (page: number, perPage: number) => {
  const skip = page === 0 ? page : page * perPage;
  return skip
}

export const getPageFromToForTab =  (page: number, rowsPerPage: number) => {
  const pageToPagination = page - 1;
  const pageFrom = pageToPagination === 0 ? 0 : pageToPagination * rowsPerPage;
  const pageTo = pageFrom + rowsPerPage;

  return {pageFrom, pageTo}
}