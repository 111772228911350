import { ListItemIcon, ListItemText, Menu, MenuItem, Typography } from "@mui/material";
import React from "react";
import { MainActionButton } from "../../../components/actionButton/MainActionButton";
import { usePopupActions } from "../../../utils/redux/storeHooks/actionsHooks/usePopupActions";
import { useSidebarData } from "../../../utils/redux/storeHooks/dataHooks/useSidebarData";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { useAccountDetailsData } from "../../../utils/redux/storeHooks/dataHooks/useAccountDetailsData";
import { useTableLeadsData } from "../../../utils/redux/storeHooks/dataHooks/useTableLeadsData";
import { AppTooltip } from "../../../components/appTooltipList/AppTooltip";
import { getUploadAbilityTooltipText } from "../../../utils/general/getUploadAbilityTooltipText";
import { useAutomationData } from "../../../utils/redux/storeHooks/dataHooks/useAutomationData";
import { UploadLeadsStatus } from "../../../types/pages/dashboardLeadsPeoplePage/uploadingLeads";
import { useTour } from "@reactour/tour";
import { useTableLeadsActions } from "../../../utils/redux/storeHooks/actionsHooks/useTableLeadsActions";

export const ImportDropDownMenu = () => {
  const { setPopUpAddLeadsUseSnOpen, setPopupImportCSVOpen } = usePopupActions();
  const { totalCountOfLeads, leadsLimit, checkOfValidCookie } = useSidebarData();
  const { uploadInfo, isDropDownMenuOpen } = useTableLeadsData();
  const { setIsDropDownMenuOpen } = useTableLeadsActions();
  const { isAutomationRunning } = useAutomationData();
  const { isGetUploadingLeadsLimitWeeklyRequest, weeklyUploadingLeadsLimit } = useAccountDetailsData();
  const { isOpen } = useTour();

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setIsDropDownMenuOpen(!!event.currentTarget);
  };

  const handleClose = () => {
    setIsDropDownMenuOpen(false)
  };

  const openPopLoadSnLink = () => {
    setPopUpAddLeadsUseSnOpen(true)
    setIsDropDownMenuOpen(false);
  }

  const openPopUpImportCsv = () => {
    setPopupImportCSVOpen(true)
    setIsDropDownMenuOpen(false);
  }

  const toolTipText = getUploadAbilityTooltipText({
    checkOfValidCookie,
    isTrackingLimitReached: totalCountOfLeads >= leadsLimit,
    isLeadsUploading: uploadInfo?.status === UploadLeadsStatus.UPLOADING,
    isLeadsAlreadyUpload: uploadInfo?.status === UploadLeadsStatus.COMPLETE,
    isGetUploadingLeadsLimitWeeklyRequest,
    isWeeklyLimitReached: weeklyUploadingLeadsLimit?.leadsUploadedPerRange >= weeklyUploadingLeadsLimit?.limitPerRange,
    isAutomationRunning
  })

  if (toolTipText && !isOpen) {
    return (
      <AppTooltip text={toolTipText}>
        <MainActionButton
          title='Add leads'
          icon={<PersonAddIcon />}
          isDisabled
          id='addLeadsButton'
        />
      </AppTooltip>
    )
  }

  return (
    <>
      <MainActionButton
        title='Add leads'
        icon={<PersonAddIcon />}
        actionOnClick={handleOpenMenu}
        id='addLeadsButton'
      />
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
          id: 'csvUploadFlow'
        }}
        anchorEl={document.getElementById('addLeadsButton')}
        open={isDropDownMenuOpen}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          style: {
            boxShadow: '4px 4px 8px 0px rgba(0, 0, 0, 0.15)',
            width: '220px',
            height: '90px',
            overflowX: 'unset',
            overflowY: 'unset',
          },
        }}
      >
        <MenuItem
          sx={{
            height: '40px',
          }}
          onClick={openPopUpImportCsv}

        >
          <ListItemIcon>
            <img alt="" src="/importCsv.svg" />
          </ListItemIcon>
          <ListItemText>
            <Typography sx={{ fontFamily: 'Kanit', fontWeight: 300, fontSize: 16 }}>
              Import CSV
            </Typography>
          </ListItemText>
        </MenuItem>

        <MenuItem
          sx={{
            height: '40px',
          }}
          onClick={openPopLoadSnLink}
        >
          <ListItemIcon>
            <img alt="" src="/snIcon.svg" />
          </ListItemIcon>
          <ListItemText>
            <Typography sx={{ fontFamily: 'Kanit', fontWeight: 300, fontSize: 16 }} >
              Use Sales Navigator
            </Typography>
          </ListItemText>
        </MenuItem>
      </Menu>
    </>
  )
}

