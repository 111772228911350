import { FeedTab } from "../../../types/pages/newFeedPage/feedTab"

export const getGeneralFeedDto = (page: number, pageFrom: number, pageTo: number, currentTab: FeedTab) => {
  return {
    currentPage: page,
    from: pageFrom,
    to: pageTo,
    includeKeywords: JSON.stringify(currentTab.includeKeywords),
    excludeKeywords: JSON.stringify(currentTab.excludeKeywords),
    daysOption: currentTab.daysOption.value,
    excludeLeadComentedBeforeDayCount: currentTab.excludeLeadComentedBeforeDayCount.value
  }
}

export const getTabFeedDto = (page: number, pageFrom: number, pageTo: number, tabId: string) => {
  return {
    currentPage: page,
    from: pageFrom,
    to: pageTo,
    tabId
  }
}