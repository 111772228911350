import React from 'react';
import styles from "./ImportCSV.module.scss";
import { Box, Grow, IconButton, Typography } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { usePopupActions } from '../../../utils/redux/storeHooks/actionsHooks/usePopupActions';
import { usePopupData } from '../../../utils/redux/storeHooks/dataHooks/usePopupData';

export const StepWithUnvalidLinks = () => {
  const { setPopupImportCSVOpen, resetUnvalidLinks } = usePopupActions();
  const { unvalidLinks } = usePopupData()

  const closePopUp = () => {
    resetUnvalidLinks();
    setPopupImportCSVOpen(false);
  }

  return (
    <Grow in={!!unvalidLinks}>
      <Box className={styles.popup}>
        <Box className={styles.header}>
          <Box className={styles.header__title}>
            Import CSV
          </Box>
          <IconButton
            color="default"
            onClick={closePopUp}
            className={styles.header__closeButton}
          >
            <CloseRoundedIcon sx={{ fill: '#191F2E' }} />
          </IconButton>
        </Box>
        <Box className={styles.contentSuccess}>
          <img src='/success.svg' alt='success' className={styles.contentSuccess__img} />
          <Typography className={styles.contentSuccess__title}>
            We have started processing your leads.
          </Typography>
          {unvalidLinks?.length > 0 && (
            <>
              <Typography className={styles.contentSuccess__subTitle}>
                {`*Please note, your file contains invalid links. We won't be able to process the leads listed below..`}
              </Typography>
              <Box className={styles.contentSuccess__unvalidLinks}>

                {unvalidLinks?.map((link => (
                  <Box key={link.position} className={styles.contentSuccess__link}>
                    <Box className={styles.contentSuccess__linkContent}>
                      <Box className={styles.contentSuccess__property}>- position:</Box>
                      <Box className={styles.contentSuccess__defenition}>{link?.position}</Box>
                    </Box>
                    <Box className={styles.contentSuccess__linkContent}>
                      <Box className={styles.contentSuccess__property}>url:</Box>
                      <Box className={styles.contentSuccess__defenition}>{
                        link.url?.length > 40
                          ? `${link.url.slice(0, 40)}...`
                          : link.url
                      }
                      </Box>
                    </Box>
                  </Box>
                )))}
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Grow>
  )
};
