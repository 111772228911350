import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AccountDetailsInitialState } from './types';
import {
  cancelPlan,
  confirmRestorePassword,
  getInvoicesHistory,
  getPlans,
  getSubscriptionDetails,
  checkIsUserAuth,
  payNow,
  restorePassword,
  sendEmailToChangePaymentDetails,
  signIn,
  upgradePlan,
  logOut,
  getUploadingLeadsLimit,
  skipTour
} from './thunks';
import { Payment_status } from '../../../../types/PPG/Payment_status';
import { AccessAppStatuses } from '../../../../types/PPG/AccessAppStatuses.enum';
import { getBlockedModalName } from '../../../pages/accountDetails/getBlockedModalName';
import { PPGSubscriptionStatus } from '../../../../types/PPG/PPGSubscriptionStatus';
import setUserInformationTolocalStorage from '../../../general/get_set_user_information';
import { getSideBarData } from '../sidebarSlice/thunks';

const initialState: AccountDetailsInitialState = {
  //Auth requests
  isUserAuth: false,
  isUserAuthRequest: true,
  isSendingRestorePasswordEmail: false,
  isSingInRequest: false,
  isConfirmRestorePasswordRequest: false,
  isEmailRestorePasswordSent: false,
  isPasswordShown: false,
  authPassword: '',
  authEmail: '',
  isAuthEmailValid: true,
  authError: null,
  checkingUserAlreadyAuth: true,
  isAuthPasswordValid: true,
  restoreEmail: '',
  isRestoreEmailValid: true,
  isImageBackgroundLoading: true,
  newPassword: '',
  isNewPasswordValid: true,
  newPasswordConfirm: '',
  restoreToken: null,
  isPasswordRestoreSuccess: false,
  //Get subscription
  accessAppStatuses: AccessAppStatuses.SUBSCRIPTION_ACTIVE,
  isGetSubscriptionDetailsRequest: false,
  activeSubscription: null,
  //Get plans
  isGetPlansRequest: false,
  plans: [],
  //Upgrade plan
  isRequestUpgradePlan: false,
  planToUpgrade: null,
  //Get invoices
  invoices: [],
  isGetInvoicesRequest: false,
  invoicePage: 0,
  totalInvoicesCount: 0,
  //Cancel plan
  isCancelPlanRequest: false,
  //Send email update payment details
  isRequestToSendEmailUpdatePaymentDetails: false,
  isPayNowRequest: false,
  //Plan Settings
  isGetUploadingLeadsLimitWeeklyRequest: false,
  weeklyUploadingLeadsLimit: null,
  //UserInformation
  userInformation: null,
  needRunTour: false,
  isSkipTourRequest: false,
  isAccountMenuOpen: false,
};

export const accountDetailsSlice = createSlice({
  name: 'accountDetailsSlice',
  initialState,
  reducers: {
    resetState: (state) => {
      return {
        ...initialState,
        isUserAuthRequest: false,
      };
    },
    setIsAccountMenuOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isAccountMenuOpen = payload;
    },
    runTour: (state, { payload }: PayloadAction<boolean>) => {
      // state.userInformation.isFirstAppEnter = true;
      state.needRunTour = true;
    },
    setIsUserAuth: (state, { payload }: PayloadAction<boolean>) => {
      state.isUserAuth = payload;
    },
    setIsPasswordSuccess: (state, { payload }: PayloadAction<boolean>) => {
      state.isPasswordRestoreSuccess = payload;
    },
    setIsNewPasswordValid: (state, { payload }: PayloadAction<boolean>) => {
      state.isNewPasswordValid = payload;
    },
    setRestoreToken: (state, { payload }: PayloadAction<string>) => {
      state.restoreToken = payload;
    },
    setNewPassword: (state, { payload }: PayloadAction<string>) => {
      state.newPassword = payload;
    },
    setNewPasswordConfirm: (state, { payload }: PayloadAction<string>) => {
      state.newPasswordConfirm = payload;
    },
    setIsImageBackgroundLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isImageBackgroundLoading = payload;
    },
    setIsRestoreEmailValid: (state, { payload }: PayloadAction<boolean>) => {
      state.isRestoreEmailValid = payload;
    },
    setRestoreEmail: (state, { payload }: PayloadAction<string>) => {
      state.restoreEmail = payload;
    },
    setIsPasswordShown: (state, { payload }: PayloadAction<boolean>) => {
      state.isPasswordShown = payload;
    },
    setIsAuthPassworValid: (state, { payload }: PayloadAction<boolean>) => {
      state.isAuthPasswordValid = payload;
    },
    setAuthPassword: (state, { payload }: PayloadAction<string>) => {
      state.authPassword = payload;
    },
    setAuthEmail: (state, { payload }: PayloadAction<string>) => {
      state.authEmail = payload;
    },
    setAuthEmailValid: (state, { payload }: PayloadAction<boolean>) => {
      state.isAuthEmailValid = payload;
    },
    setAuthError: (state, { payload }: PayloadAction<string | null>) => {
      state.authError = payload;
    },
    setIsCheckingUserAlreadyAuth: (state, { payload }: PayloadAction<boolean>) => {
      state.checkingUserAlreadyAuth = payload;
    },     
    setIsEmailRestorePasswordSent: (state, { payload }: PayloadAction<boolean>) => {
      state.isEmailRestorePasswordSent = payload;
    },
    setInvoicePage: (state, { payload }: PayloadAction<number>) => {
      state.invoicePage = payload;
    },
    setAccessAppStatus: (state, { payload }: PayloadAction<AccessAppStatuses>) => {
      state.accessAppStatuses = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSideBarData.fulfilled, (state, action) => {
      state.userInformation = action.payload.userInfo;
    })
    builder.addCase(payNow.pending, (state) => {
      state.isPayNowRequest = true;
    })
    builder.addCase(payNow.fulfilled, (state, action) => {
      state.isPayNowRequest = false;
      state.activeSubscription.paymentStatus = Payment_status.PENDING;
    })
    builder.addCase(payNow.rejected, (state, action) => {
      state.isPayNowRequest = false;
    })
    //IsUserAuth
    builder.addCase(checkIsUserAuth.pending, (state) => {
      state.isUserAuthRequest = true;
    })
    builder.addCase(checkIsUserAuth.fulfilled, (state, action) => {
      state.isUserAuthRequest = false;
      state.isUserAuth = true;
    })
    builder.addCase(checkIsUserAuth.rejected, (state, action) => {
      state.isUserAuthRequest = false;
      state.isUserAuth = false;
    })
    //SignIn
    builder.addCase(signIn.pending, (state) => {
      state.isSingInRequest = true;
    })
    builder.addCase(signIn.fulfilled, (state, action) => {
        state.isSingInRequest = false;
        setUserInformationTolocalStorage(action.payload)
        state.isUserAuth = true;
    })
    builder.addCase(signIn.rejected, (state, action) => {
      state.isSingInRequest = false;
      state.isUserAuth = false;
    })
    //LogOut
    builder.addCase(logOut.pending, (state) => {
      state.isUserAuthRequest = false;
    })
    builder.addCase(logOut.fulfilled, (state, action) => {
      state.isUserAuth = false;
    })
    builder.addCase(logOut.rejected, (state, action) => {
      state.isUserAuth = true;
    })
    //Restore password
    builder.addCase(restorePassword.pending, (state) => {
      state.isSendingRestorePasswordEmail = true;
    })
    builder.addCase(restorePassword.fulfilled, (state, action) => {
      state.isEmailRestorePasswordSent = true;
      state.isSendingRestorePasswordEmail = false;
    })
    builder.addCase(restorePassword.rejected, (state, action) => {
      state.isSendingRestorePasswordEmail = false;
    })
    //Confirm restore password
    builder.addCase(confirmRestorePassword.pending, (state) => {
      state.isConfirmRestorePasswordRequest = true;
    })
    builder.addCase(confirmRestorePassword.fulfilled, (state, action) => {
        state.isConfirmRestorePasswordRequest = false;
        setUserInformationTolocalStorage(action.payload)
        state.isUserAuth = true;
        state.isPasswordRestoreSuccess = true;
    })
    builder.addCase(confirmRestorePassword.rejected, (state, action) => {
      state.isConfirmRestorePasswordRequest = false;
      state.isUserAuth = false;
    })
    //Get subscription details
    builder.addCase(getSubscriptionDetails.pending, (state) => {
      state.isGetSubscriptionDetailsRequest = true;
    })
    builder.addCase(getSubscriptionDetails.fulfilled, (state, action) => {
      state.isGetSubscriptionDetailsRequest = false;
      const currentSubscription = action.payload.subscriptionList[0] || null;
      state.activeSubscription = currentSubscription;
      const modalName = getBlockedModalName(currentSubscription);

      if(!modalName) {
        state.accessAppStatuses = AccessAppStatuses.SUBSCRIPTION_ACTIVE
      }      
    })
    builder.addCase(getSubscriptionDetails.rejected, (state, action) => {
      state.isGetSubscriptionDetailsRequest = false;
    })
    //Get plans
    builder.addCase(getPlans.pending, (state) => {
      state.isGetPlansRequest = true;
    })
    builder.addCase(getPlans.fulfilled, (state, action) => {
        state.isGetPlansRequest = false;
        state.plans = action.payload;
    })
    builder.addCase(getPlans.rejected, (state, action) => {
      state.isGetPlansRequest = false;
    })
    //Upgrade plan
    builder.addCase(upgradePlan.pending, (state, action) => {
      state.isRequestUpgradePlan = true;
      state.planToUpgrade = action.meta.arg.plan
    })
    builder.addCase(upgradePlan.fulfilled, (state, action) => {
        state.isRequestUpgradePlan = false;
        state.activeSubscription.paymentStatus = Payment_status.PENDING
    })
    builder.addCase(upgradePlan.rejected, (state, action) => {
      state.isRequestUpgradePlan = false;
    })
    //Get invoices
    builder.addCase(getInvoicesHistory.pending, (state) => {
      state.isGetInvoicesRequest = true;
    })
    builder.addCase(getInvoicesHistory.fulfilled, (state, action) => {
        state.isGetInvoicesRequest = false;
        state.invoices = action.payload.response.orders;
        state.totalInvoicesCount = action.payload.response.totalCount
    })
    builder.addCase(getInvoicesHistory.rejected, (state, action) => {
      state.isGetInvoicesRequest = false;
    })
    //Cancel plan
    builder.addCase(cancelPlan.pending, (state) => {
      state.isCancelPlanRequest = true;
    })
    builder.addCase(cancelPlan.fulfilled, (state, action) => {
      state.activeSubscription.status = PPGSubscriptionStatus.Terminated;
      state.isCancelPlanRequest = false;
    })
    builder.addCase(cancelPlan.rejected, (state, action) => {
      state.isCancelPlanRequest = false;
    })
    //Send email update payment details
    builder.addCase(sendEmailToChangePaymentDetails.pending, (state) => {
      state.isRequestToSendEmailUpdatePaymentDetails = true;
    })
    builder.addCase(sendEmailToChangePaymentDetails.fulfilled, (state, action) => {
      state.isRequestToSendEmailUpdatePaymentDetails = false;
    })
    builder.addCase(sendEmailToChangePaymentDetails.rejected, (state, action) => {
      state.isRequestToSendEmailUpdatePaymentDetails = false;
    })
    //getUploadingLeadsLimit
    builder.addCase(getUploadingLeadsLimit.pending, (state) => {
      state.isGetUploadingLeadsLimitWeeklyRequest = true;
    })
    builder.addCase(getUploadingLeadsLimit.fulfilled, (state, action) => {
      state.isGetUploadingLeadsLimitWeeklyRequest = false;
      state.weeklyUploadingLeadsLimit = action.payload;
    })
    builder.addCase(getUploadingLeadsLimit.rejected, (state, action) => {
      state.isGetUploadingLeadsLimitWeeklyRequest = false;
    })
    //skipTour
    builder.addCase(skipTour.pending, (state) => {
      state.isSkipTourRequest = true;
    })
    builder.addCase(skipTour.fulfilled, (state) => {
      state.isSkipTourRequest = false;
      // state.userInformation.isFirstAppEnter = false;
      state.needRunTour = false;
      localStorage.setItem('isFirstAppEnter', String(false))
    })
    builder.addCase(skipTour.rejected, (state) => {
      state.isSkipTourRequest = false;
      state.needRunTour = false;
      localStorage.setItem('isFirstAppEnter', String(false))
    })
  }  
  }
);

export const accountDetailsActions = accountDetailsSlice.actions;
export const accountDetailsReducer = accountDetailsSlice.reducer;