import { Box, Typography } from "@mui/material";
import React from "react";
import styles from "../UsersStatistic.module.scss";
import { useUsersStatisticData } from "../../../utils/redux/storeHooks/dataHooks/useUsersStatisticData";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from "react-router-dom";
import { useTableLeadsActions } from "../../../utils/redux/storeHooks/actionsHooks/useTableLeadsActions";
import { useSidebarData } from "../../../utils/redux/storeHooks/dataHooks/useSidebarData";

export const UserStatisticReactions = () => {
  const { pendingReactions } = useUsersStatisticData();
  const { resetFilterBeforeRedirectFromDashboard } = useTableLeadsActions();
  const { isSideBarWide } = useSidebarData();
  const navigate = useNavigate();

  const onLeadsRedirect = () => {
    resetFilterBeforeRedirectFromDashboard()
    navigate('/leads')
  }

  return (
    <Box className={styles.usersStatistic__reactions}>
      <Box className={styles.usersStatistic__reactions__titleContainer}>
        <img src='/white_small_logo.svg' alt='' />
        <Typography className={styles.usersStatistic__reactions__title}>
          New Reactions
        </Typography>
        {pendingReactions > 0 && (
          <Box
            className={styles.usersStatistic__reactions__linkContainer}
            onClick={onLeadsRedirect}
          >
            <ArrowForwardIcon sx={{ color: '#fff' }} />
          </Box>
        )}
      </Box>
      <Box className={styles.usersStatistic__reactions__valueDescriptionContainer}>
        <Typography className={styles.usersStatistic__reactions__value}>
          {pendingReactions}
        </Typography>
        <Typography className={styles.usersStatistic__reactions__description}>
          Reactions from leads awaiting your action in the Leads tab
        </Typography>
      </Box>

      <img
        src="/heet_bg.svg"
        alt=""
        className={`${styles.usersStatistic__reactions__bg} ${isSideBarWide ? styles.usersStatistic__reactions__bg_wideSidebar : ''}`}
      />
    </Box>
  )
}

