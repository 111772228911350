import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TableLeadData } from '../../../../types/pages/dashboardLeadsPeoplePage/tableLeadData';
import { loadLeadsSnGet } from '../botManagementSlice/thunks';
import { TableLeadsSliceInitialState } from './types';
import { deleteLeadsAndUpdatedLeadList, downLoadReport, exportLeads, getLeadList, getListOfTeamMembersAndCheckCookies, getUploadingSnLinkForUser, markLeadsAsRead, markUploadAsDone, stopWorkUploadSnLink, updateFullLeadInfo } from './thunks';
import { LeadInformation } from '../../../../types/entities/leadInformation';
import { sendDM, sendInvitation, uploadCSV } from '../popupSlice/thunks';
import { getSubscriptionDetails } from '../AccounDetailsSlice/thunks';
import { Payment_status } from '../../../../types/PPG/Payment_status';

const initialState: TableLeadsSliceInitialState = {
  rows: [],
  allLeadsCount: 0,
  orderBy: 'firstName',
  order: 'ASC',
  
  selected: [],
  isAllSelected: false,
  page: 0,
  rowsPerPage: 10,
  leadToSearch: '',    
  loading: true,
  isSlotsLeft: true,
  statusOfSend: true,
  errorOfSend: '',
  responceText: '',
  selectedRowForMenu: null,
  isDeletingOrUpdateProcess: false,
  teamList: [],
  isTeamListLoading: false,
  isLoadingUploadRequest: false,
  uploadInfo: null,
  isMarkIsDoneRequest: false,
  isStopLoadLeadsRequestLoading: false,
  isDownloadingReport: false,
  isDropDownMenuOpen: false,
  //Filter
  isFilterOpen: false,
  selectedLeadConnectionPossition: null,
  dateInserted: {startDate: null, endDate: null},
  dateLastReplied: {startDate: null, endDate: null},
  dateLastCommented: {startDate: null, endDate: null},
  filterLeadName: '',
  filterCompanyName: '',
  filtersSelectedCount: 0,
  isFilterApply: false,
  appliedFilersCount: 0,
  isExportingLeads: false,
  isNeedToReloadLeadsByApplyingFilter: false,
  isHaveNewActivitySelect: false,
};

export const tableLeadsSlice = createSlice({
  name: 'tableLeadsSlice',
  initialState,
  reducers: {
    resetState: () => initialState,
    setFalseLoadingLeads: (state) => {
      state.loading = false;
    },  
    setMockLeads: (state, { payload }: PayloadAction<TableLeadData>) => {
      state.rows = payload.leads;
      state.allLeadsCount = payload.count;
      state.loading = false;
    },
    resetMockLeads: (state, { payload }: PayloadAction<TableLeadData>) => {
      state.rows = [];
      state.allLeadsCount = 0;
      state.loading = false;
    },    
    setRows: (state, { payload }: PayloadAction<TableLeadData>) => {
      state.rows = payload.leads;
    }, 
    setIsDropDownMenuOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isDropDownMenuOpen = payload;
    },
    setOrderBy: (state, { payload }: PayloadAction<string>) => {
      state.orderBy = payload;
    },
    setOrder: (state, { payload }: PayloadAction<'ASC' | 'DESC'>) => {
      state.order = payload;
    },
    toggleSelected: (state, { payload }: PayloadAction<string>) => {
      if (state.selected.includes(payload)) {
        state.selected = state.selected.filter(id => id !== payload)
        return;
      }

      state.selected.push(payload);
    },
    setSelected: (state, { payload }: PayloadAction<string[]>) => {
      state.selected = payload;
    },
    setPage: (state, { payload }: PayloadAction<number>) => {
      state.page = payload;
    },
    setRowsPerPage: (state, { payload }: PayloadAction<number>) => {
      state.rowsPerPage = payload;
    },
    setLeadToSearch: (state, { payload }: PayloadAction<string>) => {
      state.leadToSearch = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setSlotsLeft: (state, { payload }: PayloadAction<boolean>) => {
      state.isSlotsLeft = payload;
    },
    setStatusOfSend: (state, { payload }: PayloadAction<boolean>) => {
      state.statusOfSend = payload;
    },
    setErrorOfSend: (state, { payload }: PayloadAction<string>) => {
      state.errorOfSend = payload;
    },
    setResponceText: (state, { payload }: PayloadAction<string>) => {
      state.responceText = payload;
    },    
    setSelectedRowForMenu: (state, { payload }: PayloadAction<LeadInformation | null>) => {
      state.selectedRowForMenu = payload;
    },
    setDeletingOrUpdateProcess: (state, { payload }: PayloadAction<boolean>) => {
      state.isDeletingOrUpdateProcess = payload;
    },
    setDateInserted: (state, { payload }: PayloadAction<{startDate: string | null, endDate?: string | null}>) => {
      state.dateInserted = payload;
    },
    setDateLastReplied: (state, { payload }: PayloadAction<{startDate: string | null, endDate?: string | null}>) => {
      state.dateLastReplied = payload;
    },
    setDateLastCommented: (state, { payload }: PayloadAction<{startDate: string | null, endDate?: string | null}>) => {
      state.dateLastCommented = payload;
    },
    setIsFilterOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isFilterOpen = payload;
    },
    setSelectedLeadConnectionPosition: (state, { payload }: PayloadAction<string | null>) => {
      state.selectedLeadConnectionPossition = payload;
    },
    setIsFilterApply: (state, { payload }: PayloadAction<boolean>) => {
      state.isFilterApply = payload;
    },
    setFiltersSelectedCount: (state, { payload }: PayloadAction<number>) => {
      state.filtersSelectedCount = payload;
    },
    setFilterCompanyName: (state, { payload }: PayloadAction<string>) => {
      state.filterCompanyName = payload;
    },
    setFilterLeadName: (state, { payload }: PayloadAction<string>) => {
      state.filterLeadName = payload;
    },
    setAppliedFilersCount: (state, { payload }: PayloadAction<number>) => {
      state.appliedFilersCount = payload;
    },
    setIsExportingLeads: (state, { payload }: PayloadAction<boolean>) => {
      state.isExportingLeads = payload;
    },
    setIsNeedToReloadLeadsByApplyingFilter: (state, { payload }: PayloadAction<boolean>) => {
      state.isNeedToReloadLeadsByApplyingFilter = payload;
    },
    setIsHaveNewActivitySelect: (state, { payload }: PayloadAction<boolean>) => {
      state.isHaveNewActivitySelect = payload;
    },
    setIsAllSelected: (state, { payload }: PayloadAction<boolean>) => {
      state.isAllSelected = payload;
    },
    resetFilterBeforeRedirectFromDashboard: (state) => {
      state.filterLeadName = '';
      state.filterCompanyName = '';
      state.selectedLeadConnectionPossition = null;
      state.dateInserted = { startDate: null, endDate: null };
      state.dateLastCommented = { startDate: null, endDate: null };
      state.dateLastReplied = { startDate: null, endDate: null };
      state.isFilterApply = true;
      state.appliedFilersCount = 1;
      state.isFilterOpen = false;
      state.leadToSearch = '';
      state.isHaveNewActivitySelect = true;
    }
  },
  
  extraReducers: (builder) => {
    //Get Lead List
    builder.addCase(getLeadList.pending, (state) => {
      state.loading = true;
      state.responceText = ''
    })
    builder.addCase(getLeadList.fulfilled, (state, action) => {
        state.rows = action.payload.leads;
        state.allLeadsCount = action.payload.count;
        state.loading = false;
    })
    builder.addCase(getLeadList.rejected, (state, action) => {
      state.loading = false;
      state.rows = []
    })
    //Delete and update Lead List
    builder.addCase(deleteLeadsAndUpdatedLeadList.pending, (state) => {
      state.isDeletingOrUpdateProcess = true;  
    })
    builder.addCase(deleteLeadsAndUpdatedLeadList.fulfilled, (state, action) => {
      state.rows = action.payload.leads;
      state.isDeletingOrUpdateProcess = false;
      state.selected = [];
      state.selectedRowForMenu = null;
      state.isAllSelected = false;
      state.allLeadsCount = action.payload.count;    
    })
    builder.addCase(deleteLeadsAndUpdatedLeadList.rejected, (state) => {
      state.isDeletingOrUpdateProcess = false; 
      state.isAllSelected = false;
      state.selected = [];      
    })
    //Mark as Read Leads and update Lead List
    builder.addCase(markLeadsAsRead.pending, (state) => {
      state.isDeletingOrUpdateProcess = true;  
    })
    builder.addCase(markLeadsAsRead.fulfilled, (state, action) => {
      state.rows = action.payload.leads;
      state.isDeletingOrUpdateProcess = false;
      state.selected = [];
      state.selectedRowForMenu = null;  
      state.isAllSelected = false;  
    })
    builder.addCase(markLeadsAsRead.rejected, (state) => {
      state.isDeletingOrUpdateProcess = false; 
      state.selected = [];
      state.isAllSelected = false;      
    })
    //update Full LeadInfo
    builder.addCase(updateFullLeadInfo.pending, (state) => {
      state.isDeletingOrUpdateProcess = true;      
    })
    builder.addCase(updateFullLeadInfo.fulfilled, (state, action) => {
      const currentLead = state.rows.find(lead => lead.id === action.payload.id)
      if(currentLead) {
        currentLead.firstName = action.payload.firstName
        currentLead.lastName = action.payload.lastName
        currentLead.title = action.payload.title
        currentLead.companyName = action.payload.companyName
        currentLead.profileImg = action.payload.profileImg
        currentLead.conversationId = action.payload.conversationId
        currentLead.leadIdentity = action.payload.leadIdentity
        currentLead.leadConnectionPosition = action.payload.leadConnectionPosition
        currentLead.statusOfInvitation = action.payload.statusOfInvitation
        currentLead.leadIdentitySN = action.payload.leadIdentitySN
        currentLead.profileUrl = action.payload.profileUrl
      }
      state.isDeletingOrUpdateProcess = false;
    })
    builder.addCase(updateFullLeadInfo.rejected, (state, action) => {
      state.isDeletingOrUpdateProcess = false;
    })
    //get team list
    builder.addCase(getListOfTeamMembersAndCheckCookies.pending, (state) => {
      state.isTeamListLoading = true;      
    })
    builder.addCase(getListOfTeamMembersAndCheckCookies.fulfilled, (state, action) => {
      state.teamList = action.payload.filter(user => user.premiumSubscriber);
      state.isTeamListLoading = false;
    })
    builder.addCase(getListOfTeamMembersAndCheckCookies.rejected, (state, action) => {
      state.isTeamListLoading = false;
    })
    //get uploading sn link
    builder.addCase(getUploadingSnLinkForUser.pending, (state) => {
      state.isLoadingUploadRequest = true;      
    })
    builder.addCase(getUploadingSnLinkForUser.fulfilled, (state, action) => {
      state.uploadInfo = action.payload;
      state.isLoadingUploadRequest = false;
    })
    builder.addCase(getUploadingSnLinkForUser.rejected, (state, action) => {
      state.isLoadingUploadRequest = false;
    })
    //LoadLeadsSnGet
    builder.addCase(loadLeadsSnGet.fulfilled, (state, action) => {
      state.uploadInfo = action.payload.snLink;
    })
    //ImportCSV
    builder.addCase(uploadCSV.fulfilled, (state, action) => {
      state.uploadInfo = action.payload.csv;
    })
    //Send mark is done request
    builder.addCase(markUploadAsDone.pending, (state) => {
      state.isMarkIsDoneRequest = true;    
    })
    builder.addCase(markUploadAsDone.fulfilled, (state) => {
      state.isMarkIsDoneRequest = false; 
      state.uploadInfo = null; 
    })
    builder.addCase(markUploadAsDone.rejected, (state) => {
      state.isMarkIsDoneRequest = false; 
    })
    //DownLoad report
    builder.addCase(downLoadReport.pending, (state) => {
      state.isDownloadingReport = true;    
    })
    builder.addCase(downLoadReport.fulfilled, (state) => {
      state.isDownloadingReport = false; 
    })
    builder.addCase(downLoadReport.rejected, (state) => {
      state.isDownloadingReport = false; 
    })
    //StopWorkForUploadingLeads
    builder.addCase(stopWorkUploadSnLink.pending, (state) => {
      state.isStopLoadLeadsRequestLoading = true;    
    })
    builder.addCase(stopWorkUploadSnLink.fulfilled, (state, action) => {
      state.isStopLoadLeadsRequestLoading = false; 
      state.uploadInfo = action.payload.uploadInfo; 
    })
    builder.addCase(stopWorkUploadSnLink.rejected, (state) => {
      state.isStopLoadLeadsRequestLoading = false; 
    })
    //Send invitation
    builder.addCase(sendInvitation.fulfilled, (state, action) => {
      if(action.payload.isSent) {
        const lead = state.rows.find(lead => lead.id === action.payload.leadId);
        lead.haveNewActivity = false;
        lead.statusOfInvitation = 'PENDING'
      } 
    })
    //Send DM
    builder.addCase(sendDM.fulfilled, (state, action) => {
      if(action.payload.isSent) {
        const lead = state.rows.find(lead => lead.id === action.payload.leadId);
        lead.haveNewActivity = false;
      } 
    })
    //Export leads
    builder.addCase(exportLeads.pending, (state) => {
      state.isExportingLeads = true;    
    })
    builder.addCase(exportLeads.fulfilled, (state) => {
      state.isExportingLeads = false; 
    })
    builder.addCase(exportLeads.rejected, (state) => {
      state.isExportingLeads = false; 
    })
    //get subscription details
    builder.addCase(getSubscriptionDetails.fulfilled, (state, action) => {
      if(action.meta.arg.isUpdateMethod && action.meta.arg.needReload) {
        const activeSubscription = action.payload.subscriptionList.find(subscription => subscription.permission === 'allow_app_access' 
          && subscription.isActive && subscription.paymentStatus === Payment_status.CONFIRMED ) || null;
        
          if(activeSubscription) {
            window.history.go(0);
          }
      }
    })
  }
});

export const tableLeadsSliceActions = tableLeadsSlice.actions;
export const tableLeadsReducer = tableLeadsSlice.reducer;