import React, { useEffect, useRef, useState } from "react";
import scss from '../Auth.module.scss'
import { Typography, Box, Button } from "@mui/material";
import { MainActionButton } from "../../../components/actionButton/MainActionButton";
import { StyledTextFieldWithErrorAuth } from "../../../components/styledTextField/StyledTextField";
import { useAccountDetailsActions } from "../../../utils/redux/storeHooks/actionsHooks/useAccountDetailsActions";
import { useAccountDetailsData } from "../../../utils/redux/storeHooks/dataHooks/useAccountDetailsData";
import { useNavigate, useOutletContext } from "react-router-dom";
import { AuthContextType, AuthFlow } from "../../../types/pages/auth/authGeneral";
const TIMER_DURATION = 30;

export const SendRestoreEmail = () => {
  const [resendTimer, setResendTimer] = useState(TIMER_DURATION);
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const {
    restorePassword
  } = useAccountDetailsActions();
  const {
    authEmail,
    restoreEmail,
    isRestoreEmailValid,
    isSendingRestorePasswordEmail
  } = useAccountDetailsData();
  const {
    handleEmailChange,
    checkIsEmailValid
  } = useOutletContext<AuthContextType>();
  const navigate = useNavigate();
  const authFlow = AuthFlow.SENDRESTOREEMAIL;

  const handleRestore = async () => {
    const isEmailValid = checkIsEmailValid(restoreEmail, authFlow)
    if (!isEmailValid) {
      return;
    }

    await restorePassword({
      email: restoreEmail
    })

    setIsResendDisabled(true);
    setResendTimer(TIMER_DURATION);
    window.sessionStorage.setItem('resendTimerTimestamp', Date.now().toString());

    timerRef.current = setInterval(() => {
      setResendTimer((prev) => {
        if (prev <= 1) {
          clearInterval(timerRef.current);
          setIsResendDisabled(false);
          window.sessionStorage.removeItem('resendTimerTimestamp');
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  }

  useEffect(() => {
    const savedTimestamp = window.sessionStorage.getItem('resendTimerTimestamp');
    if (savedTimestamp) {
      const elapsedTime = Math.floor((Date.now() - Number(savedTimestamp)) / 1000);
      const remainingTime = TIMER_DURATION - elapsedTime;

      if (remainingTime > 0) {
        setResendTimer(remainingTime);
        setIsResendDisabled(true);

        timerRef.current = setInterval(() => {
          setResendTimer((prev) => {
            if (prev <= 1) {
              clearInterval(timerRef.current);
              setIsResendDisabled(false);
              window.sessionStorage.removeItem('resendTimerTimestamp');
              return 0;
            }
            return prev - 1;
          });
        }, 1000);
      }
    }

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (authEmail && !restoreEmail) {
      handleEmailChange(authEmail, authFlow)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box className={scss.auth__form}>
      <Box className={scss.auth__form__inputWrapper}>
        <Typography className={scss.auth__form__label}>
          Email Address
        </Typography>
        <StyledTextFieldWithErrorAuth
          value={restoreEmail}
          onChange={(e) => handleEmailChange(e.target.value, authFlow)}
          error={!isRestoreEmailValid && !!restoreEmail}
          helperText={!isRestoreEmailValid && !!restoreEmail && 'Please enter a valid email address'}
        />
      </Box>
      <MainActionButton
        width={468}
        height={44}
        title={isResendDisabled ? `resend in ${resendTimer} s` : 'Restore'}
        borderRadius="4px"
        backgroundMainColor="rgba(109, 48, 183, 1)"
        customHoverColor="rgba(130, 70, 190, 1)"
        fontSize={16}
        actionOnClick={handleRestore}
        isDisabled={isSendingRestorePasswordEmail || !checkIsEmailValid(restoreEmail, authFlow) || isResendDisabled}
        isLoading={isSendingRestorePasswordEmail}
      />

      <Box className={scss.auth__form__labelRestore}>
        Remembered your password? Back to
        <Button
          variant="text"
          className={scss.auth__form__restoteBtn}
          onClick={() => navigate('/sign-in')}
        >
          Sign in
        </Button>
      </Box>
    </Box>
  );
}

