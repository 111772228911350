import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AdminPageProxySliceInitialState } from './types';
import { addDefaultProxy, deleteDefaultProxy, getDefaultProxyList, getUsersProxyList, updateDefaultProxy, updateUserProxy } from './thunks';
import { MonitoringProxy, MonitoringUsersProxyEntity, ProxyPageType } from '../../../../types/pages/adminPage/proxy';
import { logOut } from '../AccounDetailsSlice/thunks';

const initialState: AdminPageProxySliceInitialState = {
  proxies: [],
  defaultProxies: [],
  isGetUsersProxiesRequest: false,
  isGetDefaultProxyRequest: false,
  isUpdateUsersProxiesRequest: false,
  isUpdateDefaultProxyRequest: false,
  isAddDefaultProxyRequest: false,
  isDeleteDefaultProxyRequest: false,
  proxyPageType: ProxyPageType.USERPROXIES,
  manageCurrentUserProxy: null,
  manageCurrentDefaultProxy: null,
  availableWebShareProxies:  [], 
};

export const adminPageProxySlice = createSlice({
  name: 'adminPageProxySlice',
  initialState,
  reducers: {
    resetState: () => initialState,
    setManageCurrentUserProxy: (state, { payload }: PayloadAction<MonitoringUsersProxyEntity>) => {
      state.manageCurrentUserProxy = payload;
    },
    setManageCurrentDefaultProxy: (state, { payload }: PayloadAction<MonitoringProxy>) => {
      state.manageCurrentDefaultProxy = payload;
    },
    setProxyPageType: (state, { payload }: PayloadAction<ProxyPageType>) => {
      state.proxyPageType = payload;
    },
  },
  
  extraReducers: (builder) => {
    //Get Users Proxy List
    builder.addCase(getUsersProxyList.pending, (state) => {
      state.isGetUsersProxiesRequest = true;
    })
    builder.addCase(getUsersProxyList.fulfilled, (state, action) => {
      state.isGetUsersProxiesRequest = false;
      state.proxies = action.payload.monitoringProxies;
      state.availableWebShareProxies = action.payload.availableWebShareProxies;
    })
    builder.addCase(getUsersProxyList.rejected, (state, action) => {
      state.isGetUsersProxiesRequest = false;
    })
    //Get Default Proxy List
    builder.addCase(getDefaultProxyList.pending, (state) => {
      state.isGetDefaultProxyRequest = true;
    })
    builder.addCase(getDefaultProxyList.fulfilled, (state, action) => {
      state.isGetDefaultProxyRequest = false;
      state.defaultProxies = action.payload;
    })
    builder.addCase(getDefaultProxyList.rejected, (state, action) => {
      state.isGetDefaultProxyRequest = false;
    }) 
    //Update Users Proxy
    builder.addCase(updateUserProxy.pending, (state) => {
      state.isUpdateUsersProxiesRequest = true;
    })
    builder.addCase(updateUserProxy.fulfilled, (state, action) => {
      const currentProxyEntity = state.proxies.find(proxy => proxy.user.id === action.meta.arg.userId)
      if(currentProxyEntity) {
        currentProxyEntity.proxy = action.payload.assignedProxy;
        currentProxyEntity.user.proxyFlow = action.meta.arg.proxyFlow;
      }
      state.isUpdateUsersProxiesRequest = false;
      state.manageCurrentUserProxy = null;
    })
    builder.addCase(updateUserProxy.rejected, (state, action) => {
      state.isUpdateUsersProxiesRequest = false;
    })  
    //Update Default Proxy
    builder.addCase(updateDefaultProxy.pending, (state) => {
      state.isUpdateDefaultProxyRequest = true;
    })
    builder.addCase(updateDefaultProxy.fulfilled, (state, action) => {
      const {assignedProxy} = action.payload
      state.defaultProxies = state.defaultProxies.filter(proxy => proxy.id !== action.meta.arg.oldProxyId)
      state.defaultProxies.push(assignedProxy)
      state.isUpdateDefaultProxyRequest = false;
      state.manageCurrentDefaultProxy = null;

    })
    builder.addCase(updateDefaultProxy.rejected, (state, action) => {
      state.isUpdateDefaultProxyRequest = false;
    })
    //Add Default Proxy
    builder.addCase(addDefaultProxy.pending, (state) => {
      state.isAddDefaultProxyRequest = true;
    })
    builder.addCase(addDefaultProxy.fulfilled, (state, action) => {
      const {assignedProxy} = action.payload
      state.defaultProxies.push(assignedProxy)
      state.isAddDefaultProxyRequest = false;
      })
    builder.addCase(addDefaultProxy.rejected, (state, action) => {
      state.isAddDefaultProxyRequest = false;
    })
    //Delete Default Proxy
    builder.addCase(deleteDefaultProxy.pending, (state) => {
      state.isDeleteDefaultProxyRequest = true;
    })
    builder.addCase(deleteDefaultProxy.fulfilled, (state, action) => {
      console.log('delete', action.meta)
      state.defaultProxies = state.defaultProxies.filter(proxy => proxy.id !== action.meta.arg.proxyId)
      state.isDeleteDefaultProxyRequest = false;
      state.manageCurrentDefaultProxy = null;
    })
    builder.addCase(deleteDefaultProxy.rejected, (state, action) => {
      state.isDeleteDefaultProxyRequest = false;
    })    
  }
});

export const adminPageProxySliceActions = adminPageProxySlice.actions;
export const adminPageProxyReducer = adminPageProxySlice.reducer;