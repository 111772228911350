import { Box, Button, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { usePopupActions } from '../../utils/redux/storeHooks/actionsHooks/usePopupActions';
import { useTableLeadsData } from '../../utils/redux/storeHooks/dataHooks/useTableLeadsData';
import { useTableLeadsActions } from '../../utils/redux/storeHooks/actionsHooks/useTableLeadsActions';
import CancelIcon from '@mui/icons-material/Cancel';
import { useLocation } from 'react-router-dom';
import { UploadLeadsStatus } from '../../types/pages/dashboardLeadsPeoplePage/uploadingLeads';
import { DeterminateCircularProgress } from '../determinateCircularProgress/DeterminateCircularProgress';
import { UploadingIndicatorWrapper } from './UploadingIndicatorWrapper';
import styles from './UploadingIndicator.module.scss'

export const UploadingIndicator = () => {
  const { setPopUpStopParsingLeads, setPopUpUploadReportOpen } = usePopupActions();
  const { getUploadingSnLinkForUser } = useTableLeadsActions();
  const { uploadInfo } = useTableLeadsData();
  const pathname = useLocation().pathname;
  const isLeadParsingInProgress = uploadInfo?.status === UploadLeadsStatus.UPLOADING;
  const isLeadParsingComplete = uploadInfo?.status === UploadLeadsStatus.COMPLETE;
  const isParsingError = !!uploadInfo?.uploadingError;

  const wrapperProps = { isLeadParsingInProgress, isLeadParsingComplete, isParsingError }

  const getPercent = () => {
    if (uploadInfo) {
      const uploadedLeads = uploadInfo.leadInformation;
      const totalLeadsCount = uploadInfo.uploadingReport?.totalLeadsCount;
      if (typeof uploadedLeads === 'number') {
        return (uploadedLeads / totalLeadsCount) * 100
      }
    }
  }

  const onStopUpload = () => {
    setPopUpStopParsingLeads(true)
  }

  const onOpenReport = () => {
    setPopUpUploadReportOpen(true)
  }

  useEffect(() => {
    if (!isLeadParsingInProgress) return;

    getUploadingSnLinkForUser();

    const interval = setInterval(getUploadingSnLinkForUser, 3000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, isLeadParsingInProgress]);


  useEffect(() => {
    getUploadingSnLinkForUser();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  if (isParsingError) {
    return (
      <UploadingIndicatorWrapper wrapperProps={wrapperProps}>
        <>
          <Typography sx={{ fontFamily: 'Kanit', fontSize: 16, color: '#191F2E' }}>
            Issue upload leads
          </Typography>
          <Button
            onClick={onOpenReport}
            variant='contained'
            className={styles.actionButton}
          >
            <Typography sx={{ fontFamily: 'Kanit', fontSize: 16, color: '#191F2E' }}>
              show report
            </Typography>
          </Button>
        </>
      </UploadingIndicatorWrapper>
    )
  }

  if (isLeadParsingInProgress) {
    return (
      <UploadingIndicatorWrapper wrapperProps={wrapperProps}>
        <>
          <DeterminateCircularProgress sx={{ color: '#191F2E' }} size={34} value={getPercent() || 0} />
          <Typography sx={{ fontFamily: 'Kanit', fontSize: 16, color: '#191F2E' }}>
            Processing your leads
          </Typography>
          <Button
            onClick={onStopUpload}
            variant='contained'
            className={styles.actionButton}
          >
            <CancelIcon sx={{ color: '#191F2E' }} />
            <Typography sx={{ fontFamily: 'Kanit', fontSize: 16, color: '#191F2E' }}>
              stop
            </Typography>
          </Button>
        </>
      </UploadingIndicatorWrapper>
    )
  }

  if (isLeadParsingComplete) {
    return (
      <UploadingIndicatorWrapper wrapperProps={wrapperProps}>
        <>
          <Typography sx={{ fontFamily: 'Kanit', fontSize: 16, color: '#191F2E' }}>
            Upload complete
          </Typography>
          <Button
            onClick={onOpenReport}
            variant='contained'
            className={styles.actionButton}
          >
            <Typography sx={{ fontFamily: 'Kanit', fontSize: 16, color: '#191F2E' }}>
              show report
            </Typography>
          </Button>
        </>
      </UploadingIndicatorWrapper>
    )
  }

  return (
    <Box sx={{ display: 'none' }}></Box>
  )
}

