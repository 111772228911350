import * as React from 'react';
import { PagesTabs } from '../../components/pagesTabs/PagesTabs';
import { useNewFeedData } from '../../utils/redux/storeHooks/dataHooks/useNewFeedData';
import { Actions } from './features/Actions/Actions';
import { PostsList } from './features/Post/PostsList';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { TabsType } from '../../types/general/pagesTabs';
import { useNewFeedActions } from '../../utils/redux/storeHooks/actionsHooks/useNewFeedActions';
import { CustomLinearProgress } from '../../utils/pages/newFeedPage/CustomLinearProgress';
import { Box } from '@mui/material';
import { useTour } from '@reactour/tour';

export const NewFeedPage = () => {
  const { tabs, isSuccessDelete, deleteOrUpdateTabId, isAllTabsLoading } = useNewFeedData();
  const {
    deleteTabFromState,
    getAllTabs,
    getPromptList,
  } = useNewFeedActions();
  const navigate = useNavigate()
  const { isOpen } = useTour();

  useEffect(() => {
    if (isSuccessDelete) {
      navigate('/feed/general', { replace: true })
      setTimeout(() => {
        deleteTabFromState(deleteOrUpdateTabId)
      }, 500)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessDelete])

  useEffect(() => {
    if (!isOpen) {
      getAllTabs();
      getPromptList();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isAllTabsLoading) {
    return (
      <CustomLinearProgress sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        height: '4px',
        width: '101%'
      }} />
    )
  }

  return (
    <Box>
      <PagesTabs tabs={tabs} tabsType={TabsType.feed} />
      <Actions />
      <PostsList />
    </Box>

  )
}

