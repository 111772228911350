import * as React from 'react';
import { SinglePost } from './SinglePost';
import { Box, SelectChangeEvent } from '@mui/material';
import style from '../../newFeedPage.module.scss'
import { useNewFeedData } from '../../../../utils/redux/storeHooks/dataHooks/useNewFeedData';
import { useParams } from 'react-router-dom';
import { useNewFeedActions } from '../../../../utils/redux/storeHooks/actionsHooks/useNewFeedActions';
import { useEffect } from 'react';
import { NoDataAtSearch } from '../../../../components/noDataForSearch/NoDataForSearch';
import { getPageFromToForTab } from '../../../../utils/general/getPageFromTo';
import { PostsPagination } from './PostsPagination';
import { getGeneralFeedDto, getTabFeedDto } from '../../../../utils/pages/newFeedPage/getFeedDto';
import { useTour } from '@reactour/tour';

export const PostsList = () => {
  const { tabs, pagesForTabs, isGetFeedLoading, postsPerPage } = useNewFeedData();
  const { getFeed, getFeedGeneralTab, resetCurrenPagesForTab, resetFeedForTab, setPostPerPage } = useNewFeedActions();
  const { tabId } = useParams();
  const currentTab = tabs.find(tab => String(tab.id) === tabId) || null;
  const currentPagesForTab = pagesForTabs.find(pages => pages.tabId === tabId);
  const paginationCount = Math.ceil(currentTab?.feedCount / postsPerPage) || 1;
  const { isOpen } = useTour();

  const handlePostsPerPageChange = (event: SelectChangeEvent<number>) => {
    setPostPerPage(Number(event.target.value));
    onFeedRequestHandler(1, Number(event.target.value));
  };

  const getPageFromToAndScrollUp = (page: number, rowsPerPage: number) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    return getPageFromToForTab(page, rowsPerPage)
  }

  const onFeedRequestHandler = (page: number, rowsPerPage: number) => {
    const { pageFrom, pageTo } = getPageFromToAndScrollUp(page, rowsPerPage);

    if (currentTab.id === 'general') {
      const payload = getGeneralFeedDto(page, pageFrom, pageTo, currentTab)
      getFeedGeneralTab(payload)
      return;
    }
    const payload = getTabFeedDto(page, pageFrom, pageTo, tabId)
    getFeed(payload)
  }

  useEffect(() => {
    if (currentTab?.id === 'general' && !isOpen) {
      resetFeedForTab('general')
      const payload = getGeneralFeedDto(1, 0, 10, currentTab)
      getFeedGeneralTab(payload)
      resetCurrenPagesForTab('general')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentTab?.daysOption,
    currentTab?.excludeKeywords,
    currentTab?.includeKeywords,
    currentTab?.excludeLeadComentedBeforeDayCount
  ])

  if (!isGetFeedLoading && currentTab?.feed?.length === 0) {
    return (
      <NoDataAtSearch />
    )
  }

  return (
    currentTab && (
      <Box className={style.postsWrapper}>
        {currentTab.feed.map(post => <SinglePost key={post.id} post={post} />)}
        <Box className={style.paginationWrapper}>
          {paginationCount > 1 && <PostsPagination
            postsPerPage={postsPerPage}
            feedCount={currentTab.feedCount}
            currentPage={currentPagesForTab.page}
            onFeedRequestHandler={onFeedRequestHandler}
            handlePostsPerPageChange={handlePostsPerPageChange}
          />}
        </Box>
      </Box>
    )
  )
}

