import React from 'react';
import styles from "./UploadLeadsReport.module.scss";
import { Box, Typography } from '@mui/material';
import { useTableLeadsData } from '../../../utils/redux/storeHooks/dataHooks/useTableLeadsData';
import CheckIcon from '@mui/icons-material/Check';
import { UploadLeadsError } from '../../../types/pages/dashboardLeadsPeoplePage/uploadingLeads';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CookieIcon from '@mui/icons-material/Cookie';
import SettingsIcon from '@mui/icons-material/Settings';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';

export const UploadLeadsReportHeader = () => {
  const { uploadInfo } = useTableLeadsData();

  const getHeaderContentStyleOptions = () => {
    if (!uploadInfo?.uploadingError) {
      const isSnLink = 'link' in uploadInfo;
      return {
        icon: <CheckIcon sx={{ color: 'rgba(0, 170, 37, 1)' }} />,
        text: isSnLink ? 'Congrats! Your sales nav link has been uploaded:' : 'Congrats! Your file has been uploaded:',
        backColor: 'rgba(235, 255, 232, 1)',
        spanText: uploadInfo && 'fileName' in uploadInfo ? uploadInfo.fileName.slice(0, 12) + '...' : null
      }
    }

    switch (uploadInfo.uploadingError) {
      case UploadLeadsError.TO_MANY_REQUEST:
        return {
          icon: <AccessTimeIcon sx={{ color: 'rgba(221, 87, 43, 1)' }} />,
          text: `Sorry, you can't upload any more leads today due to LinkedIn limits. Please try again tomorrow.`,
          backColor: 'rgba(255, 183, 163, 1)',
          spanText: ''
        }
      case UploadLeadsError.LINKEDIN_DENNY_ACCESS:
        return {
          icon: <CookieIcon sx={{ color: 'rgba(233, 147, 96, 1)' }} />,
          text: `Sorry, perhaps your cookies have expired. Please ensure that our Extension is enabled, then perform a few actions on LinkedIn while it is active.`,
          backColor: 'rgba(251, 231, 177, 1)',
          spanText: ''
        }
      case UploadLeadsError.LIMIT_ERROR:
        return {
          icon: <DoNotDisturbAltIcon sx={{ color: 'rgba(221, 87, 43, 1)' }} />,
          text: `Warning, You've reached your leads limit. Please, upgrade to add more`,
          backColor: 'rgba(251, 231, 177, 1)',

        }
      default:
        return {
          icon: <SettingsIcon sx={{ color: 'rgba(32, 92, 155, 1)' }} />,
          text: `Sorry, something went wrong:( We're working to fix the issue. Try again later.`,
          backColor: 'rgba(177, 208, 232, 1)',
          spanText: ''
        }
    }
  }

  const { icon, text, backColor, spanText } = getHeaderContentStyleOptions()

  return (
    <Box
      className={styles.header}
      sx={{
        backgroundColor: backColor
      }}
    >
      {icon}
      <Typography className={styles.header__typography}>
        {text}
      </Typography>
      {spanText && <Box className={styles.header__span}>
        {spanText}
      </Box>}
    </Box>
  );
};
