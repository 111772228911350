import React from 'react';
import { FunctionComponent } from "react";
import AccountMenu from '../accountMenu/account'
import scss from './header.module.scss';
import { Link, useLocation } from 'react-router-dom';
import { AutomationIndicator } from '../automationIndicator/AutomationIndicator';
import { HeaderNotifications } from './features/HeaderNotifications';
import { CheckNotificationsIndicator } from '../checkNotificationInsdicator/CheckNotificationsIndicator';
import { UploadingIndicator } from '../UploadingIndicator/UploadingIndicator';

const Header: FunctionComponent = () => {
  const location = useLocation();
  const logoShowPaths = [
    '/automation',
    '/prompts-manager',
    '/bot-management',
    '/bot-management/initial-feed',
    '/admin-page/users',
    '/admin-page/teams',
    '/admin-page/proxies',
    // '/account-details'
  ]
  const isLogoShow = logoShowPaths.includes(location.pathname)

  return (
    <div className={scss.header}>
      <div className={scss.header1}>
        {isLogoShow && (
          <Link to='/' className={scss.logoWrapper}>
            <img className={scss.logoIcon} alt="" src="/logo.svg" />
          </Link>
        )}
        <UploadingIndicator />
        {!isLogoShow && <CheckNotificationsIndicator />}
        <AutomationIndicator />
        <HeaderNotifications />
        <AccountMenu />
      </div>
      <div className={scss.divider}>
      </div>
    </div>
  );
};

export default Header;
