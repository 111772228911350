import { CSV } from "../../../types/entities/csv";
import { SnLink } from "../../../types/entities/snLink";

export const getUpdateUploadBody = (upload: CSV | SnLink ) => {
  const isSnLink = Object.prototype.hasOwnProperty.call(upload, 'link')

  return {
    snLink: isSnLink ? upload : null,
    csv: !isSnLink ? upload : null,
  }
}