import { motion } from "framer-motion";
import React, { ReactNode } from "react";

type Props = {
  children: ReactNode;
}

const animation = {
  initial: {
    opacity: 0,
    rotateY: 90
  },
  animate: {
    opacity: 1,
    rotateY: 0
  },
  exit: {
    rotateY: -90,
    opacity: 0
  }
};

export const AuthAnimation = ({ children }: Props) => {
  return (
    <>
      <motion.div
        variants={animation}
        initial="initial"
        animate="animate"
        exit='exit'
        transition={{ duration: 0.2 }}
        style={{ overflow: "hidden" }}
      >
        {children}
      </motion.div>
    </>
  )
}

