import {
  CSSProperties,
  FunctionComponent,
  ReactNode,
  useEffect,
  useState,
} from "react";
import { createPortal } from "react-dom";
import styles from "./PortalPopup.module.css";
import React from 'react';
import { Box } from "@mui/material";
import { useSidebarData } from "../../utils/redux/storeHooks/dataHooks/useSidebarData";

type PopupProps = {
  overlayColor?: string;
  isBlur?: boolean;
  onOutsideClick?: () => void;
  children: ReactNode;
  relativeLayerId?: string;
  disableOverlayClick?: boolean;
};

const PortalPopup: FunctionComponent<PopupProps> = ({
  children,
  overlayColor,
  isBlur = false,
  onOutsideClick,
  relativeLayerId,
  disableOverlayClick
}) => {
  const [overlayPosition, setOverlayPosition] = useState<CSSProperties>({})
  const [modalPosition, setModalPosition] = useState<CSSProperties>({})
  const { isSideBarWide } = useSidebarData()

  const changeOverlayPosition = () => {
    if (relativeLayerId) {
      const elem = document.getElementById(relativeLayerId)
      if (elem) {
        const rect = elem.getBoundingClientRect();
        setOverlayPosition({
          position: "fixed",
          left: `${rect.left}px`,
          top: `${rect.top}px`,
          width: `${rect.width}px`,
          height: `${rect.height}px`,
          transition: 'left 0.05s ease-out'
        });
      } else {
        setOverlayPosition({
          position: "fixed",
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
        });
      }

    } else {
      setOverlayPosition({
        position: "fixed",
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
      });
    }
  };

  const changeModalPosition = () => {
    if (relativeLayerId) {
      const elem = document.getElementById(relativeLayerId)
      if (elem) {
        const rect = elem.getBoundingClientRect();

        setModalPosition({
          position: "absolute",
          left: `${rect.width / 2}px`,
          // top: `${window.scrollY + window.innerHeight / 2}px`,
          top: `${(window.innerHeight - 64) / 2}px`,

          transform: "translate(-50%, -50%)",
          transition: 'left 0.05s ease-out'
        })
      } else {
        setModalPosition({
          position: "absolute",
          left: '50%',
          top: `50%`,
          transform: "translate(-50%, -50%)",
        })
      }

    } else {
      setModalPosition({
        position: "absolute",
        left: '50%',
        top: `50%`,
        transform: "translate(-50%, -50%)",
      })
    }
  }

  useEffect(() => {


    window.addEventListener("resize", changeOverlayPosition);
    window.addEventListener("scroll", changeOverlayPosition, true);
    window.addEventListener("resize", changeModalPosition);
    window.addEventListener("scroll", changeModalPosition, true);

    return () => {
      window.removeEventListener("resize", changeOverlayPosition);
      window.removeEventListener("scroll", changeOverlayPosition, true);
      window.removeEventListener("resize", changeModalPosition);
      window.removeEventListener("scroll", changeModalPosition, true);
    };
  }, []);

  useEffect(() => {
    if (overlayPosition.position) {
      setTimeout(() => {
        changeOverlayPosition()
        changeModalPosition()
      }, 305)
    } else {
      changeOverlayPosition()
      changeModalPosition()
    }
  }, [isSideBarWide])

  const onOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (
      onOutsideClick &&
      (e.target as HTMLElement).classList.contains(styles.portalPopupOverlay) && !disableOverlayClick
    ) {
      onOutsideClick();
    }
    e.stopPropagation();
  }


  return (
    <Portal>
      <Box
        className={styles.portalPopupOverlay}
        sx={{
          backgroundColor: overlayColor,
          backdropFilter: isBlur ? "blur(4px)" : "none",
          ...overlayPosition
        }}
        onClick={onOverlayClick}
      >
        <Box style={{ ...modalPosition }}>
          {children}
        </Box>
      </Box>
    </Portal >
  );
};

type PortalProps = {
  children: ReactNode;
  containerId?: string;
};

export const Portal: FunctionComponent<PortalProps> = ({
  children,
  containerId = "portals",
}) => {
  let portalsDiv = document.getElementById(containerId);
  if (!portalsDiv) {
    portalsDiv = document.createElement("div");
    portalsDiv.setAttribute("id", containerId);
    document.body.appendChild(portalsDiv);
  }

  return createPortal(children, portalsDiv);
};
export default PortalPopup;
