import React from 'react';
import styles from "../RunAutomation.module.scss";
import { Box, Button, Typography, Zoom } from '@mui/material';
import { MainActionButton } from '../../../actionButton/MainActionButton';
import { useAutomationActions } from '../../../../utils/redux/storeHooks/actionsHooks/useAutomationActions';
import { useLocation } from 'react-router-dom';
import { useNewFeedData } from '../../../../utils/redux/storeHooks/dataHooks/useNewFeedData';
import { initialTab } from '../../../../utils/pages/newFeedPage/initialTab';
import { useAutomationData } from '../../../../utils/redux/storeHooks/dataHooks/useAutomationData';
import { useTableLeadsData } from '../../../../utils/redux/storeHooks/dataHooks/useTableLeadsData';
import { useSidebarData } from '../../../../utils/redux/storeHooks/dataHooks/useSidebarData';
import { useLeadsNotificationData } from '../../../../utils/redux/storeHooks/dataHooks/useLeadsNotificationData';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useAccountDetailsData } from '../../../../utils/redux/storeHooks/dataHooks/useAccountDetailsData';
import { Payment_status } from '../../../../types/PPG/Payment_status';
import { TooltipApp } from '../../../appTooltipList/TooltipApp';
import { UploadLeadsStatus } from '../../../../types/pages/dashboardLeadsPeoplePage/uploadingLeads';

type Props = {
  closePopUp: () => void;
}

export const FooterPopUp = ({ closePopUp }: Props) => {
  const { runAutomation, setRunAutomationStep } = useAutomationActions();
  const { checkOfValidCookie, checkingCookiesProcess } = useSidebarData()
  const { isNotificationUpdating } = useLeadsNotificationData()
  const {
    isAutomationRunning,
    excludeLeadsList,
    isRunAutomationRequest,
    runAutomationPromptId,
    runAutomationStep,
  } = useAutomationData();
  const { tabs } = useNewFeedData()
  const { activeSubscription } = useAccountDetailsData();
  const pathname = useLocation().pathname
  const tabId = pathname.split('/').pop();
  const { uploadInfo } = useTableLeadsData();

  const currentTab = tabs.find(tab => tab.id === tabId) || initialTab;

  const handleRunAutomation = () => {
    runAutomation({
      isAutomationRunning: isAutomationRunning ? false : true,
      tab: currentTab,
      excludeLeads: excludeLeadsList.map(lead => lead.leadIdentitySN?.trim() || lead.leadIdentity || ''),
      promptId: runAutomationPromptId
    })
  }

  const onBack = () => {
    setRunAutomationStep('FIRST')
  }

  const onNext = () => {
    setRunAutomationStep('SECOND')
  }

  if (runAutomationStep === 'FIRST') {
    return (
      <Box className={styles.footer}>
        <Button
          variant='text'
          onClick={closePopUp}
          className={styles.footer__cancel}
        >
          Cancel
        </Button>
        <MainActionButton
          title='Next'
          icon={<ArrowForwardIosIcon sx={{ fontSize: 16 }} />}
          actionOnClick={onNext}
          iconPosition='right'
          width={100}
          isDisabled={!runAutomationPromptId}
        />
      </Box>
    )
  }

  return (
    <Box className={styles.footer}>
      <Button
        variant='text'
        onClick={onBack}
        className={styles.footer__cancel}
      >
        <ArrowBackIosIcon sx={{ fontSize: 16 }} />
        Back
      </Button>
      {!activeSubscription || activeSubscription.paymentStatus !== Payment_status.CONFIRMED
        ? (
          <TooltipApp TransitionComponent={Zoom} placement="bottom-start" title={
            <>
              <Typography sx={{ fontSize: 14, textAlign: 'center', padding: '4px 4px' }}>
                {"This feature available, after confirm payment."}
              </Typography>
            </>
          }>
            <Box>
              <MainActionButton
                title='Run automation'
                isDisabled
              />
            </Box>
          </TooltipApp >
        ) : (
          <MainActionButton
            title='Run automation'
            actionOnClick={handleRunAutomation}
            isDisabled={isAutomationRunning
              || isRunAutomationRequest
              || uploadInfo?.status === UploadLeadsStatus.UPLOADING
              || !checkOfValidCookie
              || checkingCookiesProcess
              || isNotificationUpdating
              || !runAutomationPromptId
            }
            isLoading={isRunAutomationRequest}
          />
        )}
    </Box >
  )
}

