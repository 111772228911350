import { Box, Button, CircularProgress, Zoom } from "@mui/material"
import * as React from 'react';
import scss from '../../../AutomationPage.module.scss'
import { PromptV2 } from "../../../../../types/entities/promptV2";
import { useAutomationActions } from "../../../../../utils/redux/storeHooks/actionsHooks/useAutomationActions";
import { useAutomationData } from "../../../../../utils/redux/storeHooks/dataHooks/useAutomationData";
import { MainActionButton } from "../../../../../components/actionButton/MainActionButton";
import { useNavigate } from "react-router-dom";
import { PromptV2Link } from "../../../../../types/pages/automationPage/promptV2Names";
import { TooltipApp } from "../../../../../components/appTooltipList/TooltipApp";
import DeleteIcon from '@mui/icons-material/Delete';
import { checkIsCountUserPromptsMoreThanFive } from "../../../../../utils/pages/automationPage/checkIsCountUserPromptsMoreThanFive";

type Props = {
  currentPrompt: PromptV2;
}

export const PromptActions = ({ currentPrompt }: Props) => {
  const { clearExistPrompt, clearNewPrompt, saveNewPrompt, updatePrompt, deletePrompt } = useAutomationActions()
  const { initialPrompts, isNewPromptSaving, isPromptUpdating, prompts, isPromptDeleting } = useAutomationData()
  const navigate = useNavigate();
  const defaultPath = '/settings/prompts'
  const promptsWithTheSameNameLength = prompts.filter(prompt => prompt.name === currentPrompt.name).length
  const isCountPromptsMoreThanFive = checkIsCountUserPromptsMoreThanFive(prompts);

  const onClear = () => {
    clearExistPrompt(currentPrompt)
  }

  const checkIfDefferensWithInitial = () => {
    const currentInitial = initialPrompts.find(prompt => prompt.id === currentPrompt.id)

    const isBodyDifferent = currentPrompt.prompt !== currentInitial.prompt
    const isTitleDefferent = currentPrompt.name !== currentInitial.name
    const isContextVariableDifferent = ![...currentPrompt.contextVariables].sort().every((value, index) => value === [...currentInitial.contextVariables].sort()[index])
    const isEmojiDifferent = currentPrompt.includeEmoji !== currentInitial.includeEmoji
    const isHashTagDifferent = currentPrompt.includeHashTags !== currentInitial.includeHashTags

    return isBodyDifferent || isTitleDefferent || isEmojiDifferent || isHashTagDifferent || isContextVariableDifferent || isNewPromptSaving
  }

  const checkIfTitleAndBodyChanged = () => {
    const currentInitial = initialPrompts.find(prompt => prompt.id === currentPrompt.id)
    return currentPrompt.name === currentInitial.name || currentPrompt.prompt === currentInitial.prompt
  }

  const onCancel = () => {
    clearNewPrompt()
    navigate(`${defaultPath}/${PromptV2Link.default}`)
  }

  const onSaveNewPrompt = () => {
    saveNewPrompt({
      name: currentPrompt.name,
      prompt: currentPrompt.prompt,
      includeHashTags: currentPrompt.includeHashTags,
      includeEmoji: currentPrompt.includeEmoji,
    })
  }

  const onUpdatePrompt = () => {
    updatePrompt({
      id: currentPrompt.id,
      name: currentPrompt.name,
      prompt: currentPrompt.prompt,
      includeEmoji: currentPrompt.includeEmoji,
      includeHashTags: currentPrompt.includeHashTags,
      contextVariables: currentPrompt.contextVariables
    })
  }

  const isActionsDisabled = !checkIfDefferensWithInitial()

  const onDeletePrompt = () => {
    deletePrompt({
      id: currentPrompt.id
    })
    navigate(`${defaultPath}/${PromptV2Link.default}`)
  }

  if (currentPrompt && (currentPrompt?.linkName === PromptV2Link.newPrompt)) {
    return (
      <Box className={scss.promptSetting__actions}>
        {(checkIfTitleAndBodyChanged() || isCountPromptsMoreThanFive || promptsWithTheSameNameLength > 1) ? (
          <TooltipApp TransitionComponent={Zoom} placement="bottom-start" title={
            <>
              <span className={scss.toolTipBody}>
                {isCountPromptsMoreThanFive ? 'You can create only 5 prompts' : 'First add unique title and prompt'}
              </span>
            </>
          }>
            <div>
              <MainActionButton
                title='Save New Prompt'
                width={220}
                isDisabled
              />
            </div>
          </TooltipApp>

        ) : (
          <MainActionButton
            title='Save New Prompt'
            width={220}
            isDisabled={isNewPromptSaving}
            isLoading={isNewPromptSaving}
            actionOnClick={onSaveNewPrompt}
          />
        )}
        <Button
          className={scss.promptSetting__cancelButton}
          onClick={onCancel}
        >
          Cancel
        </Button>
      </Box>
    )
  }

  return (
    <Box className={scss.promptSetting__actions}>
      <Box className={scss.promptSetting__mainActions}>
        <MainActionButton
          title='Update Prompt'
          width={220}
          isDisabled={isActionsDisabled || isPromptUpdating}
          actionOnClick={onUpdatePrompt}
          isLoading={isPromptUpdating}
        />

        <Button
          className={scss.promptSetting__cancelButton}
          onClick={onClear}
          disabled={isActionsDisabled}
        >
          Reset
        </Button>
      </Box>


      {currentPrompt.linkName !== PromptV2Link.default
        && currentPrompt.linkName !== PromptV2Link.newPrompt
        && currentPrompt.linkName !== PromptV2Link.invitePrompt
        && currentPrompt.linkName !== PromptV2Link.messagePrompt && <Button
          className={scss.promptSetting__deleteButton}
          onClick={onDeletePrompt}
          disabled={isPromptDeleting}
        >
          {isPromptDeleting ? (
            <CircularProgress size={24} sx={{ fontSize: 16, color: 'rgba(59, 36, 112, 1)' }} />
          ) : (
            <>
              <DeleteIcon />
              Delete
            </>
          )}
        </Button>}
    </Box >

  )
}

