import { useNavigate } from "react-router-dom";
import { CustomTourStep } from "../../types/general/customTourStep";
import { useTableLeadsActions } from "../redux/storeHooks/actionsHooks/useTableLeadsActions";
import { usePopupActions } from "../redux/storeHooks/actionsHooks/usePopupActions";
import { useNewFeedActions } from "../redux/storeHooks/actionsHooks/useNewFeedActions";
import { feedGeneralMock, feedTabMock, leadsMock } from "../general/tourMock";
import { useEndTour } from "./useEndTour";
import { useAccountDetailsActions } from "../redux/storeHooks/actionsHooks/useAccountDetailsActions";
import React from 'react';
import { AppTourContent } from "../../components/appTour/AppTourContent";


export const useGetTourSteps = () => {
  const navigate = useNavigate();
  const { setIsDropDownMenuOpen, setMockLeads, resetMockLeads } = useTableLeadsActions();
  const { setPopupImportCSVOpen } = usePopupActions();
  const { setMockFeedToGeneral, resetMockFeedGeneral, createMockCampaign, resetMockCampaign } = useNewFeedActions();
  const { setIsAccountMenuOpen } = useAccountDetailsActions();
  const { onEndTour } = useEndTour();

  const steps: CustomTourStep[] = [
    {
      selector: "#leadsTab",
      // content: <Box></Box>`Get started with HEET.AI by adding your leads' and clients' LinkedIn profiles to the app.`,
      content: <AppTourContent
        title="Get started with HEET.AI"
        description={`Go to the "Leads" tab, then add your leads' and clients' LinkedIn profiles to the app.`}
      />,
      disableActions: true,
      padding: { mask: [0, 0, 0, 0], popover: [20, 0, 0, 0] },
    },
    {
      selector: "#addLeadsButton",
      // content: 'Click "Add Leads" to proceed and upload your leads to start commenting on their posts. ',
      content: <AppTourContent
        title='Click "Add Leads"'
        description="Upload your leads to start commenting on their posts."
      />,
      disableActions: true,
      padding: { mask: [0, 0, 0, 0] },
      onNext: () => setIsDropDownMenuOpen(true),
      onPrev: () => setIsDropDownMenuOpen(false)
    },
    {
      selector: "#csvUploadFlow",
      // content: 'Choose the best option to add your audience: upload a CSV file or use a Sales Nav search link (recommended if you or your team members have an active Sales Nav account).',
      content: <AppTourContent
        title='Choose the best option to add your audience'
        description="Upload a CSV file or use a Sales Nav search link (recommended if you or your team members have an active Sales Nav account)."
      />,
      disableActions: false,
      position: 'bottom',
      padding: { mask: [0, 25, 34, 25], popover: [0, 50, 0, 0] },
      onNext: () => {
        setPopupImportCSVOpen(true)
        setIsDropDownMenuOpen(false)
      },
      onPrev: () => {
        setIsDropDownMenuOpen(false)
      }
    },
    {
      selector: "#mainPopUpUploadCsv",
      // content: 'Check if your file is ready for import to heet.ai and complete uploading your leads.',
      content: <AppTourContent
        title='Import your audience '
        description="Check if your file is ready for import to heet.ai and complete uploading your leads."
      />,
      disableActions: false,
      position: 'right',
      padding: { mask: [0, 0, 0, 0] },
      onNext: () => {
        setPopupImportCSVOpen(false)
        navigate('/settings/prompts/default')
      },
      onPrev: () => {
        setPopupImportCSVOpen(false)
        setIsDropDownMenuOpen(true)
      }
    },
    {
      selector: "#settingTab",
      // content: 'Head over to the Settings section to fine-tune prompts (commands) for comments, invites, and messages',
      content: <AppTourContent
        title='Go to Settings'
        description="Check the Settings section to manage prompts and automation."
      />,
      disableActions: false,
      position: 'right',
      padding: { mask: [0, 0, 0, 0], popover: [20, 20, 0, 0] },
      onNext: () => {
        setPopupImportCSVOpen(false)
        navigate('/settings/prompts/default')
      },
      onPrev: () => {
        setPopupImportCSVOpen(true)
        navigate('/leads')
      }
    },
    {
      selector: "#settingPrompt",
      // content: 'Fine-tune your prompts for comments, invites and messages',
      content: <AppTourContent
        title='Configure settings'
        description="Fine-tune prompts (commands) for comments, invites, and messages."
      />,
      disableActions: true,
      position: ((postionsProps, prevRect) => {
        const windowWidth = window.innerWidth;
        return [windowWidth - 470, 10]

      }),
      padding: { mask: [5, 5, 5, 5] },

      onNext: () => {
        setMockFeedToGeneral(JSON.parse(JSON.stringify(feedGeneralMock)))
        navigate('/feed')
      },
      onPrev: () => {
        resetMockFeedGeneral()
      }
    },
    {
      selector: "#feedTab",
      // content: 'You’re all set to start commenting! Jump into the "Feed" tab to see posts from your heet.ai leads.',
      content: <AppTourContent
        title='You’re all set to start commenting!'
        description='Jump into the "Feed" tab to see posts from your heet.ai leads.'
      />,
      disableActions: true,
      position: 'bottom',
      padding: { mask: [0, 0, 0, 0], popover: [20, 30, 0, 0] },
      onPrev: () => {
        navigate('/settings/prompts/default')
      }
    },
    {
      selector: "#saveCampaign",
      // content: 'To start an automated comments campaign, apply filters for date and keywords, then click "Save campaign".',
      content: <AppTourContent
        title='Creating an automated comments campaign'
        description='To start an automated comments campaign, apply filters for date and keywords, then click "Save campaign".'
      />,
      disableActions: true,
      position: 'bottom',
      padding: { mask: [0, 0, 0, 0] },
      onNext: () => {
        createMockCampaign((JSON.parse(JSON.stringify(feedTabMock))))
        navigate('/feed/1')
      },
    },
    {
      selector: "#newCampaign",
      // content: 'To view filtered posts and launch the automation, open your newly created campaign.',
      content: <AppTourContent
        title='Go to the campaign'
        description='Open your new campaign to view filtered posts and launch the automation.'
      />,
      disableActions: true,
      position: 'bottom',
      padding: { mask: [8, 8, 8, 8] },
      onPrev: () => {
        navigate('/feed/general')
        resetMockCampaign()
      }
    },
    {
      selector: "#runAutomation",
      // content: 'Start your automated commenting by clicking "Run automation". Your comments will be posted directly to LinkedIn.',
      content: <AppTourContent
        title='Start automation for comments'
        description='Start your automated commenting by clicking "Run automation". Your comments will be posted directly to LinkedIn.'
      />,
      disableActions: true,
      position: 'bottom',
      padding: { mask: [0, 0, 0, 0] },
      onNext: () => {
        setMockLeads((JSON.parse(JSON.stringify(leadsMock))))
        navigate('/leads')
      },
      onPrev: () => {
        resetMockLeads();
      }
    },
    {
      selector: "#updateNotification",
      // content: 'Check your notifications regularly to track all reactions (likes and replies to your comments) from leads.',
      content: <AppTourContent
        title='Check your notifications'
        description='Regularly update notifications to track all reactions (likes and replies to your comments) from leads.'
      />,
      disableActions: true,
      position: 'bottom',
      padding: { mask: [0, 0, 0, 0] },
      onPrev: () => {
        navigate('/feed/1')
      }
    },
    {
      selector: "#inviteLeads",
      // content: 'Send AI-powered invites to 2nd-degree connections and messages to 1st-degree LinkedIn contacts.',
      content: <AppTourContent
        title='Send AI-powered invites and message'
        description='Send invites to 2nd-degree connections and messages to 1st-degree LinkedIn contacts.'
      />,
      disableActions: true,
      position: 'bottom',
      padding: { mask: [0, 0, 65, 0], popover: [75, 0, 0, 0] },
      onNext: () => {
        setIsAccountMenuOpen(true)
      }
    },
    {
      selector: "#tourAgain",
      // content: 'Thanks for watching! Jump into commenting, and if you missed anything, just replay the product tour.',
      content: <AppTourContent
        title='Thanks for watching!'
        description='Jump into commenting, and if you missed anything, just replay the product tour.'
      />,
      disableActions: true,
      position: 'bottom',
      padding: { mask: [-20, 0, 40, 50], popover: [0, 0, 0, 55] },
      onPrev: () => {
        setIsAccountMenuOpen(false)
      },
      onNext: () => {
        setIsAccountMenuOpen(false)
        onEndTour()
      }
    },
  ]
  return steps;
}