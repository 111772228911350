export enum AuthFlow {
  SIGNIN = 'SIGNIN',
  SENDRESTOREEMAIL = 'SENDRESTOREEMAIL',
  SETNEWPASSWORD = 'SETNEWPASSWORD'
}

export interface AuthContextType {
  handleEmailChange: (email: string, authFlow: AuthFlow) => void;
  checkIsEmailValid: (password: string, authFlow: AuthFlow) => boolean;
  handlePasswordChange: (password: string, authFlow: AuthFlow) => void;
  checkIsPasswordValid: (password: string, authFlow: AuthFlow) => boolean;
}