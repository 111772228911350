import React from 'react'
import { Outlet, Navigate } from 'react-router-dom'
import { useAccountDetailsData } from '../utils/redux/storeHooks/dataHooks/useAccountDetailsData'

const PrivateRoutes = () => {
    const { isUserAuth, isUserAuthRequest } = useAccountDetailsData();

    return (
        isUserAuth || isUserAuthRequest ? <Outlet /> : <Navigate to="/sign-in" />
    )
}

export default PrivateRoutes