type checkAbilityProps = {
  checkOfValidCookie: boolean;
  isTrackingLimitReached: boolean;
  isLeadsUploading: boolean;
  isGetUploadingLeadsLimitWeeklyRequest: boolean;
  isWeeklyLimitReached: boolean;
  isAutomationRunning: boolean;
  isLeadsAlreadyUpload: boolean;
}
export const getUploadAbilityTooltipText = (options: checkAbilityProps) => {
  const {
    checkOfValidCookie,
    isTrackingLimitReached,
    isLeadsUploading,
    isGetUploadingLeadsLimitWeeklyRequest,
    isWeeklyLimitReached,
    isAutomationRunning,
    isLeadsAlreadyUpload
  } = options;

   if (!checkOfValidCookie) {
      return "Your cookies have expired. Please update them using the heet.ai extension."
    }
  
    if (isTrackingLimitReached) {
      return "You've hit the limit for tracking leads. Upgrade your plan or remove some existing leads to continue."      
    }
  
    if (isLeadsUploading) {
      return "Please wait until the current leads finish processing before uploading more"
    }
  
    if (isGetUploadingLeadsLimitWeeklyRequest) {
      return "Please wait while we check your upload limits."
    }
  
    if (isWeeklyLimitReached) {
      return "You've reached your weekly upload limit. Upgrade your plan or try again next week."
    }

    if (isAutomationRunning) {
      return "Automation is currently running. Wait for the process to finish or stop it to start uploading leads."
    }

    if(isLeadsAlreadyUpload) {
      return "🚧 You have an upload that is not marked as DONE. Finish it and try again! 🔄"
    }
  
    return null;

}