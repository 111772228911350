import React from 'react';
import { Box, Typography } from '@mui/material';
import PersonOffIcon from '@mui/icons-material/PersonOff';


export const NoUserList = () => {
  return (
    <Box sx={{
      height: 220,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '48px',
      marginBottom: '48px'
    }}
    >
      <Typography sx={{
        fontFamily: 'Kanit',
        fontSize: '18px',
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        width: '245px'
      }}>
        <PersonOffIcon sx={{ marginRight: '12px' }} />
        {`There's no active Sales Nav account. Please, add one or contact support team.`}
      </Typography>
    </Box>
  )
}

