import { useNavigate } from "react-router-dom";
import { useTableLeadsActions } from "../redux/storeHooks/actionsHooks/useTableLeadsActions";
import { useTour } from "@reactour/tour";
import { useAccountDetailsActions } from "../redux/storeHooks/actionsHooks/useAccountDetailsActions";
import { useAccountDetailsData } from "../redux/storeHooks/dataHooks/useAccountDetailsData";
import { usePopupActions } from "../redux/storeHooks/actionsHooks/usePopupActions";
import { useNewFeedActions } from "../redux/storeHooks/actionsHooks/useNewFeedActions";

export const useEndTour = () => {
  const navigate = useNavigate();
  const { resetMockLeads } = useTableLeadsActions();
  const { setPopupImportCSVOpen } = usePopupActions();
  const { resetMockFeedGeneral, resetMockCampaign } = useNewFeedActions();
  const {skipTour} = useAccountDetailsActions();
  const {setIsOpen} = useTour();

  const resetMock = () => {
    resetMockFeedGeneral()
    resetMockCampaign()
    resetMockLeads()
  }

  const onEndTour = () => {
    resetMock()
    skipTour();   
    navigate('/overview');
    setIsOpen(false)
    setPopupImportCSVOpen(false)
  }

  return {onEndTour, resetMock}

}