import { useTour } from "@reactour/tour";
import { useGetTourSteps } from "./useGetTourSteps";
import { useEffect } from "react";
import { useFreezePopUp } from "./useStackPopUp";

export const useTourNavigation = () => {
  const { currentStep, setCurrentStep, setIsOpen, isOpen,  } = useTour();
  const steps = useGetTourSteps();
  const isFirstStep = currentStep === 0;
  const isLastStep = currentStep === steps?.length - 1;
  useFreezePopUp()

  const handleNextSteps = () => {
    const step = steps[currentStep];
    if (step?.onNext) {
      step.onNext();
    }
    setCurrentStep(currentStep + 1);
  };

  const handlePrevSteps = () => {
    const step = steps[currentStep];
    if (step?.onPrev) {
      step.onPrev();
    }
    setCurrentStep(currentStep - 1);
  };

  const blockBackNavigation = () => {
    window.history.pushState(null, "", window.location.href);
  };

  useEffect(() => {
    blockBackNavigation();
    window.addEventListener("popstate", blockBackNavigation);

    return () => {
      window.removeEventListener("popstate", blockBackNavigation);
    };
  }, []);

  return { handleNextSteps, handlePrevSteps, isFirstStep, isLastStep, currentStep, setIsOpen, setCurrentStep, isOpen };
};