import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BotManagementInitialState } from './types';
import { addUserLikeBot, checkFeedDaily, checkFeedInitial, checkValidCookiesForBot, deleteUserFromBots, getBots, getBotStatistic, getNeedAdminCheckSnlinks, getUsers, loadLeadsSnGet, stopWork } from './thunks';
import { Bot } from '../../../../types/pages/botManagmentPage/bot';
import { BotsCronJobName } from '../../../../types/pages/botManagmentPage/botsCronJobNames';
import { BotsStatistic } from '../../../../types/pages/botManagmentPage/botStatistic';
import { SnLink } from '../../../../types/entities/snLink';

const initialState: BotManagementInitialState = {
  bots: [],
  isBotsLoading: false,
  isGetBotsError: false,
  dailyFeedWorkerList: [],
  initialFeedWorkerList: [],
  leadsWorkerList: [],
  checkingCookieForBot: null,
  isLoadingRunCheckFeedDaily: false,
  isLoadingRunCheckFeedInitial: false,
  isLoadingRunLoadLeadsSnGet: false,
  users: [],
  selectedUserForDailyFeed: null,
  isUsersLoading: false,
  selectedUserForInitialFeedParse: null,
  userUuidForAddBot: null,
  userUuidDeleteBot: null,
  botUuidStopWork: null,
  selectedUserForLoadLeads: null,
  isStatisticLoading: false,
  botStatistic: [],
  botStatisticForChart: [],
  isSnLinksLoading: false,
  snLinks: [],
  selectedSnLinkToInitialParse: null,
};

export const botManagementSlice = createSlice({
  name: 'botManagementSlice',
  initialState,
  reducers: {
    resetState: () => initialState,  
    addToDailyFeedWorkList: (state, { payload }: PayloadAction<Bot>) => {
      const isExist = state.dailyFeedWorkerList.find(bot => bot.uidd === payload.uidd)
      if(!isExist) {
        state.dailyFeedWorkerList.push(payload)
      }      
    },
    removeFromDailyFeedWorkList: (state, { payload }: PayloadAction<Bot>) => {
      state.dailyFeedWorkerList =  state.dailyFeedWorkerList.filter(bot => bot.uidd !== payload.uidd)
    },
    addToInitialFeedWorkList: (state, { payload }: PayloadAction<Bot>) => {
      const isExist = state.initialFeedWorkerList.find(bot => bot.uidd === payload.uidd)
      if(!isExist) {
        state.initialFeedWorkerList.push(payload)
      }      
    },
    removeFromInitialFeedWorkList: (state, { payload }: PayloadAction<Bot>) => {
      state.initialFeedWorkerList =  state.initialFeedWorkerList.filter(bot => bot.uidd !== payload.uidd)
    },
    addToLeadsWorkList: (state, { payload }: PayloadAction<Bot>) => {
      const botFromWorkerList = state.leadsWorkerList.find(bot => bot.uidd === payload.uidd)
      const bot = state.bots.find(bot => bot.uidd === payload.uidd)

      if(!botFromWorkerList && bot.premiumSubscriber) {
        state.leadsWorkerList = [payload]
      }      
    },
    removeFromLeadsWorkList: (state, { payload }: PayloadAction<Bot>) => {
      state.leadsWorkerList =  state.leadsWorkerList.filter(bot => bot.uidd !== payload.uidd)
    },
    setSelectedUserForDailyFeedParse: (state, { payload }: PayloadAction<Bot>) => {
      state.selectedUserForDailyFeed =  payload
    },
    setSelectedUserForInitialFeedParse: (state, { payload }: PayloadAction<Bot>) => {
      state.selectedUserForInitialFeedParse =  payload
    },
    setSelectedUserForLoadLeads: (state, { payload }: PayloadAction<Bot>) => {
      state.selectedUserForLoadLeads =  payload
    },
    setSelectedSnLinkToInitialParse: (state, { payload }: PayloadAction<SnLink | null>) => {
      state.selectedSnLinkToInitialParse = payload;
    },
  },
  extraReducers: (builder) => {
    //getBotsAndCheckCookies
    builder.addCase(getBots.pending, (state, action) => {
      state.isBotsLoading = true;   
    })
    builder.addCase(getBots.fulfilled, (state, action) => {
      const bots = action.payload
      state.bots = bots;
      state.isBotsLoading = false;
    })
    builder.addCase(getBots.rejected, (state, action) => {
      state.isGetBotsError = true;
      state.isBotsLoading = false;
    })
    //checkValidCookiesFroBot
    builder.addCase(checkValidCookiesForBot.pending, (state, action) => {
      state.checkingCookieForBot = action.meta.arg.userUuid;   
    })
    builder.addCase(checkValidCookiesForBot.fulfilled, (state, action) => {
      const botIndex = state.bots.findIndex(bot => bot.uidd === action.meta.arg.userUuid);
      if(botIndex !== -1) {
        state.bots[botIndex] = action.payload
      }
      state.checkingCookieForBot = null;
    })
    builder.addCase(checkValidCookiesForBot.rejected, (state, action) => {
      state.checkingCookieForBot = null;
    })
    //checkFeedDaily
    builder.addCase(checkFeedDaily.pending, (state, action) => {
      state.isLoadingRunCheckFeedDaily = true;   
    })
    builder.addCase(checkFeedDaily.fulfilled, (state, action) => {
      console.log(action.payload)
      const {workerUuids} = action.payload
      state.isLoadingRunCheckFeedDaily = false;
      state.dailyFeedWorkerList = []
      workerUuids.forEach(uuid => {
        const currentWorker = state.bots.find(bot => bot.uidd === uuid)
        if(currentWorker) {
          currentWorker.isBusyLikeBot = true;
          currentWorker.botCronJobName = BotsCronJobName.dailyParcingFeed          
        }
      }) 
    })
    builder.addCase(checkFeedDaily.rejected, (state, action) => {
      state.isLoadingRunCheckFeedDaily = false;
    })
    //checkFeedInitial
    builder.addCase(checkFeedInitial.pending, (state, action) => {
      state.isLoadingRunCheckFeedInitial = true;   
    })
    builder.addCase(checkFeedInitial.fulfilled, (state, action) => {
      console.log(action.payload)
      const {workerUuid} = action.payload
      state.isLoadingRunCheckFeedInitial = false;
      state.initialFeedWorkerList = []
      const currentWorker = state.bots.find(bot => bot.uidd === workerUuid)
      if(currentWorker) {
        currentWorker.isBusyLikeBot = true;
        currentWorker.botCronJobName = BotsCronJobName.initialParcingFeed  
      }
    })
    builder.addCase(checkFeedInitial.rejected, (state, action) => {
      state.isLoadingRunCheckFeedInitial = false;
    })
    //GetUsers
    builder.addCase(getUsers.pending, (state, action) => {
      state.isUsersLoading = true;   
    })
    builder.addCase(getUsers.fulfilled, (state, action) => {
      state.isUsersLoading = false;
      state.users = action.payload;
    })
    builder.addCase(getUsers.rejected, (state, action) => {
      state.isUsersLoading = false;
    })
    //AddUserLikeBot
    builder.addCase(addUserLikeBot.pending, (state, action) => {
      state.userUuidForAddBot = action.meta.arg.user.uidd;   
    })
    builder.addCase(addUserLikeBot.fulfilled, (state, action) => {
      state.userUuidForAddBot = null;
      state.users = state.users.filter(user => user.uidd !== action.payload.userUuid);
      state.bots.push(action.meta.arg.user)
    })
    builder.addCase(addUserLikeBot.rejected, (state) => {
      state.userUuidForAddBot = null;
    })
    //DeleteUserFromBots
    builder.addCase(deleteUserFromBots.pending, (state, action) => {
      state.userUuidDeleteBot = action.meta.arg.userUuid  
    })
    builder.addCase(deleteUserFromBots.fulfilled, (state, action) => {
      state.userUuidDeleteBot = null;
      state.bots = state.bots.filter(bot => bot.uidd !== action.payload.userUuid);
    })
    builder.addCase(deleteUserFromBots.rejected, (state) => {
      state.userUuidDeleteBot = null;
    })
    //StopWork
    builder.addCase(stopWork.pending, (state, action) => {
      state.botUuidStopWork = action.meta.arg.botUuid  
    })
    builder.addCase(stopWork.fulfilled, (state, action) => {
      state.botUuidStopWork = null;
      if(action.payload.cronJobDeleted) {
        const currentWorker = state.bots.find(bot => bot.uidd === action.payload.botUuid)
        if(currentWorker) {
          currentWorker.isBusyLikeBot = false;
          currentWorker.botCronJobName = '';        
        }
      }     
    })
    builder.addCase(stopWork.rejected, (state) => {
      state.botUuidStopWork = null;
    })
    //LoadLeadsSnGet
    builder.addCase(loadLeadsSnGet.pending, (state, action) => {
      state.isLoadingRunLoadLeadsSnGet = true;  
    })
    builder.addCase(loadLeadsSnGet.fulfilled, (state, action) => {
      state.isLoadingRunLoadLeadsSnGet = false;
      state.leadsWorkerList = []
      const currentWorker = state.bots.find(bot => bot.uidd === action.payload.botUuid)
      if(currentWorker) {
        currentWorker.isBusyLikeBot = true;
        currentWorker.botCronJobName = BotsCronJobName.dailyParcingFeed          
      } 
    })
    builder.addCase(loadLeadsSnGet.rejected, (state) => {
      state.isLoadingRunLoadLeadsSnGet = false;
    })
    //Get bot statistic
    builder.addCase(getBotStatistic.pending, (state, action) => {
      state.isStatisticLoading = true;  
    })
    builder.addCase(getBotStatistic.fulfilled, (state, action) => {
      const botStatisticForChart = action.payload.reduce((acc: {[key: string]: BotsStatistic}, bot) => {
        const existBot = !!acc[bot.firstName];
        if (!existBot) {
          const stringBot = JSON.stringify(bot);
          const newBot = JSON.parse(stringBot);
          acc[bot.firstName] = newBot;
        } else {
          acc[bot.firstName].countOfLeadsProcessedByParsingFeedForMonth += bot.countOfLeadsProcessedByParsingFeedForMonth;
          acc[bot.firstName].countOfLeadsProcessedByParsingFeedForToday += bot.countOfLeadsProcessedByParsingFeedForToday;
          acc[bot.firstName].countOfLeadsProcessedByParsingLeadsForMonth += bot.countOfLeadsProcessedByParsingLeadsForMonth;
          acc[bot.firstName].countOfLeadsProcessedByParsingLeadsForToday += bot.countOfLeadsProcessedByParsingLeadsForToday;
        }
      
        return acc;      
      }, {});

      state.botStatisticForChart = Object.values(botStatisticForChart);
      state.botStatistic = action.payload;
      state.isStatisticLoading = false;
    })
    builder.addCase(getBotStatistic.rejected, (state) => {
      state.isStatisticLoading = false;
    })
    //LoadSnLinksNeedToCheck
    builder.addCase(getNeedAdminCheckSnlinks.pending, (state, action) => {
      state.isSnLinksLoading = true;  
    })
    builder.addCase(getNeedAdminCheckSnlinks.fulfilled, (state, action) => {
      state.isSnLinksLoading = false;
      state.snLinks = action.payload;
    })
    builder.addCase(getNeedAdminCheckSnlinks.rejected, (state) => {
      state.isSnLinksLoading = false;
    })                          
  }    
});

export const botManagementActions = botManagementSlice.actions;
export const botManagementReducer = botManagementSlice.reducer;
