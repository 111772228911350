import axios from "axios";

export const uploadCSVFile = async (path: string, file: File, needUserUuid?: boolean) => {
    const userUuid = getUserUuid()
    const formData = new FormData();
    formData.append('file', file);
    formData.append('userUuid', userUuid);
    const userId = needUserUuid ? `/${userUuid}` : '';
    const headers = getHeaders('upload')
    try {
        const res = await axios.post(process.env.REACT_APP_API_URL + path + `${userId}`, formData, {
            headers: headers,
            withCredentials: true,
        })
        return res;
    } catch(e) {
        return e.response
    }
}

const getUserUuid = () => {
    return localStorage.getItem('userUuid')
}

const getHeaders = (headersName: 'default' | 'upload' | 'cron' | 'export') => {
    switch(headersName) {
        case 'upload':
            return {
                'Cache-Control': 'no-cache',
                'Access-Control-Allow-Credentials': 'true',
                'Access-Control-Allow-Origin': 'https://app.heet.ai, https://dev.heet.ai',
                'Content-Type': 'multipart/form-data',
            }
        case 'export':
            return {
                'Cache-Control': 'no-cache',
                'Access-Control-Allow-Credentials': 'true',
                'Access-Control-Allow-Origin': 'https://app.heet.ai, https://dev.heet.ai',
                'Content-Type': 'text/csv',
            }
        case 'cron':
            return {
                'Cache-Control': 'no-cache',
                'Access-Control-Allow-Credentials': 'true',
                'Content-Type': 'application/json',
            }
        default: 
            return {
                'Cache-Control': 'no-cache',
                'Access-Control-Allow-Credentials': 'true',
                'Access-Control-Allow-Origin': 'https://app.heet.ai, https://dev.heet.ai',
                'Content-Type': 'application/json',
            }
    }
}


  export const getRequest = async (path: string, needUserUuid?: boolean) => {
    const userUuid = getUserUuid()

    const userId = needUserUuid ? `/${userUuid}` : '';
    const headers = getHeaders('default')
    try {
        const res = await axios.get(process.env.REACT_APP_API_URL + path + `${userId}`, {
            headers: headers,
            withCredentials: true,
        })
        return res
    } catch (e) {

        if(e.code === 'ERR_NETWORK') {
            return e
        }
        
        return e.response
    }
}

export const postRequest = async (path: string, body?: object, needUserUuid?: boolean) => {
    const userUuid = getUserUuid()
    const userId = needUserUuid ? `/${userUuid}` : '';
    const headers = getHeaders('default')
    try {
        const res = await axios.post(process.env.REACT_APP_API_URL + path + `${userId}`, body, {
            headers: headers,
            withCredentials: true,
        })
        return res
    } catch (e) {

        if(e.code === 'ERR_NETWORK') {
            return e
        }
        
        return e.response
    }
}

export const postRequestExport = async (path: string, body?: object, needUserUuid?: boolean) => {
    const userUuid = getUserUuid()
    const userId = needUserUuid ? `/${userUuid}` : '';
    const headers = getHeaders('default')
    try {
        const res = await axios.post(process.env.REACT_APP_API_URL + path + `${userId}`, body, {
            headers: headers,
            responseType: 'blob',
            withCredentials: true,
        })
        return res
    } catch (e) {

        if(e.code === 'ERR_NETWORK') {
            return e
        }
        
        return e.response
    }
}

export const deleteRequest = async (path: string, needUserUuid?: boolean) => {
    const userUuid = getUserUuid()
    const userId = needUserUuid ? `/${userUuid}` : '';
    const headers = getHeaders('default')
    try {
        const res = await axios.delete(process.env.REACT_APP_API_URL + path + `${userId}`, {
            headers: headers,
            withCredentials: true,
        })
        return res
    } catch (e) {

        if(e.code === 'ERR_NETWORK') {
            return e
        }
        
        return e.response
    }
}
