import React, { useEffect } from 'react';
import styles from "./UpgradePlan.module.scss";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Button, IconButton, Box, CircularProgress, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { SubscriptionPlan } from '../../../types/PPG/SubscriptionPlan';
import { usePopupActions } from '../../../utils/redux/storeHooks/actionsHooks/usePopupActions';
import { useAccountDetailsData } from '../../../utils/redux/storeHooks/dataHooks/useAccountDetailsData';
import { useFreezePopUp } from '../../../utils/customHooks/useStackPopUp';
import { useAccountDetailsActions } from '../../../utils/redux/storeHooks/actionsHooks/useAccountDetailsActions';
import { getUserInformationFromlocalStorage } from '../../../utils/general/get_set_user_information';
import { PaymentFlow } from '../../../types/PPG/paymentFlow.enum';
import {urlBookCall} from "../../../utils/subscription/common";

export const PopUpUpgradePlan = () => {
    const { setPopupUpgradePlanOpen } = usePopupActions();
    const { activeSubscription, plans, isGetPlansRequest } = useAccountDetailsData();
    const { getPlans } = useAccountDetailsActions();
    const { paymentFlow } = getUserInformationFromlocalStorage();

    useFreezePopUp();

    useEffect(() => {
        getPlans();
    }, [getPlans]);

    const closePopUp = () => setPopupUpgradePlanOpen(false);

    const onUpgradePlan = () => {
         if ([PaymentFlow.PPG, PaymentFlow.INVOICE].includes(paymentFlow)) {
            window.open(urlBookCall, '_blank');
        }
    };

    return (
        <Box className={styles.popup}>
            <Box className={styles.header}>
                <Typography className={styles.header__title}>Simple pricing for your business</Typography>
                <Typography className={styles.header__subTitle}>Plans that are carefully crafted to suit your business</Typography>
                <IconButton onClick={closePopUp} className={styles.header__closeButton}>
                    <CloseRoundedIcon sx={{ fill: 'rgba(111, 125, 155, 1)' }} />
                </IconButton>
            </Box>

            {isGetPlansRequest ? (
                <Box className={styles.loaderContainer}>
                    <CircularProgress className={styles.loaderContainer__loader} />
                </Box>
            ) : (
                <Box className={styles.content}>
                    {plans.map(({ name, description, plan, price, leadsCount, isBestDeal, features }) => (
                        <Box key={name} className={`${styles.content__card} ${isBestDeal ? styles.content__card__bestDeal : ''} ${activeSubscription?.plan !== plan ? styles.content__card__current : ''}`} boxShadow={2}>
                            <Box className={styles.content__cardHeader}>
                                <Box className={styles.content__cardHeader_cardNameContainer}>
                                    <Typography className={styles.content__cardHeader_cardName}>{name}</Typography>

                                     <Box className={styles.content__cardHeader_cardDescriptionWrapper}>
                                        <Typography className={styles.content__cardHeader_cardDescription}>{description}</Typography>
                                         {plan === SubscriptionPlan.LIGHT && (
                                             <Box className={styles.content__cardPriceContainer}>
                                                 <Typography className={styles.content__cardPriceContainer_cardPriceMain}>
                                                     {`$${price}`} <span className={styles.content__cardHeader_cardLeads_sub}>{leadsCount}</span>
                                                 </Typography>
                                             </Box>
                                         )}
                                    </Box>
                                </Box>
                            </Box>


                            <Box className={styles.content__actionsContainer}>
                                <Button
                                    variant="contained"
                                    className={`${styles.content__actionsContainer_button} ${plan === SubscriptionPlan.LIGHT ? styles.content__actionsContainer_button_current : styles.content__actionsContainer_button_regular}`}
                                    onClick={() => onUpgradePlan()}
                                    disabled={plan === SubscriptionPlan.LIGHT}
                                >
                                    {plan === SubscriptionPlan.LIGHT ? 'Current plan' : 'Talk to Sales'}
                                </Button>
                            </Box>

                            <Box className={styles.content__featuresContainer}>
                                <Box className={styles.content__specialfeaturesContainer}>
                                    {features.map(feature => (
                                        <Box key={feature} className={styles.content__featuresContainer_feature}>
                                            <CheckCircleIcon sx={{color: 'rgba(107, 77, 175, 1)', fontSize: '24px'}}/>
                                            <span dangerouslySetInnerHTML={{__html: feature}}/>
                                        </Box>
                                    ))}
                                </Box>
                            </Box>
                        </Box>
                    ))}
                </Box>
            )}
        </Box>
    );
};