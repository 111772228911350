import React, { ReactElement } from 'react';
import { TourProvider } from '@reactour/tour';
import { useGetTourSteps } from '../../utils/customHooks/useGetTourSteps';
import { CloseButton, NextButton, PrevButton } from './AppTourButtons';
import { Box, Typography } from '@mui/material';
import styles from './AppTour.module.scss'

type Props = {
  children: ReactElement;
}

type NavigationProps = {
  currentStep: number;
  setCurrentStep: (step: number) => void;
}

type ContentProps = {
  content: any;
  currentStep: number;
}

export const AppTour = ({ children }: Props) => {
  const steps = useGetTourSteps();
  return (
    <TourProvider
      steps={steps}
      disableInteraction
      showDots={false}
      showBadge={false}
      showCloseButton={false}
      styles={{
        popover: (base) => ({
          ...base,
          borderRadius: '8px',
          maxWidth: 430
        }),
      }}
      components={{
        Navigation: ({ setCurrentStep, currentStep }: NavigationProps) => (
          <Box>
            <Box className={styles.navigation}>
              {steps.map((_, index) => (
                <Box
                  className={`
                    ${styles.navigation__element} 
                    ${currentStep === index ? styles.navigation__element_current : ''} 
                    ${index < currentStep ? styles.navigation__element_passed : ''}
                    `}
                  key={index}
                  onClick={() => setCurrentStep(index)}
                />
              ))}
            </Box>
            <Box className={styles.navigation__badge}>
              {`${currentStep + 1}/${steps.length}`}
            </Box>
            <Box className={styles.navigation__buttons}
              sx={{
                justifyContent: currentStep === 0 ? 'center' : 'space-between'
              }}
            >
              <PrevButton />
              <NextButton />
            </Box>
          </Box>

        ),
        Content: ({ content }: ContentProps) => {
          return content
        },
      }}
    >
      {children}
    </TourProvider>
  )
}

