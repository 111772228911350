import React from 'react';
import { Avatar, Box, Divider, Typography } from '@mui/material';
import { useTableLeadsData } from '../../../../utils/redux/storeHooks/dataHooks/useTableLeadsData';
import CircleIcon from '@mui/icons-material/Circle';
import UserInformation from '../../../../types/entities/user_information';

type Props = {
  onSelectUserHandler: (user: UserInformation) => void;
  selectUserForParse: UserInformation | null;
}

export const TeamList = ({ onSelectUserHandler, selectUserForParse }: Props) => {
  const { teamList } = useTableLeadsData();
  const getTextAndColor = (user: UserInformation) => {
    if (user.isBusyLikeBot) {
      return {
        color: '#DB491F',
        text: 'User is busy now. Wait until end own work.'
      }
    }

    if (!user.isCookieValidForNow) {
      return {
        color: '#DB491F',
        text: 'Inactive SN (User is inactive. Refresh the SN page.)'
      }
    }

    return {
      color: '#25660E',
      text: 'Active SN'
    }
  }

  return (
    <>
      <Typography sx={{
        fontFamily: 'Kanit',
        fontSize: 16,
        marginBottom: '8px',
        textAlign: 'center',
        marginTop: '8px'
      }}>
        Select user for parsing
      </Typography>
      <Box sx={{
        height: 275,
        marginTop: '4px',
        overflowY: 'scroll',
        display: 'flex',
        flexDirection: 'column',
        gap: '12px'
      }}
      >
        {teamList.map(user => {
          const { color, text } = getTextAndColor(user)
          return (
            <Box
              key={user.uidd}
              onClick={() => onSelectUserHandler(user)}
              sx={{
                cursor: 'pointer',
                backgroundColor: (selectUserForParse?.uidd === user?.uidd) ? 'rgba(59,36,112,0.1)' : '#fff',
              }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  gap: '12px',
                  padding: '4px'
                }}
              >
                <Avatar
                  alt=""
                  src={user.profileImg}
                  sx={{ width: '40px', height: '40px' }}
                />
                <Box>
                  <Typography sx={{ fontFamily: 'Inter', color: '#3B2470', fontSize: 14, fontWeight: 500 }}>
                    {`${user.firstName} ${user.lastName}`}
                  </Typography>

                  <Typography sx={{
                    fontFamily: 'Inter',
                    color: '#25660E',
                    fontSize: 12,
                    display: 'flex',
                    alignItems: 'center'
                  }}>
                    <CircleIcon sx={{ color: color, fontSize: 8, marginRight: '4px' }} />
                    {text}
                  </Typography>
                </Box>
              </Box>
              <Divider sx={{ width: '100%', marginTop: '4px' }} />
            </Box>
          )
        })}
      </Box>
    </>
  )
}

