import { Middleware } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { accountDetailsActions } from "../slices/AccounDetailsSlice/accountDetailsSlice";
import { AccessAppStatuses } from "../../../types/PPG/AccessAppStatuses.enum";
import { cleanUpAllStorage } from "../../general/get_set_user_information";
import { resetStore } from "../storeHooks/resetStore";

export const errorMiddleware: Middleware<object, RootState> = ({dispatch}) => next => action => {
  if (action.type.endsWith('/rejected')) {
    
    if (action.payload === AccessAppStatuses.PAYMENT_REQUIRED) {
      dispatch(accountDetailsActions.setAccessAppStatus(AccessAppStatuses.PAYMENT_REQUIRED))
    }

    if(action.payload === 'Unauthorized') {
      dispatch(accountDetailsActions.setIsUserAuth(false));
      resetStore(dispatch);
      cleanUpAllStorage();
    }
  }
  return next(action);
};