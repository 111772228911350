import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import style from './NotAllowedPage.module.scss'

export const NotAllowedPage = () => {

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight: '60vh',
      }}
    >
      <Typography variant="h2" style={{ color: 'black' }}>
        Access denied
      </Typography>
      <Typography variant="h6" style={{ color: 'black' }} mt={4}>
        The page you are trying to access is not available.
      </Typography>
      <Typography variant="h6" style={{ color: 'black' }}>
        Please return to the home page
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 4, mt: 6 }}>
        <Button
          variant='outlined'
          sx={{
            width: 150,
            backgroundColor: '#6d30b7',
            borderColor: '#6d30b7',
            '&:hover': {
              backgroundColor: '#6f7d9b',
              borderColor: '#6f7d9b'
            }
          }}
        >
          <Link to='/' className={style.link}>Back Home</Link>
        </Button>
      </Box>
    </Box>
  )
}

