import React from "react";
import { Box, CircularProgress } from "@mui/material";

export const GlobalLoadingState = () => {
  return (
    <Box sx={{
      height: "100vh",
      width: "100%",
      background: "linear-gradient(270deg, #191F2E, #252F44, #191F2E)",
      backgroundSize: "400% 400%",
      animation: "shimmer 6s ease-in-out infinite",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",
      "@keyframes shimmer": {
        "0%": { backgroundPosition: "0% 50%" },
        "50%": { backgroundPosition: "100% 50%" },
        "100%": { backgroundPosition: "0% 50%" },
      },
    }}>
      <CircularProgress
        sx={{
          color: "#5C4A75",
          size: 200,
        }} />
    </Box>
  );
}
