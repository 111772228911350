import React, { useEffect } from "react";
import { Box, CircularProgress, Slide, Typography } from "@mui/material";
import scss from './Auth.module.scss';
import { AuthAnimation } from "../../components/authAnimation/AuthAnimation";
import { Outlet, useNavigate } from "react-router-dom";
import { useAccountDetailsData } from "../../utils/redux/storeHooks/dataHooks/useAccountDetailsData";
import { useAccountDetailsActions } from "../../utils/redux/storeHooks/actionsHooks/useAccountDetailsActions";
import { AuthContextType, AuthFlow } from "../../types/pages/auth/authGeneral";

export const AuthLayout = () => {
  const {
    authError,
    isUserAuth,
    isPasswordRestoreSuccess
  } = useAccountDetailsData();
  const {
    setAuthEmail,
    setAuthPassword,
    setAuthEmailValid,
    setIsAuthPassworValid,
    setRestoreEmail,
    setIsRestoreEmailValid,
    setNewPassword,
    setIsNewPasswordValid,
    setAuthError,
  } = useAccountDetailsActions();
  const navigate = useNavigate();

  const handleEmailChange = (email: string, authFlow: AuthFlow) => {
    checkIsEmailValid(email, authFlow)
    if (authError) {
      setAuthError(null)
    }

    switch (authFlow) {
      case AuthFlow.SIGNIN:
        setAuthEmail(email);
        break;
      case AuthFlow.SENDRESTOREEMAIL:
        setRestoreEmail(email);
        break;
    }
  };

  const checkIsEmailValid = (email: string, authFlow: AuthFlow) => {
    const emailPattern = /\S+@\S+\.\S+/;
    const isEmailValid = emailPattern.test(email);

    if (!isEmailValid) {
      setEmailUnValid(authFlow);
      return false;
    }

    setEmailValid(authFlow)
    return true
  }

  const setEmailValid = (authFlow: AuthFlow) => {
    switch (authFlow) {
      case AuthFlow.SIGNIN:
        setAuthEmailValid(true)
        break;
      case AuthFlow.SENDRESTOREEMAIL:
        setIsRestoreEmailValid(true);
        break;
    }
  }

  const setEmailUnValid = (authFlow: AuthFlow) => {
    switch (authFlow) {
      case AuthFlow.SIGNIN:
        setAuthEmailValid(false);
        break;
      case AuthFlow.SENDRESTOREEMAIL:
        setIsRestoreEmailValid(false);
        break;
    }
  }

  const handlePasswordChange = (password: string, authFlow: AuthFlow) => {
    checkIsPasswordValid(password, authFlow)
    if (authError) {
      setAuthError(null)
    }
    switch (authFlow) {
      case AuthFlow.SIGNIN:
        setAuthPassword(password)
        break;
      case AuthFlow.SETNEWPASSWORD:
        setNewPassword(password)
        break;
    }
  };

  const checkIsPasswordValid = (password: string, authFlow: AuthFlow) => {
    const isPasswordValid = password.length > 5;

    if (!isPasswordValid) {
      setPasswordUnValid(authFlow)
      return false;
    }
    setPasswordValid(authFlow)
    return true
  }

  const setPasswordUnValid = (authFlow: AuthFlow) => {
    switch (authFlow) {
      case AuthFlow.SIGNIN:
        setIsAuthPassworValid(false);
        break;
      case AuthFlow.SETNEWPASSWORD:
        setIsNewPasswordValid(false)
        break;
    }
  }

  const setPasswordValid = (authFlow: AuthFlow) => {
    switch (authFlow) {
      case AuthFlow.SIGNIN:
        setIsAuthPassworValid(true);
        break;
      case AuthFlow.SETNEWPASSWORD:
        setIsNewPasswordValid(true)
        break;
    }
  }

  useEffect(() => {

    if (isUserAuth && !isPasswordRestoreSuccess) {
      navigate('/overview')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserAuth])

  return (
    <Box className={scss.auth}>
      {isPasswordRestoreSuccess && (
        <Slide direction="left" in={isPasswordRestoreSuccess}>
          <Box className={scss.auth__popupSuccess}>
            <Box className={scss.auth__popupSuccess__headerSuccess}>
              <img src='/success.svg' alt='success' className={scss.auth__popupSuccess__headerSuccess__img} />
              <Typography className={scss.auth__popupSuccess__headerSuccess__title}>
                Password successfully restored
              </Typography>
              <Typography className={scss.auth__popupSuccess__headerSuccess__subTitle}>
                {`You will be automatically redirected in a few seconds`}
                <CircularProgress sx={{ color: '#191F2E' }} size={24} />
              </Typography>
            </Box>
          </Box>
        </Slide>
      )}

      <Box className={scss.auth__actions}>
        <Box className={scss.auth__header}>
          <Box className={scss.auth__header__mainLogo}>
            <img src={'./registrationLogo.svg'} alt="" className={scss.auth__header__mainLogo__logo} />
            <img src={'./registrationText.svg'} alt="" />
          </Box>
          <Typography className={scss.auth__header__mainTitle}>
            AI-powered platform to build and manage client relationships at scale
          </Typography>
        </Box>

        <AuthAnimation>
          <Box className={scss.actions__actionContainer}>
            <Outlet context={{
              handleEmailChange,
              handlePasswordChange,
              checkIsPasswordValid,
              checkIsEmailValid
            } as AuthContextType} />
          </Box>
        </AuthAnimation>

        <Box className={scss.auth__footer}>
          <Typography className={scss.auth__footer__text}>
            OpenAI-powered app
          </Typography>
        </Box>
      </Box>

      <Box className={scss.auth__background}>
        <img
          src={'./banner.png'}
          alt="" className={scss.auth__background__img}
        />

      </Box>
    </Box >
  );
}
