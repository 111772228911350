import React from 'react';
import { MainActionButton } from '../../actionButton/MainActionButton';
import { AppTooltip } from '../../appTooltipList/AppTooltip';
import LogoutIcon from '@mui/icons-material/Logout';

type Props = {
  file: File;
  toolTipText: string;
  handleUpload: () => void;
  isUploadingCSVProcess: boolean;
}

export const UploadCsvButton = ({ file, toolTipText, handleUpload, isUploadingCSVProcess }: Props) => {
  const iconSx = { transform: "rotate(-90deg)", fontSize: '18px' }

  if (toolTipText) {
    return (
      <AppTooltip text={toolTipText} >
        <MainActionButton
          title='Import CSV'
          icon={<LogoutIcon sx={iconSx} />}
          isDisabled
          backgroundMainColor='rgba(73, 43, 131, 0.89)'
          borderRadius='2px'
          width={140}
        // id='mainPopUpUploadCsv'
        />
      </AppTooltip >
    )
  }

  if (!file) {
    return (
      <AppTooltip text='Select file first'>
        <MainActionButton
          title='Import CSV'
          icon={<LogoutIcon sx={iconSx} />}
          isDisabled
          backgroundMainColor='rgba(73, 43, 131, 0.89)'
          borderRadius='2px'
          width={140}
        // id='mainPopUpUploadCsv'
        />
      </AppTooltip >
    )

  }

  return (
    <MainActionButton
      title='Import CSV'
      icon={<LogoutIcon sx={iconSx} />}
      isLoading={isUploadingCSVProcess}
      actionOnClick={handleUpload}
      backgroundMainColor='rgba(73, 43, 131, 0.89)'
      borderRadius='2px'
      width={140}
    // id='mainPopUpUploadCsv'
    />
  )
};
