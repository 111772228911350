import React from 'react';
import styles from "./UploadLeadsReport.module.scss";
import { Box, Typography } from '@mui/material';
import { useTableLeadsData } from '../../../utils/redux/storeHooks/dataHooks/useTableLeadsData';

export const UploadLeadsReportContent = () => {
  const { uploadInfo } = useTableLeadsData();
  const isCsv = uploadInfo && 'fileName' in uploadInfo;

  if (!uploadInfo) {
    return null;
  }

  if (isCsv) {
    return (
      <Box className={styles.content}>
        <Typography className={styles.content__title}>
          {"Here`s an overview report"}
        </Typography>
        <Box className={styles.content__report}>
          <Typography className={styles.content__report__info}>
            {"LinkedIn links in the file:"}
          </Typography>
          <Box className={styles.content__report__number}>
            {uploadInfo?.uploadingReport?.totalLeadsCount}
          </Box>
        </Box>
        <Box className={styles.content__report}>
          <Typography className={styles.content__report__info}>
            {"Failed links:"}
          </Typography>
          <Box className={`${styles.content__report__number} ${styles.content__report__number_red}`}>
            {uploadInfo?.uploadingReport?.notProcessedLeadCount}
          </Box>
        </Box>
        <Box className={styles.content__report}>
          <Typography className={styles.content__report__info}>
            {"Successfully uploaded links:"}
          </Typography>
          <Box className={styles.content__report__number}>
            {uploadInfo?.uploadingReport?.processedLeadsCount}
          </Box>
        </Box>
      </Box>
    )
  }

  return (
    <Box className={styles.content}>
      <Typography className={styles.content__title}>
        {"Here`s an overview report"}
      </Typography>
      <Box className={styles.content__report}>
        <Typography className={styles.content__report__info}>
          {"Leads in link"}
        </Typography>
        <Box className={styles.content__report__number}>
          {uploadInfo?.uploadingReport?.totalLeadsCount}
        </Box>
      </Box>
      <Box className={styles.content__report}>
        <Typography className={styles.content__report__info}>
          {"Successfully processed leads:"}
        </Typography>
        <Box className={styles.content__report__number}>
          {uploadInfo?.uploadingReport?.processedLeadsCount}
        </Box>
      </Box>
    </Box>
  );
};
