import { useAppSelector } from '../typedHooks';

export const useTableLeadsData = () => {
  const {
    rows,
    orderBy,
    order,
    selected,
    page,
    rowsPerPage,
    leadToSearch,
    loading,
    isSlotsLeft,
    statusOfSend,
    errorOfSend,
    selectedRowForMenu,
    isDeletingOrUpdateProcess,
    responceText,
    teamList,
    isTeamListLoading,
    isLoadingUploadRequest,
    uploadInfo,
    isMarkIsDoneRequest,
    isStopLoadLeadsRequestLoading,
    allLeadsCount,
    dateInserted,
    isFilterOpen,
    dateLastCommented,
    dateLastReplied,
    selectedLeadConnectionPossition,
    filterCompanyName,
    filterLeadName,
    filtersSelectedCount,
    isFilterApply,
    appliedFilersCount,
    isExportingLeads,
    isNeedToReloadLeadsByApplyingFilter,
    isHaveNewActivitySelect,
    isAllSelected,
    isDownloadingReport,
    isDropDownMenuOpen
  } = useAppSelector((state) => state.tableLeadsReducer);

  return {
    rows,
    orderBy,
    order,
    selected,
    page,
    rowsPerPage,
    leadToSearch,
    loading,
    isSlotsLeft,
    statusOfSend,
    errorOfSend,
    selectedRowForMenu,
    isDeletingOrUpdateProcess,
    responceText,
    teamList,
    isTeamListLoading,
    isLoadingUploadRequest,
    uploadInfo,
    isMarkIsDoneRequest,
    isStopLoadLeadsRequestLoading,
    allLeadsCount,
    dateInserted,
    isFilterOpen,
    dateLastCommented,
    dateLastReplied,
    selectedLeadConnectionPossition,
    filterCompanyName,
    filterLeadName,
    filtersSelectedCount,
    isFilterApply,
    appliedFilersCount,
    isExportingLeads,
    isNeedToReloadLeadsByApplyingFilter,
    isHaveNewActivitySelect,
    isAllSelected,
    isDownloadingReport,
    isDropDownMenuOpen
  };
};