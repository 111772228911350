export const getKey = (pathname: string) => {
    if (pathname.startsWith('/sign-in')) {
      return '/sign-in'
    }

    if (pathname.startsWith('/restore-pass')) {
      return '/restore-pass'
    }

    // if (pathname.startsWith('/overview')) {
    //   return '/overview'
    // }

    // if (pathname.startsWith('/team-management')) {
    //   return '/team-management'
    // }

    // if (pathname.startsWith('/leads')) {
    //   return '/leads'
    // }

    // if (pathname.startsWith('/feed')) {
    //   return '/feed'
    // }

    // if (pathname.startsWith('/settings')) {
    //   return '/settings'
    // }

    return 'app-routes'
  }