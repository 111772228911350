import React from "react";
import scss from '../Auth.module.scss';
import { Box, Button, IconButton, InputAdornment, Typography } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useAccountDetailsData } from "../../../utils/redux/storeHooks/dataHooks/useAccountDetailsData";
import { useAccountDetailsActions } from "../../../utils/redux/storeHooks/actionsHooks/useAccountDetailsActions";
import { StyledTextFieldWithErrorAuth } from "../../../components/styledTextField/StyledTextField";
import { MainActionButton } from "../../../components/actionButton/MainActionButton";
import { AuthContextType, AuthFlow } from "../../../types/pages/auth/authGeneral";

export const SignIn = () => {
  const {
    isAuthEmailValid,
    authEmail,
    authPassword,
    isPasswordShown,
    isSingInRequest,
    isAuthPasswordValid,
    authError,
  } = useAccountDetailsData();
  const {
    setIsPasswordShown,
    signIn,
  } = useAccountDetailsActions();
  const {
    handleEmailChange,
    handlePasswordChange,
    checkIsPasswordValid,
    checkIsEmailValid
  } = useOutletContext<AuthContextType>();
  const navigate = useNavigate();
  const authFlow = AuthFlow.SIGNIN

  const handleSignIn = async () => {
    const isEmailValid = checkIsEmailValid(authEmail, authFlow)
    const isPasswordValid = checkIsPasswordValid(authPassword, authFlow)

    if (!isEmailValid || !isPasswordValid) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const response: any = await signIn({
      email: authEmail,
      password: authPassword
    })

    const isLoginFullfiled = response.meta.requestStatus === 'fulfilled'

    if (isLoginFullfiled) {
      navigate('/overview')
    }
  };

  return (
    <Box className={scss.auth__form}>
      <Box className={scss.auth__form__inputWrapper}>
        <Typography className={scss.auth__form__label}>
          Email Address
        </Typography>
        <StyledTextFieldWithErrorAuth
          value={authEmail}
          onChange={(e) => handleEmailChange(e.target.value, authFlow)}
          error={!isAuthEmailValid}
          helperText={!isAuthEmailValid && 'Please enter a valid email address'}
        />
      </Box>

      <Box className={scss.auth__form__inputWrapper}>
        <Typography className={scss.auth__form__label}>
          Password
        </Typography>
        <StyledTextFieldWithErrorAuth
          type={isPasswordShown ? "text" : "password"}
          value={authPassword}
          onChange={(e) => handlePasswordChange(e.target.value, authFlow)}
          error={!isAuthPasswordValid}
          helperText={(authError && 'Please enter a valid password') || (!isAuthPasswordValid && 'Password must be at least 6 symbols.')}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setIsPasswordShown(!isPasswordShown)}>
                  {isPasswordShown
                    ? <Visibility sx={{ color: '#FFFFFF' }} />
                    : <VisibilityOff sx={{ color: '#FFFFFF' }} />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      </Box>

      <MainActionButton
        width={468}
        height={44}
        title="Sign In"
        borderRadius="4px"
        backgroundMainColor="rgba(109, 48, 183, 1)"
        customHoverColor="rgba(130, 70, 190, 1)"
        fontSize={16}
        actionOnClick={handleSignIn}
        isDisabled={isSingInRequest}
        isLoading={isSingInRequest}
      />

      <Box className={scss.auth__form__labelRestore}>
        Forgot password?
        <Button
          variant="text"
          className={scss.auth__form__restoteBtn}
          onClick={() => navigate('/restore-pass')}
        >
          Restore
        </Button>
      </Box>
    </Box>
  )
}
