import { Routes, Route, useLocation, Navigate, } from "react-router-dom";
import { DashboardLeadsPeoplePage } from "./pages/dashboardLeadsPeoplePage/DashboardLeadsPeoplePage";
import { useEffect } from "react";
import React from "react";
import { LayoutApp } from "./components/layouts/LayoutApp";
import { useSidebarActions } from "./utils/redux/storeHooks/actionsHooks/useSidebarActions";
import { TableLeads } from "./components/tableLeads/TableLeads";
import { TeamManagementGuard } from "./route-guard/route-guard-teamManagement";
import { NewFeedPage } from "./pages/newFeedPage/newFeedPage";
import { DailyFeedTab } from "./pages/botManagementPage/features/tabs/dailyFeedTab";
import { InitialFeedTab } from "./pages/botManagementPage/features/tabs/initialFeedTab";
import { LeadsTab } from "./pages/botManagementPage/features/tabs/leadsTab";
import { excludePathesToRequest } from "./utils/general/excludePathesToRequestInAppComponent";
import { TableUsers } from "./pages/adminPage/features/tableUsers/TableUsers";
import { TableTeams } from "./pages/adminPage/features/tableTeams/TableTeams";
import { BotManagementGuard } from "./route-guard/route-guard-botManagement";
import { AdminPageGuard } from "./route-guard/route-guard-AdminPage";
import { NotAllowedPage } from "./pages/notAllowedPage/NotAllowedPage";
import { AutomationPage } from "./pages/automationPage/AutomationPage";
import { PromptSettings } from "./pages/automationPage/features/promptSettings/PromptSettings";
import AutomationSettings from "./pages/automationPage/features/automationSettings/AutomationSettings";
import { AccountDetails } from "./pages/accountDetails/AccountDetails";
import { UsersStatistic } from "./pages/usersStatistic/UsersStatistic";
import { ProxiesMonitoring } from "./pages/adminPage/features/proxiesMonitoring/ProxiesMonitoring";
import PrivateRoutes from "./route-guard/route-guard";
import { AnimatePresence } from "framer-motion";
import { AnimateTab } from "./components/animateTab/AnimateTab";
import { AuthLayout } from "./pages/authentication/AuthLayout";
import { RestorePasswordFlow } from "./pages/authentication/restorePassword/RestorePasswordFlow";
import { SignIn } from "./pages/authentication/signIn/signIn";
import { useAccountDetailsActions } from "./utils/redux/storeHooks/actionsHooks/useAccountDetailsActions";
import { useAccountDetailsData } from "./utils/redux/storeHooks/dataHooks/useAccountDetailsData";
import { GlobalLoadingState } from "./pages/authentication/GlobalLoadingState";
import { images } from "./utils/pages/auth/images";
import { getKey } from "./utils/general/getRouteKey";
import { useTour } from "@reactour/tour";

function App() {
  const location = useLocation();
  const pathname = location.pathname
  const { checkValidCookie } = useSidebarActions();
  const { checkIsUserAuth } = useAccountDetailsActions();
  const { isUserAuth, isUserAuthRequest } = useAccountDetailsData();
  const { needRunTour } = useAccountDetailsData();
  const { setIsOpen, isOpen, setCurrentStep } = useTour();

  useEffect(() => {
    const imagePromises = images.map(
      (src) =>
        new Promise((resolve, reject) => {
          const img = new Image();
          img.src = src;
          img.onload = resolve;
          img.onerror = reject;
        })
    );

    Promise.all(imagePromises)
    checkIsUserAuth()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!isUserAuth) {
      return;
    }
    const isPathExcluded = excludePathesToRequest.some(excludePath => {
      if (excludePath instanceof RegExp) {
        return excludePath.test(pathname);
      }
      return excludePath === pathname;
    });

    if (!isPathExcluded) {
      checkValidCookie();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, checkValidCookie])

  useEffect(() => {
    if (!isOpen) {
      setCurrentStep(0)
    }

    if (needRunTour) {
      setIsOpen(true)
    }
  }, [needRunTour, setIsOpen])

  if (isUserAuthRequest) {
    return <GlobalLoadingState />
  }

  return (
    <AnimatePresence mode='wait'>
      <Routes key={getKey(location.pathname)} location={location}>
        <Route element={<AuthLayout />}>
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/restore-pass" element={<RestorePasswordFlow />} />
        </Route>
        <Route element={<PrivateRoutes />}>
          <Route path="bot-management" element={<BotManagementGuard />} >
            <Route index element={<DailyFeedTab />} />
            <Route path="/bot-management/initial-feed" element={<InitialFeedTab />} />
            <Route path="/bot-management/leads" element={<LeadsTab />} />
          </Route>
          <Route path="admin-page" element={<AdminPageGuard />} >
            <Route path="/admin-page/users" element={<TableUsers />} />
            <Route path="/admin-page/teams" element={<TableTeams />} />
            <Route path="/admin-page/proxies" element={<ProxiesMonitoring />} />
          </Route>
          <Route path="/" element={<LayoutApp />}>
            <Route index element={<Navigate to="overview" />} />
            <Route path="/overview" element={
              <AnimateTab>
                <UsersStatistic />
              </AnimateTab>
            } />
            <Route path="team-management" element={
              <AnimateTab>
                <TeamManagementGuard />
              </AnimateTab>
            } />
            <Route path="leads" element={
              <AnimateTab>
                <DashboardLeadsPeoplePage />
              </AnimateTab>
            } >
              <Route index element={<TableLeads />} />
            </Route>
            <Route path="feed" element={<Navigate to="/feed/general" replace />} />
            <Route path="feed/:tabId" element={
              <AnimateTab>
                <NewFeedPage />
              </AnimateTab>

            } />
            <Route path="settings" element={
              <AnimateTab>
                <AutomationPage />
              </AnimateTab>} >
              <Route path="/settings/prompts" element={<Navigate to="/settings/prompts/default" replace />} />
              <Route path="/settings/prompts/:promptLink" element={<PromptSettings />} />
              <Route path="/settings/automation" element={<AutomationSettings />} />
            </Route>
            <Route path="/account-details" element={
              <AnimateTab>
                <AccountDetails />
              </AnimateTab>
            } />
          </Route>
        </Route>


        <Route path="*" element={
          <AnimateTab>
            <NotAllowedPage />
          </AnimateTab>
        } />
      </Routes>
    </AnimatePresence>
  );
}

export default App;
