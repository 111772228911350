import { getRequest, postRequest, postRequestExport } from "../../../general/https";
import { DeleteLeadsPayload, DeleteLeadsResponse, DownLoadReportPayload, DownLoadReportResponse, ExportLeadsPayload, ExportLeadsResponse, GetLeadListResponse, GetLeadsPayload, GetListOfTeamMembersAndCheckCookiesPayload, GetListOfTeamMembersAndCheckCookiesResponse, GetUploadingSnLinkForUserResponse, MarkLeadsAsReadPayload, MarkLeadsAsReadResponse, MarkUploadAsDonePayload, MarkUploadAsDoneResponse, StopWorkUploadSnLinkPayload, StopWorkUploadSnLinkResponse, UpdateFullLeadInfoPayload, UpdateFullLeadInfoResponse } from "./types";
import { checkErrorStatus } from "../../../general/checkErrorStatus";
import { createAsyncThunkCheckSubscription } from "../../middlewares/thunkCondition";
import { getUpdateUploadBody } from "../../../pages/dashboardLeadsPeoplePage/getUpdateUploadBody";

export const getLeadList = createAsyncThunkCheckSubscription<GetLeadListResponse, GetLeadsPayload>(
  'tableLeadsSlice/getLeadList',
  async (payload, {rejectWithValue}) => {
    try {
      const response = await postRequest(`/lead-information/get-leads`, payload, true)
      checkErrorStatus(response)
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)



export const deleteLeadsAndUpdatedLeadList = createAsyncThunkCheckSubscription<DeleteLeadsResponse, DeleteLeadsPayload>(
  'tableLeadsSlice/deleteLeadsAndUpdatedLeadList',
  async (payload, {rejectWithValue}) => {
    try {
      await postRequest('/lead-information/remove-leads', {
        leadsIds: payload.selectedRows,
        isAllSelected: payload.isAllSelected,
        filters: payload.filters 
      }, true)
      
      const response = await postRequest(`/lead-information/get-leads`, payload, true) 
      checkErrorStatus(response)
      return response.data;
    } catch(error) {
      return rejectWithValue(error.message)
    }

  }
)

export const markLeadsAsRead = createAsyncThunkCheckSubscription<MarkLeadsAsReadResponse, MarkLeadsAsReadPayload>(
  'tableLeadsSlice/markLeadsAsRead',
  async (payload, {rejectWithValue}) => {
    try {
      await postRequest('/lead-information/mark-leads-as-read', {
        leadsIds: payload.selectedRows,
        isAllSelected: payload.isAllSelected,
        filters: payload.filters 
      }, true)
    
      const response = await postRequest(`/lead-information/get-leads`, payload, true)      
      checkErrorStatus(response)
      return response.data;
    } catch(error) {
      return rejectWithValue(error.message)
    }

  }
)

export const updateFullLeadInfo = createAsyncThunkCheckSubscription<UpdateFullLeadInfoResponse, UpdateFullLeadInfoPayload>(
  'tableLeadsSlice/updateFullLeadInfo',
  async (body, {rejectWithValue}) => {
    try {
      const response = await postRequest(`/lead-information/update-full-lead-info`, body, true)
      checkErrorStatus(response)
      return response.data
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const getListOfTeamMembersAndCheckCookies = createAsyncThunkCheckSubscription<
GetListOfTeamMembersAndCheckCookiesResponse, GetListOfTeamMembersAndCheckCookiesPayload>(
  'tableLeadsSlice/getListOfTeamMembersAndCheckCookies',
  async ({userInformation}, {rejectWithValue}) => {
    const body: {userUuid?: string, organizationId?: string} = {};
    const organizationId = userInformation.organizationId;
    const userUuid = localStorage.getItem('userUuid');
    if(organizationId === 'none' || !organizationId) {
      body.userUuid = userUuid;
    } else {
      body.organizationId = organizationId
    }
    try {
      const response = await postRequest(`/team-information/getListOfTeamMembersAndCheckCookies`, body , false)
      checkErrorStatus(response)
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)

export const getUploadingSnLinkForUser = createAsyncThunkCheckSubscription<GetUploadingSnLinkForUserResponse>(
  'tableLeadsSlice/getUploadingSnLinkForUser',
  async (_, {rejectWithValue}) => {
    try {
      const response = await getRequest('/parsing/get-uploading-leads-info', true)
      checkErrorStatus(response)
      return response.data;
    } catch(error) {
      return rejectWithValue(error.message)
    }
  }
)

export const markUploadAsDone = createAsyncThunkCheckSubscription<MarkUploadAsDoneResponse, MarkUploadAsDonePayload>(
  'tableLeadsSlice/markUploadAsDone',
  async (payload, {rejectWithValue}) => {
    try {
      const body = getUpdateUploadBody(payload.uploadInfo);  
      const response = await postRequest('/parsing/mark-as-done', body, false)
      checkErrorStatus(response)
      return response.data;
    } catch(error) {
      return rejectWithValue(error.message)
    }

  }
)

export const downLoadReport = createAsyncThunkCheckSubscription<DownLoadReportResponse, DownLoadReportPayload>(
  'tableLeadsSlice/downLoadReport',
  async (payload, {rejectWithValue}) => {
    try {
      const body = getUpdateUploadBody(payload.uploadInfo);  
      const response = await postRequestExport('/parsing/download-report', body, false);
      checkErrorStatus(response);
      const url = window.URL.createObjectURL(response.data);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'upload_report.csv';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
      return response.data;
    } catch(error) {
      return rejectWithValue(error.message)
    }

  }
)

export const stopWorkUploadSnLink = createAsyncThunkCheckSubscription<StopWorkUploadSnLinkResponse, StopWorkUploadSnLinkPayload>(
  'tableLeadsSlice/stopWorkUploadSnLink',
  async (payload, {rejectWithValue}) => {
    try {
      const body = getUpdateUploadBody(payload.uploadInfo);      
      const response = await postRequest('/parsing/stop-work-upload-leads', body, false)
      checkErrorStatus(response)
      return response.data;
    } catch(error) {
      return rejectWithValue(error.message)
    }
  }
)

export const exportLeads = createAsyncThunkCheckSubscription<ExportLeadsResponse, ExportLeadsPayload>(
  'tableLeadsSlice/exportLeads',
  async (body, {rejectWithValue}) => {
    try {
      const response = await postRequestExport('/lead-information/export-leads', body, true)
      const url = window.URL.createObjectURL(response.data);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'leads.csv';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
      checkErrorStatus(response)
    } catch(error) {
      return rejectWithValue(error.message)
    }
  }
)