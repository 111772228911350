export type UploadReport = {
  // //For CSV
  // leadUrlsToUpload?: string[];
  // processedLeadUrls?: { position: number; url: string }[] | null;
  // notProcessedLeadUrls?:
  //   | { position: number; url: string; reason: NotProcessedUrlError }[]
  //   | null;
  // //For SnLInk
  totalLeadsCount: number;
  processedLeadsCount: number;
  notProcessedLeadCount: number;
};

export enum UploadLeadsStatus {
  UPLOADING = 'UPLOADING',
  COMPLETE = 'COMPLETE',
  DONE = 'DONE',
}

export enum UploadLeadsError {
  TO_MANY_REQUEST = 'TO_MANY_REQUEST',
  NOT_PROCESSED = 'NOT_PROCESSED',
  LINKEDIN_DENNY_ACCESS = 'LINKEDIN_DENNY_ACCESS',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
  LIMIT_ERROR = 'LIMIT_ERROR',
}

export enum NotProcessedUrlError {
  UNVALID_URL = 'UNVALID_URL',
  USER_ALREADY_EXIST = 'USER_ALREADY_EXIST',
  USER_NOT_FOUND = 'NOT_FOUND_USER',
  UNKNOWN_REASON = 'UNKNOWN_REASON',
}