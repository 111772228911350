import * as React from 'react';
import { useParams } from "react-router-dom";
import { useAutomationData } from "../../../../utils/redux/storeHooks/dataHooks/useAutomationData";
import { PromptV2Link } from "../../../../types/pages/automationPage/promptV2Names";
import { Box, CircularProgress, Typography } from '@mui/material';
import { PromptName } from './settings/PromptName';
import { PromptBody } from './settings/PromptBody';
import { PromptActions } from './settings/PromptActions';
import scss from '../../AutomationPage.module.scss'
import { PromptContextVariables } from './settings/PromptContextVariables';
import { PromptCheckBoxes } from './settings/PromptCheckBoxes';
import { PromptsList } from './PromptsList';
import { StyledSwitcher } from '../../../../components/styledComponents/StyledSwitcher';
import { useAutomationActions } from '../../../../utils/redux/storeHooks/actionsHooks/useAutomationActions';
import { isGenerateMessageType } from '../../../../types/pages/automationPage/isGenerateMessageType';
import { checkIsSettingShow } from '../../../../utils/pages/automationPage/checkIsSettingsShow';

export const PromptSettings = () => {
  const { promptLink } = useParams();
  const { prompts, dontGenerateDM, dontGenerateInviteMessage, isGenerateMessageUpdating } = useAutomationData()
  const { updateIsGenerateMessage } = useAutomationActions()
  const currentPrompt = prompts?.find(prompt => prompt.linkName === promptLink) || null;
  const isSettingShow = checkIsSettingShow(promptLink, dontGenerateDM, dontGenerateInviteMessage)
  const isRepliedPrompt = promptLink === PromptV2Link.messagePrompt || promptLink === PromptV2Link.invitePrompt

  const onToogleMessageInviteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let updateGenerateMessageType = null;
    let isGenerated = null;
    switch (promptLink) {
      case PromptV2Link.messagePrompt:
        updateGenerateMessageType = isGenerateMessageType.message
        isGenerated = !dontGenerateDM
        break;
      case PromptV2Link.invitePrompt:
        updateGenerateMessageType = isGenerateMessageType.invite
        isGenerated = !dontGenerateInviteMessage
        break;
    }

    if (updateGenerateMessageType) {
      updateIsGenerateMessage({
        isGenerated,
        updateGenerateMessageType
      })
    }
  }

  return (
    <Box className={scss.promptSettingsContainer} >
      <PromptsList />
      {currentPrompt && (
        <Box className={scss.promptSetting}>
          {isRepliedPrompt && <Box className={scss.promptSetting__checkboxMesssageInviteWrapper}>
            <Typography className={scss.promptSetting__checkBoxMessageInviteTitle}>
              {promptLink === PromptV2Link.messagePrompt
                ? `Don't generate automated messages`
                : 'Don’t generate connection request notes'}
            </Typography>
            <StyledSwitcher
              checked={promptLink === PromptV2Link.messagePrompt ? dontGenerateDM : dontGenerateInviteMessage}
              onChange={((e) => onToogleMessageInviteChange(e))}
            />
            {isGenerateMessageUpdating && <CircularProgress size={24} sx={{ fontSize: 16, color: 'rgba(59, 36, 112, 1)' }} />}
          </Box>}
          {isSettingShow && (
            <>
              {isRepliedPrompt && <Typography className={scss.promptSetting__messsageInviteTitle}>
                {promptLink === PromptV2Link.messagePrompt
                  ? 'This prompt is designed for messaging on LinkedIn.'
                  : 'This prompt is designed for inviting your connections on LinkedIn.'}
              </Typography>}
              <PromptName currentPrompt={currentPrompt} />
              <PromptBody currentPrompt={currentPrompt} />
              {isRepliedPrompt && <PromptContextVariables currentPrompt={currentPrompt} />}
              <PromptCheckBoxes currentPrompt={currentPrompt} />
              <PromptActions currentPrompt={currentPrompt} />
            </>
          )}
        </Box >
      )}
    </Box>
  )
}

