import React from 'react';
import styles from "./WelcomeTour.module.scss";
import { Box, Button, IconButton, Typography } from '@mui/material';
import { MainActionButton } from '../../actionButton/MainActionButton';
import { usePopupActions } from '../../../utils/redux/storeHooks/actionsHooks/usePopupActions';
import { useAccountDetailsActions } from '../../../utils/redux/storeHooks/actionsHooks/useAccountDetailsActions';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useAccountDetailsData } from '../../../utils/redux/storeHooks/dataHooks/useAccountDetailsData';
import { useEndTour } from '../../../utils/customHooks/useEndTour';
import { useNavigate } from 'react-router-dom';

export const PopUpWelcomeTour = () => {
  const { setPopUpWelcomeTourOpen } = usePopupActions();
  const { runTour, skipTour, } = useAccountDetailsActions();
  const { isSkipTourRequest } = useAccountDetailsData();
  const { resetMock } = useEndTour();
  const navigate = useNavigate();

  const handleRunTour = () => {
    runTour();
    setPopUpWelcomeTourOpen(false);
    resetMock()
    navigate('/leads')
  }

  const onSckipTour = async () => {
    await skipTour()
    setPopUpWelcomeTourOpen(false);
  }

  return (
    <Box className={styles.popup}>
      <Box className={styles.header}>
        <Box className={styles.header__title}>
          WELCOME TO <span className={styles.header__title_red}>HEET.AI</span>
        </Box>
        <IconButton
          color="default"
          onClick={onSckipTour}
          className={styles.header__closeButton}
        >
          <CloseRoundedIcon sx={{ fill: '#191F2E' }} />
        </IconButton>
      </Box>
      <Box className={styles.content}>
        <Typography className={styles.content__mainText}>
          Get to know the app better! Watch our onboarding video or take a quick product tour right within the app.
        </Typography>
        <Box className={styles.content__videoContainer}>
          <iframe
            className={styles.content__iframe}
            title='start video'
            width="100%"
            height="400"
            src="https://www.youtube.com/embed/onjnkyz2r0A"
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </Box>
      </Box>

      <Box className={styles.footer}>
        <Button
          variant='text'
          onClick={onSckipTour}
          className={styles.footer__cancel}
          disabled={isSkipTourRequest}
        >
          Skip
        </Button>
        <MainActionButton
          title='App tour'
          borderRadius='4px'
          width={162}
          height={40}
          fontWeight={300}
          actionOnClick={handleRunTour}
          isDisabled={isSkipTourRequest}
        />
      </Box>
    </Box>
  );
};
