import React from 'react';
import styles from "./UploadLeadsReport.module.scss";
import { Box } from '@mui/material';
import { MainActionButton } from '../../actionButton/MainActionButton';
import { useTableLeadsData } from '../../../utils/redux/storeHooks/dataHooks/useTableLeadsData';
import { useTableLeadsActions } from '../../../utils/redux/storeHooks/actionsHooks/useTableLeadsActions';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

export const UploadLeadsReportFooter = () => {
  const { isMarkIsDoneRequest, uploadInfo, isDownloadingReport } = useTableLeadsData();
  const { markUploadAsDone, downLoadReport } = useTableLeadsActions();

  const markUpload = () => {
    if (uploadInfo) {
      markUploadAsDone({
        uploadInfo
      })
    }
  }

  const onDownLoadReport = () => {
    if (uploadInfo) {
      downLoadReport({ uploadInfo })
    }
  }

  return (
    <Box className={styles.footer}>
      <MainActionButton
        title='Download report'
        isFullWidth
        height={40}
        fontWeight={400}
        borderRadius='4px'
        backgroundMainColor='rgba(248, 243, 255, 1)'
        fontColor='rgba(93, 66, 145, 1)'
        actionOnClick={onDownLoadReport}
        isLoading={isDownloadingReport}
        isDisabled={isDownloadingReport}
        borderButton='1px solid rgba(230, 217, 248, 1)'
        icon={<ArrowDownwardIcon />}
        iconPosition='right'
      />
      <MainActionButton
        title='Mark as done'
        isFullWidth
        height={40}
        fontWeight={300}
        borderRadius='4px'
        actionOnClick={markUpload}
        isLoading={isMarkIsDoneRequest}
        isDisabled={isMarkIsDoneRequest}
      />
    </Box>
  );
};
