import React from 'react';
import { CloseButton } from './AppTourButtons';
import { Box, Typography } from '@mui/material';
import styles from './AppTour.module.scss'

type Props = {
  title: string;
  description?: string;
}

export const AppTourContent = ({ title, description }: Props) => {
  return (
    <Box className={styles.content}>
      <Box className={styles.content__close}>
        <CloseButton />
      </Box>

      <Typography className={styles.content__mainTitle}>
        {title}
      </Typography>
      {description && <Typography className={styles.content__subTitle}>
        {description}
      </Typography>}

    </Box>
  )
}