import React, { useState } from 'react';
import styles from "./PopUpNewSubscription.module.scss";
import { Box, Typography, Paper } from '@mui/material';
import { useAccountDetailsData } from '../../../utils/redux/storeHooks/dataHooks/useAccountDetailsData';
import { getUserInformationFromlocalStorage } from '../../../utils/general/get_set_user_information';
import { MainActionButton } from '../../actionButton/MainActionButton';
import { isFirstPaymentProcessing, openSubscriptionLink } from '../../../utils/subscription/common';
import { useFreezePopUp } from '../../../utils/customHooks/useStackPopUp';
import CheckIcon from '@mui/icons-material/Check';

const benefits = [
    'FREE trial after subscription – No upfront payment required',
    'See results from day one – Start engaging instantly',
    'Unlimited comments FOREVER with LinkedIn extension'
];

export const PopUpNewSubscription = () => {
    const [isLinkPrepare, setIsLinkPrepare] = useState(false)
    const { activeSubscription, invoices } = useAccountDetailsData();
    const { paymentFlow } = getUserInformationFromlocalStorage();
    const isSubscribeDisabled = isFirstPaymentProcessing(activeSubscription, invoices);
    useFreezePopUp();

    const onRequestFirstSubscription = async () => {
        setIsLinkPrepare(true)
        await openSubscriptionLink(paymentFlow, activeSubscription);
        setIsLinkPrepare(false)
    };

    return (
        <Paper elevation={8} className={styles.popup}>
            <Box className={styles.iconContainer}>
                <img src="/popup-subscription.svg" alt="Icon" />
            </Box>

            <Typography variant="h5" className={styles.title}>
                Welcome to HEET.AI!
            </Typography>
            <Typography variant="body1" className={styles.description}>
                Glad you share our passion for <strong>social selling</strong> on <strong>LinkedIn.</strong><br />
                Here’s what you’ll get with your <strong>7-day FREE</strong> trial:
            </Typography>

            <Box className={styles.featuresBox}>
                <Typography variant="body2" className={styles.feature}><span className={styles.bullet}></span><strong>250 leads</strong> monitored</Typography>
                <Typography variant="body2" className={styles.feature}><span className={styles.bullet}></span>Daily post tracking</Typography>
                <Typography variant="body2" className={styles.feature}><span className={styles.bullet}></span><strong>3 active</strong> searches per month</Typography>
                <Typography variant="body2" className={styles.feature}><span className={styles.bullet}></span>AI-powered comments & messages</Typography>
                <Typography variant="body2" className={styles.feature}><span className={styles.bullet}></span><strong>35 invites + 35 messages</strong> per month</Typography>
                <Typography variant="body2" className={styles.feature}><span className={styles.bullet}></span><strong>50-150</strong> AI-generated comments per day</Typography>
                <Typography variant="body2" className={styles.feature}><span className={styles.bullet}></span><strong>1 default & 1 custom prompt</strong> for tone fine-tuning</Typography>
            </Box>

            <Box className={styles.priceContainer}>
                <Typography variant="h6" className={styles.oldPrice}>$25.00</Typography>
                <Typography variant="h4" className={styles.newPrice}>$0.00 <span className={styles.priceSubText}>for 7 days</span></Typography>
                <Typography variant="body2" className={styles.priceInfo}>*FREE for 7 days, then $25/month</Typography>
            </Box>

            <Box className={styles.benefitsContainer}>
                {benefits.map((benefit, index) => (
                    <Typography key={index} variant="body2" className={styles.benefit}>
                        <CheckIcon className={styles.checkIcon} />
                        {benefit}
                    </Typography>
                ))}
            </Box>

            <MainActionButton
                title='Subscribe'
                actionOnClick={onRequestFirstSubscription}
                borderRadius='2px'
                isLoading={isLinkPrepare}
                isDisabled={isSubscribeDisabled || isLinkPrepare}
            />
        </Paper>
    );
};
